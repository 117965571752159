import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import classnames from "classnames";
import Sidebar from "../partials/Sidebar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import { updateProfile } from "../../actions/userActions";
import axios from "axios";
import { withTranslation } from 'react-i18next';
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import { toast, ToastContainer} from "react-toastify";
import keys from "../../actions/config";
import { withRouter } from "react-router-dom";


const url = keys.baseUrl;
class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            _id : "",
            name: "",
            email: "",
            phonenumber: "",
            profile: "",
            profileurl : "",
            errors: {},
        };
    }

    componentDidMount() {
        this.getData()
    };

     componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.auth !== undefined
            && nextProps.auth.profile !== undefined
            && nextProps.auth.profile.data !== undefined
            && nextProps.auth.profile.data.message !== undefined) {
            toast(nextProps.auth.profile.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
            nextProps.auth.profile = undefined;
        }
    }

    getData() {
         if (this.props.auth !== undefined && this.props.auth.user !== undefined) {
            const id = this.props.auth.user.id;
            axios
            .get(url+"api/userget/"+id)
            .then(res => {
                console.log(res.data,'ressdcfdvcfvfv');
                this.setState(res.data);
                if(this.state.profile == ""){
                    this.setState({profileurl : keys.baseUrl+"uploads/No_image_available.png"})
                }else{
                    this.setState({profileurl : keys.baseUrl+this.state.profile})
                }
                console.log(this.state.profile);
            })
            .catch()
        }
        console.log(this.props.auth);
    }

    handleChange = (event) => {
      this.setState({
        profileurl: URL.createObjectURL(event.target.files[0]),
        profile: event.target.files[0]
      })
    //   const data = new FormData()
    //   data.append('file', this.state.profile)
    //   axios.post(url+"api/profileupload", data, { // receive two parameter endpoint url ,form data
    //   })
    //   .then(res => { // then print response status
    //     console.log(res.statusText)
    //   })
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

     onProfileUpdate = e => {
        e.preventDefault();
        const updateProfile = {
            _id: this.state.id,
            name: this.state.name,
            email: this.state.email,
            phonenumber: this.state.phonenumber,
            profile: this.state.profile
        };
        const data = new FormData();
        data.append('name', this.state.name);
        data.append('email', this.state.email);
        data.append('phonenumber', this.state.phonenumber);
        data.append('file', this.state.profile);
        data.append('_id', this.state._id);
        this.props.updateProfile(data);
        // axios.post(url+"api/profileupload", data, { // receive two parameter endpoint url ,form data
        // })
        // .then(res => { // then print response status
        //     console.log(res.statusText)
        // })

    };

    render() {
        const {t} = this.props;
        const { errors } = this.state;


        return (
            <div>
                <Navbar/>
                <div className="d-flex" id="wrapper">
                    <Sidebar/>
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            
                           
                            <h3 className="mt-2 text-secondary">{t("EDIT_PROFILE")}</h3>
                            <form noValidate onSubmit={this.onProfileUpdate} id="update-profile" className="mt-5">
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">{t("NAME")}</label>
                                        </div>
                                        <div className="col-md-6">

                                            <input
                                                onChange={this.onChange}
                                                value={this.state.name}
                                                id="name"
                                                type="text"
                                                error={errors.name}

                                                className={classnames("form-control", {
                                                    invalid: errors.name
                                                })}/>
                                            <span className="text-danger">{errors.name}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="email">{t("EMAIL")}</label>
                                        </div>
                                        <div className="col-md-6">
                                        <input
                                                onChange={this.onChange}
                                                value= {this.state.email}
                                                id="email"
                                                type="text"
                                                error={errors.email}
                                                disabled
                                                className={classnames("form-control", {
                                                    invalid: errors.email
                                                })}/>
                                            
                                            <span className="text-danger">{errors.email}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="phonenumber">{t("PHONE_NUMBER")}</label>
                                        </div>
                                        <div className="col-md-6">
                                             <input
                                                onChange={this.onChange}
                                                value={this.state.phonenumber}
                                                id="phonenumber"
                                                type="text"
                                                error={errors.phonenumber}
                                                className={classnames("form-control", {
                                                    invalid: errors.phonenumber
                                                })}/>
                                            <span className="text-danger">{errors.phonenumber}</span>
                                        </div>
                                    </div>

                                    {/*}<div className="row mt-2">
                                        <div className="col-md-3">
                                        <br/>
                                        <label htmlFor="profile">Profile Image</label>
                                        </div>
                                        <div className="col-md-6">
                                        <input type="file" onChange={this.handleChange}
                                        />
                                        <img width="100px" src={this.state.profileurl} />

                                        </div>
                                    </div>
                                    */}

                                </form>
                                    <br />
                                <button
                                    form="update-profile"
                                    type="submit"
                                    className="btn btn-primary">
                                    {t("UPDATE_PROFILE")}
                                </button>
                         </div>
                    </div>
                    <ToastContainer/>
                </div>
            </div>
        );
    }

}

Profile.propTypes = {
    updateProfile: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { updateProfile }
)(withTranslation()(Profile),(withRouter(Profile)));
