import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { withTranslation, WithTranslation } from "react-i18next";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import SupportReplyModal from "../partials/SupportReplyModal";
import { toast, ToastContainer } from "react-toastify";
import keys from "../../actions/config";
import { t } from "i18next";
const url = keys.baseUrl;
class tradehistory extends Component {
    constructor(props) {
        super(props);
        const {t}=this.props
        this.state = {
            records: [],
        };
        this.columns = [
            {
                key: "pairName",
                text: t("CONTRACTS"),
                className: "pairName",
                align: "left",
                sortable: true,
                width: 200,
            },
            {
                key: "name",
                text: t("NAME"),
                className: "name",
                align: "left",
                sortable: true,
            },
            {
                key: "quantity",
                text: t("OUANTITY"),
                className: "quantity",
                align: "left",
                sortable: true,
                width: 200,
            },
            {
                key: "quantity",
                text: t("FILLED_REMAINING"),
                className: "Filled",
                align: "left",
                sortable: true,
                width: 200,
                cell: record => {
                    var filledAmount = record.filledAmount ? record.filledAmount : 0;
                    var Remaining = parseFloat(record.quantity) - parseFloat(record.filledAmount);
                    return (
                        <Fragment>
                            {parseFloat(filledAmount).toFixed(8) + "/" + parseFloat(Remaining).toFixed(8)}
                        </Fragment>
                    );
                }
            },

            {
                key: "e_price",
                text: t("EXEC_PRICE"),
                className: "e_price",
                align: "left",
                sortable: true,
                width: 200,

            },
            {
                key: "price",
                text: t("ORDER_PRICE"),
                className: "price",
                align: "left",
                sortable: true,
                width: 200,

            },
            {
                key: "buyorsell",
                text: t("BUY_OR_SELL"),
                className: "price",
                align: "left",
                sortable: true,
                width: 200,

            },
            {
                key: "orderValue",
                text: t("ORDER_VALUE"),
                className: "orderValue",
                align: "left",
                sortable: true,
                width: 200,


            },
            {
                key: "orderType",
                text: t("TYPE"),
                className: "orderType",
                align: "left",
                sortable: true,
                width: 200,

            },
            {
                key: "status",
                text: t("STATUS"),
                className: "status",
                align: "left",
                sortable: true,
                width: 200,


            }, {
                key: "date",
                text: t("DATE"),
                className: "date",
                align: "left",
                sortable: true,
                width: 200,


            },



        ];

        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            filename: "Order",
            no_data_text: t('NO_RECORD_FOUND'),
            language: {
                length_menu: t("SHOW_MENU"),
                filter: t("FILTER IN RECORDS"),
                info: t("SHOWING_START_END"),
                pagination: {
                    first: t("FIRST"),
                    previous: t("PREVIOUS"),
                    next: t("NEXT"),
                    last: t("LAST")
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };
    }
    componentDidMount() {
        this.getData()
    };

    pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }


    getData() {
        axios
            .post(url + "api/order_history/spot")
            .then(res => {
                this.setState({ records: res.data.data })
            })
            .catch()
    }

    render() {
        const {t}=this.props

        const { records } = this.state
        return (
            <div>
                <Navbar />
                <div className="d-flex" id="wrapper">
                    <Sidebar />
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <h3 className="mt-2 text-secondary">{t("ORDER_HISTORY")}</h3>
                            <div className="resonsive_table">
                                              
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                onPageChange={this.pageChange.bind(this)}
                                className="table table-bordered table-striped order_history_table"
                            />
                            </div>
                        </div>
                    </div>
                    <ToastContainer />
                </div>
            </div>
        );
    }

}

tradehistory.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});

export default connect(
    mapStateToProps
)(withTranslation()(tradehistory),(tradehistory));
