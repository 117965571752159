import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addFaq } from "../../actions/faqActions";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import { withTranslation, WithTranslation } from 'react-i18next';
import $ from 'jquery';
import { Editor } from '@tinymce/tinymce-react';

import 'react-toastify/dist/ReactToastify.css';
class FaqAddModal extends React.Component {
    constructor() {
        super();
        this.state = {
            question: "",
            answer: "",
            errors: {}
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
             $("#add-faq-modal").find(".text-danger").show();
            this.setState({
                errors: nextProps.errors
            });
        }
        console.log(nextProps);
        if (nextProps.auth !== undefined
            && nextProps.auth.faq !== undefined
            && nextProps.auth.faq.data !== undefined
            && nextProps.auth.faq.data.message !== undefined) {
            $('#add-faq-modal').modal('hide');
            toast(nextProps.auth.faq.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
            nextProps.auth.faq = undefined;
            
        }
    }
  
    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onFaqAdd = e => {
        e.preventDefault();
        const newFaq = {
            question: this.state.question,
            answer: this.state.answer
        };
        console.log(newFaq);
        this.props.addFaq(newFaq);
    };


    render() {
        const {t}=this.props
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="add-faq-modal" data-reset="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">{t("ADD_FAQ")}                                                                                                                                                                                       </h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate onSubmit={this.onFaqAdd} id="add-faq">
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="question">{t("QUESTION")}</label>
                                        </div>
                                        <div className="col-md-9">
                                            <textarea
                                                onChange={this.onChange}
                                                value={this.state.question}
                                                id="question"
                                                type="text"
                                                error={errors.question}
                                                className={classnames("form-control", {
                                                    invalid: errors.question
                                                })}/>
                                            <span className="text-danger">{errors.question}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="answer">{t("ANSWER")}</label>
                                        </div>
                                        <div className="col-md-9">
                                            <textarea
                                                onChange={this.onChange}
                                                value={this.state.answer}
                                                error={errors.answer}
                                                id="answer"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.answer
                                                })}
                                            />
                                            <span className="text-danger">{errors.answer}</span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-outline" data-dismiss="modal">{t("CLOSE")}</button>
                                <button
                                    form="add-faq"
                                    type="submit"
                                    className="btn btn-primary">
                                    {t("ADD_FAQ")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

FaqAddModal.propTypes = {
    addFaq: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { addFaq }
)(withRouter(withTranslation()(FaqAddModal),(FaqAddModal)));
