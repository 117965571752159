import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addcurrency } from "../../actions/currency";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select";
import { withTranslation, WithTranslation } from "react-i18next";
import $ from "jquery";
import { Editor } from "@tinymce/tinymce-react";

import "react-toastify/dist/ReactToastify.css";

const options = [
  { value: "Crypto", label: "Crypto" },
  { value: "Token", label: "Token" },
  { value: "Fiat", label: "Fiat" },
];

const optionsToken = [
  { value: "ERC_20", label: "ERC_20" },
  { value: "BEP_20", label: "BEP_20" },
  { value: "TRC_20", label: "TRC_20" },
];

class CurrencyAddModal extends React.Component {
  constructor() {
    super();
    this.state = {
      currencyName: "",
      currencySymbol: "",
      fee: "",
      minimum: "",
      status: "",
      currencyType: "",
      tokenType: "",
      contractaddress: "",
      minabi: "",
      errors: {},
      curimage:null,
      attachment:"",
      imageerror:"",
      curimagesize:0,
      decimals:0
    };
  }

  styles = {
    option: (styles, {isFocused, isSelected}) => ({
      ...styles,
      color: "#fff",
      background: isFocused
          ? '#bdbdbd'
          : isSelected
              ? '#005eb8'
              : "#808080",
      zIndex: 1
  }),
    // option: (provided, state) => ({
    //   ...provided,
    //   color: "white",
    //   backgroundColor: "#81c8f6",
    // }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '52px',
      padding: '0 6px',
      backgroundColor: "#03081f",
      borderColor: '#81c8f6',
    borderRadius: 10,
    borderStyle: 'solid',
    borderWidth: '1px'
     
    }),
    control: (provided, state) => ({
      ...provided,
      height: '52px',
      borderRadius:10,
      backgroundColor: "#03081f",
      border:'none'
     
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '52px',
      position: 'absolute',
      right: 0,
      top: 0,
      color:'#fff' 
    }),    
    singleValue: (provided, state) => ({
      ...provided,
      color: "#fff"     
    }),
    menuList: base => ({
      ...base,
      // kill the white space on first and last option
      padding: 0
    })
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      console.log('nextProps.errors',nextProps.errors)
      $("#add-currency-modal").find(".text-danger").show();
      this.setState({
        errors: nextProps.errors,
      });
    } else {
      this.setState({
        errors: "",
      });
    }
    //console.log(nextProps.auth.currencyadd.data,'shdbjksfcgdsyfvfdsuy');
    if (
      nextProps.auth !== undefined &&
      nextProps.auth.currencyadd !== undefined &&
      nextProps.auth.currencyadd.data !== undefined &&
      nextProps.auth.currencyadd.data.message !== undefined
    ) {
      $("#add-currency-modal").modal("hide");
      this.setState({
        errors: "",
      });
      toast(nextProps.auth.currencyadd.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      nextProps.auth.currencyadd = undefined;
      this.setState({
        errors: "",
      });
    }
  }
  handleChange = (optionsTerms) => {
    this.setState({ currencyType: optionsTerms.value });
  };
  handleChangeToken = (optionsTerms) => {
    this.setState({ tokenType: optionsTerms.value });
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };


  handleChangeefile = (event) => {
    console.log(event.target.files[0],"image")
if(event.target.files[0]){
    var filesize= event.target.files[0].size
    if(filesize>20000){
      console.log("filesize",filesize)
      this.setState({curimagesize:filesize})
      this.setState({imageerror:"Image size should be less than  20 Kb"})
      toast("Image Size should be less than 20 Kb", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    else{
      console.log("filesize2",filesize)
    this.setState({
      curimagesize:filesize,
      imageerror:"",
      curimage: URL.createObjectURL(event.target.files[0]),
      attachment: event.target.files[0],
    });
  }
}

  };

  onCurrencyAdd = (e) => {
    e.preventDefault();
    if(this.state.curimagesize<20000){
console.log("this.state.minabi",this.state.minabi)
    const newCurrency = {
      currencyName: this.state.currencyName,
      currencySymbol: this.state.currencySymbol,
      fee: this.state.fee,
      currencyType: this.state.currencyType,
      contractaddress: this.state.contractaddress,
      minabi: this.state.minabi,
      tokenType: this.state.tokenType,
    };
    const data = new FormData();
    data.append("currencyName", this.state.currencyName);
    data.append("currencySymbol", this.state.currencySymbol);
    data.append("fee", this.state.fee);
    data.append("contractaddress", this.state.contractaddress);
    data.append("currencyType", this.state.currencyType);
    data.append("minabi", this.state.minabi);
    data.append("decimals", this.state.decimals);
    data.append("minimum", this.state.minimum);
    data.append("tokenType", this.state.tokenType);

    data.append("file", this.state.attachment);

    this.props.addcurrency(data);
    this.setState( {
    currencyName: "",
    currencySymbol: "",
    fee: "",
    minimum: "",
    status: "",
    currencyType: "",
    tokenType: "",
    contractaddress: "",
    minabi: "",
    errors: {},
    curimage:null,
    attachment:"",
    imageerror:"",
    curimagesize:0,
    decimals:0})
  }else{
    toast("Image Size should be less than 20 Kb", {
      position: toast.POSITION.TOP_CENTER,
    });
  }
  };
  onClose=()=>{
    this.setState( {currencyName: "",
    currencySymbol: "",
    fee: "",
    minimum: "",
    status: "",
    currencyType: "",
    tokenType: "",
    contractaddress: "",
    minabi: "",
    errors: {},
    curimage:null,
    attachment:"",
    imageerror:"",
    curimagesize:0,
    decimals:0})
  }

  render() {
    const {t}=this.props
    const { errors } = this.state;
    console.log("state",this.state)
    return (
      <div>
        <div className="modal fade" id="add-currency-modal" data-reset="true">
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">{t("ADD_CURRENCY")}</h4>
                <button type="button" className="close" data-dismiss="modal" onClick={this.onClose}>
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <form
                  noValidate
                  onSubmit={this.onCurrencyAdd}
                  id="add-currency"
                >
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="currencyName">{t("CURRENCY_TYPE")}</label>
                    </div>
                    <div className="col-md-9">
                      <Select isSearchable={false}
                        // value={selectedTerms}
                        onChange={this.handleChange}
                        options={options}
                        label="Single select"
                        styles={this.styles} className="border_blue_select"
                      />
                      
                      <span className="text-danger">{errors.currencyType}</span>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="currencyName">{t("CURRENCY_NAME")}</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.onChange}
                        value={this.state.currencyName}
                        id="currencyName"
                        type="text"
                        error={errors.currencyName}
                        className={classnames("form-control", {
                          invalid: errors.currencyName,
                        })}
                      />
                      <span className="text-danger">{errors.currencyName}</span>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="currencySymbol">{t("CURRENCY_SYMBOL")}</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.onChange}
                        value={this.state.currencySymbol}
                        error={errors.currencySymbol}
                        id="currencySymbol"
                        type="text"
                        className={classnames("form-control", {
                          invalid: errors.currencySymbol,
                        })}
                      />
                      <span className="text-danger">
                        {errors.currencySymbol}
                      </span>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="currencySymbol">{t("DECIMALS")}</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.onChange}
                        value={this.state.decimals}
                        error={errors.decimals}
                        id="decimals"
                        type="number"
                        min='0'
                        className={classnames("form-control", {
                          invalid: errors.decimals,
                        })}
                      />
                      <span className="text-danger">
                        {errors.decimals}
                      </span>
                    </div>
                  </div>
                  {/* {console.log("THE ERROR TYPE : ", errors)} */}
                  {this.state.currencyType == "Token" ? (
                    <div>
                      <div className="row mt-2">
                        <div className="col-md-3">
                          <label htmlFor="currencyName">{t("CONTRACT_ADDRESS")}</label>
                        </div>
                        <div className="col-md-9">
                          <input
                            onChange={this.onChange}
                            value={this.state.contractaddress}
                            error={errors.contractaddress}
                            id="contractaddress"
                            type="text"
                            className={classnames("form-control", {
                              invalid: errors.contractaddress,
                            })}
                          />
                          <span className="text-danger">
                            {errors.contractaddress}
                          </span>
                        </div>
                      </div>
                      <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="currencyName">{t("TOKEN_TYPE")}</label>
                    </div>
                    <div className="col-md-9">
                      <Select isSearchable={false}
                        // value={selectedTerms}
                        onChange={this.handleChangeToken}
                        options={optionsToken}
                        label="Single select"
                        styles={this.styles} className="border_blue_select"
                      />
                      <span className="text-danger">{errors.tokenType}</span>
                    </div>
                  </div>
                      <div className="row mt-2">
                        <div className="col-md-3">
                          <label htmlFor="currencyName">{t("MIN_ABI")}</label>
                        </div>
                        <div className="col-md-9">
                          <textarea
                            onChange={this.onChange}
                            value={this.state.minabi}
                            error={errors.minabi}
                            id="minabi"
                            type="text"
                            className={classnames("form-control", {
                              invalid: errors.minabi,
                            })}
                          />
                          <span className="text-danger">{errors.minabi}</span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="fee">{t("WITHDRAWAL_FEE")}</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.onChange}
                        value={this.state.fee}
                        error={errors.fee}
                        id="fee"
                        type="text"
                        className={classnames("form-control", {
                          invalid: errors.fee,
                        })}
                      />
                      <span className="text-danger">{errors.fee}</span>
                    </div>
                  </div>
                  
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="minimum">{t("MINIMUM_WITHDRAW")}</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.onChange}
                        value={this.state.minimum}
                        error={errors.minimum}
                        id="minimum"
                        type="text"
                        className={classnames("form-control", {
                          invalid: errors.minimum,
                        })}
                      />
                      <span className="text-danger">{errors.minimum}</span>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="minimum">{t("CURRENCY_ICON")}</label>
                    </div>
                    <div className="col-md-9">
                    <label class="custom-file-upload">
                    <input
                            type="file"
                            accept="image/x-png,image/gif,image/jpeg"
                            onChange={this.handleChangeefile}
                            name="file1"
                            id="exampleInputFile"
                            aria-describedby="fileHelp"
                          />
                         {t("CHOOSE_FILE")}
                          </label>
                          <span className="text-danger">{this.state.imageerror ? this.state.imageerror :errors.file}</span>
                           <img
                          className="img-fluid proofThumb"
                          src={this.state.curimage}
                        />
                    </div>
                  </div>

                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline"
                  data-dismiss="modal"
                  onClick={this.onClose}
                >
                {t("CLOSE")}
                </button>
                <button
                  form="add-currency"
                  type="submit"
                  className="btn btn-primary"
                >
                  {t("ADD_CURRENCY")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CurrencyAddModal.propTypes = {
  addcurrency: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { addcurrency })(
  withRouter(withTranslation()(CurrencyAddModal),(CurrencyAddModal))
);
