import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import classnames from "classnames";
import { toast, ToastContainer } from "react-toastify";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateUser } from "../../actions/userActions";
import { withRouter } from "react-router-dom";
import $ from "jquery";
import axios from "axios";

import "react-toastify/dist/ReactToastify.css";
import keys from "../../actions/config";

const url = keys.baseUrl;
const imageurl = keys.imageUrl;

class kycupdate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      name: "",
      email: "",
      lastname: "",
      phonenumber: "",
      address1: "",
      country: "",
      city: "",
      reason: "",
      pincode: "",
      IDtype: "",
      IDproofno: "",
      Addresstype: "",
      Addressproofno: "",
      Phototype: "",
      Phototypeno: "",
      IDprooffront: "",
      IDproofback: "",
      Photofile: "",
      Addressfile: "",
      IDstatus: "",
      Addresstatus: "",
      Photostatus: "",
      verifiedstatus: "",
      errors: {},
      IDhide: "",
      reasonID: "",
      Addresshide: "",
      reasonAddress: "",
      Photohide: "",
      reasonPhoto: "",
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    const id = this.props.match.params.id;
    axios
      .get(url + "api/userget/" + id)
      .then((res) => {
        this.setState(res.data);
      })
      .catch();
  }
  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  showrejectid = (e) => {
    this.setState({ IDhide: "Show" });
  };

  showaddress = (e) => {
    this.setState({ Addresshide: "Show" });
  };

  onshowPhoto = (e) => {
    this.setState({ Photohide: "Show" });
  };

  onSkipbutton() {
    this.props.history.push("/kyc");
  }

  onAcceptID = (e) => {
     const id = this.props.match.params.id;
    const idaccept = {
      _id: id,
      email: this.state.email,
    };

    axios
      .post(url + "api/IDverifiedaccept", idaccept)
      .then((res) => {
        if (res.status === 200) {
          this.setState({ IDstatus: res.data.updateddata.IDstatus });
          toast(res.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          this.getData();
        }
      })
      .catch();
  };

  onRejectID = (e) => {
     const id = this.props.match.params.id;

    const idreject = {
      _id: id,
      email: this.state.email,
      reason: this.state.reasonID,
    };
    axios
      .post(url + "api/IDverifiedreject", idreject)
      .then((res) => {
        if (res.status === 200) {
          this.setState({ IDstatus: res.data.updateddata.IDstatus });
          toast(res.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          this.getData();
        }
      })
      .catch();
  };

  onAcceptAddress = (e) => {
     const id = this.props.match.params.id;
    const addressaccept = {
      _id: id,
      email: this.state.email,
    };
    axios
      .post(url + "api/addressaccept", addressaccept)
      .then((res) => {
        if (res.status === 200) {
          this.setState({ Addresstatus: res.data.updateddata.Addresstatus });

          toast(res.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          this.getData();
        }
      })
      .catch();
  };

  onRejectAddress = (e) => {
     const id = this.props.match.params.id;
    const addressreject = {
      _id: id,
      email: this.state.email,
      reason: this.state.reasonAddress,
    };
    axios
      .post(url + "api/addressreject", addressreject)
      .then((res) => {
        if (res.status === 200) {
          this.setState({ Addresstatus: res.data.updateddata.Addresstatus });

          toast(res.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          this.getData();
        }
      })
      .catch();
  };

  onAcceptPhoto = (e) => {
    const id = this.props.match.params.id;
    const photoaccept = {
      _id: id,
      email: this.state.email,
    };
    axios
      .post(url + "api/photoaccept", photoaccept)
      .then((res) => {
        if (res.status === 200) {
          this.setState({ Photostatus: res.data.updateddata.Photostatus });

          toast(res.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          this.getData();
        }
      })
      .catch();
  };
  onRejectPhoto = (e) => {
    const id = this.props.match.params.id;
    const photoreject = {
      _id: id,
      email: this.state.email,
      reason: this.state.reasonPhoto,
    };
    axios
      .post(url + "api/photoreject", photoreject)
      .then((res) => {
        if (res.status === 200) {
          this.setState({ Photostatus: res.data.updateddata.Photostatus });

          toast(res.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          this.getData();
        }
      })
      .catch();
  };

  render() {
    const { errors } = this.state;
    return (
      <div>
        <Navbar />
        <div className="d-flex" id="wrapper">
          <Sidebar />
          <div id="page-content-wrapper">
            <div className="container-fluid">
              <h3 className="mt-2 mb-0 text-secondary">
                KYC Verification
                <button
                  onClick={() => this.onSkipbutton()}
                  className="btn btn-outline float-right mt-0 mr-0"
                >
                  Back to Kyc
                </button>
              </h3>

              {/* <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">First Name</label>
                </div>
                <div className="col-md-9">{this.state.name}</div>
              </div> */}

              {/* <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">Last Name</label>
                </div>
                <div className="col-md-9">{this.state.lastname}</div>
              </div> */}

              {/* <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="email">Email</label>
                </div>
                <div className="col-md-9">{this.state.email}</div>
              </div> */}
{/* 
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">Mobile Number</label>
                </div>
                <div className="col-md-9">{this.state.phonenumber}</div>
              </div> */}

              {/* <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">Address</label>
                </div>
                <div className="col-md-9">{this.state.address1}</div>
              </div> */}

              {/* <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">City</label>
                </div>
                <div className="col-md-9">{this.state.city}</div>
              </div> */}

              {/* <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">Pin Code</label>
                </div>
                <div className="col-md-9">{this.state.pincode}</div>
              </div> */}
{/* 
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name">Country</label>
                </div>
                <div className="col-md-9">{this.state.country}</div>
              </div> */}

              <div className="row mt-4 mb-4">
                <div className="col-md-3">
                  <label htmlFor="name">ID Type</label>
                </div>
                <div className="col-md-9">{this.state.IDtype}</div>
              </div>

              <div className="row mt-2 mb-4">
                <div className="col-md-3">
                  <label htmlFor="name"> ID Proof Unique No</label>
                </div>
                <div className="col-md-9">{this.state.IDproofno}</div>
              </div>

              {this.state.IDprooffront ? (
                <div className="row mt-2 mb-4">
                  <div className="col-md-3">
                    <label htmlFor="name"> ID Proof Image</label>
                  </div>
                  <div className="col-md-3">
                    Front
                    <img
                      className="img-fluid proofThumb mb-3"
                      src={imageurl + "kyc/" + this.state.IDprooffront}
                    />
                    <a
                      href={imageurl + "kyc/" + this.state.IDprooffront}
                      target="_blank"
                      className="btn btn-primary mr-3"
                    >
                      view
                    </a>
                  </div>
                  <div className="col-md-3">
                    Back
                    <img
                      className="img-fluid proofThumb mb-3"
                      src={imageurl + "kyc/" + this.state.IDproofback}
                    />
                    <a
                      href={imageurl + "kyc/" + this.state.IDproofback}
                      target="_blank"
                      className="btn btn-primary mr-3"
                    >
                      view
                    </a>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="row mt-2">
                <div className="col-md-3"></div>
                <div className="col-md-9">
                  {this.state.IDstatus == "Pending" ? (
                    <div>
                      <div className="mb-2">
                      <button
                        form="update-id"
                        type="submit"
                        className="btn btn-primary mr-3"
                        onClick={() => this.onAcceptID()}
                      >
                        Accept
                      </button>
                      <button
                        type="submit"
                        className="btn btn-primary mr-3"
                        onClick={() => this.showrejectid()}
                      >
                        Reject
                      </button>
                    </div>
                      {this.state.IDhide == "Show" ? (
                        <div className="col-md-4 px-0">
                          <input
                            onChange={this.onChange}
                            value={this.state.reasonID}
                            id="reasonID"
                            type="text"
                            error={errors.reasonID}
                            className={classnames("form-control", {
                              invalid: errors.reasonID,
                            })}
                          />
                          <span className="text-danger">{errors.reasonID}</span>

                          <button
                            type="submit"
                            className="btn btn-primary mr-3 mt-2"
                            onClick={() => this.onRejectID()}
                          >
                            Submit Reason
                          </button>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3"></div>
                <div className="col-md-9">
                  {this.state.IDstatus == "Verified" ? (
                    <h4 className="modal-title">ID Proof Verified</h4>
                  ) : (
                    ""
                  )}

                  {this.state.IDstatus == "Rejected" ? (
                    <h4 className="modal-title">ID Proof Rejected</h4>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-md-3">
                  <label htmlFor="name">Address ID Type</label>
                </div>
                <div className="col-md-9">{this.state.Addresstype}</div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="name"> Address Proof Unique No</label>
                </div>
                <div className="col-md-9">{this.state.Addressproofno}</div>
              </div>

              {this.state.Addressfile ? (
                <div className="row mt-2">
                  <div className="col-md-3">
                    <label htmlFor="name">Address Proof Image</label>
                  </div>
                  <div className="col-md-3">
                    {" "}
                    <img
                      className="img-fluid proofThumb mb-3"
                      src={imageurl + "kyc/" + this.state.Addressfile}
                    />
                    
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="row mt-2">
                <div className="col-md-3"></div>
                <div className="col-md-9">
                  {this.state.Addresstatus == "Pending" ? (
                    <div>
                       <div className="mb-2">
                        <a
                      href={imageurl + "kyc/" + this.state.Addressfile}
                      target="_blank"
                      className="btn btn-primary mr-3"
                    >
                      view
                    </a>
                      <button
                        form="update-id"
                        type="submit"
                        className="btn btn-primary mr-3"
                        onClick={() => this.onAcceptAddress()}
                      >
                        Accept
                      </button>
                      <button
                        type="submit"
                        className="btn btn-primary mr-3"
                        onClick={() => this.showaddress()}
                      >
                        Reject
                      </button>
                    </div>
                      {this.state.Addresshide == "Show" ? (
                        <div className="col-md-4 px-0">
                          <input
                            onChange={this.onChange}
                            value={this.state.reasonAddress}
                            id="reasonAddress"
                            type="text"
                            error={errors.reasonAddress}
                            className={classnames("form-control", {
                              invalid: errors.reasonAddress,
                            })}
                          />
                          <span className="text-danger">
                            {errors.reasonAddress}
                          </span>

                          <button
                            type="submit"
                            className="btn btn-primary mr-3 mt-2"
                            onClick={() => this.onRejectAddress()}
                          >
                            Submit Reason
                          </button>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3"></div>
                <div className="col-md-9">
                  {this.state.Addresstatus == "Verified" ? (
                    <h4 className="modal-title">Address Proof Verified</h4>
                  ) : (
                    ""
                  )}

                  {this.state.Addresstatus == "Rejected" ? (
                    <h4 className="modal-title">Address Proof Rejected</h4>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              {this.state.Photofile ? (
                <div className="row mt-5">
                  <div className="col-md-3">
                    <label htmlFor="name">PhotoProof</label>
                  </div>
                  <div className="col-md-3">
                    {" "}
                    <img
                      className="img-fluid proofThumb mb-3"
                      src={imageurl + "kyc/" + this.state.Photofile}
                    />
                    
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="row mt-2">
                <div className="col-md-3"></div>
                <div className="col-md-9">
                  {this.state.Photostatus == "Pending" ? (
                    <div>
                       <div className="mb-2">
                        <a
                      href={imageurl + "kyc/" + this.state.Photofile}
                      target="_blank"
                      className="btn btn-primary mr-3"
                    >
                      view
                    </a>
                      <button
                        form="update-id"
                        type="submit"
                        className="btn btn-primary mr-3"
                        onClick={() => this.onAcceptPhoto()}
                      >
                        Accept
                      </button>
                      <button
                        type="submit"
                        className="btn btn-primary mr-3"
                        onClick={() => this.onshowPhoto()}
                      >
                        Reject
                      </button>
                    </div>
                      {this.state.Photohide == "Show" ? (
                        <div className="col-md-4 px-0">
                          <input
                            onChange={this.onChange}
                            value={this.state.reasonPhoto}
                            id="reasonPhoto"
                            type="text"
                            error={errors.reasonPhoto}
                            className={classnames("form-control", {
                              invalid: errors.reasonPhoto,
                            })}
                          />
                          <span className="text-danger">
                            {errors.reasonPhoto}
                          </span>

                          <button
                            type="submit"
                            className="btn btn-primary mr-3 mt-2"
                            onClick={() => this.onRejectPhoto()}
                          >
                            Submit Reason
                          </button>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3"></div>
                <div className="col-md-9">
                  {this.state.Photostatus == "Verified" ? (
                    <h4 className="modal-title">Photo Proof Verified</h4>
                  ) : (
                    ""
                  )}

                  {this.state.Photostatus == "Rejected" ? (
                    <h4 className="modal-title">Photo Proof Rejected</h4>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {/* 
              {this.state.verifiedstatus == "Verified" ? (
                  <div className="col-md-4">
                    <centre>
                      <h4 className="modal-title">
                        All Proofs have been Verified
                      </h4>
                    </centre>
                  </div>
                ) : (
                  ""
                )} */}
            </div>
          </div>
          <ToastContainer />
        </div>
      </div>
    );
  }
}

kycupdate.propTypes = {
  updateUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { updateUser })(withRouter(kycupdate));
