import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { logoutUser } from "../../actions/authActions";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import {Link} from "react-router-dom";
import {faUserAlt} from "@fortawesome/free-solid-svg-icons/faUserAlt";
import TradingViewWidget, { Themes } from 'react-tradingview-widget';
var CanvasJSReact = require('../../canvasjs.react');
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;


class Dashboard extends Component {

    onLogoutClick = e => {
        e.preventDefault();
        this.props.logoutUser();
    };

    // componentDidMount() {
    //     console.log(document.querySelectorAll(".list-group-item-action.active")[0].offsetTop,"top");
    //     var pagelinkofset = document.querySelectorAll(".list-group-item-action.active")[0].offsetTop;
    //     document.getElementById("scrollbar_sidebar_id").scrollTo(0,pagelinkofset);
    //   }

    render() {
const {t} = this.props;
        //const { user } = this.props.auth;
        return (
            <div>
                <Navbar/>
                <div className="d-flex" id="wrapper">
                    <Sidebar/>
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <h3 className="mt-2 text-secondary">{t("DASHBOARD")}</h3>
                            <div className="row px-0 px-md-2 mt-4">
                                <div className="col-sm-6 col-lg-4 p-sm-2 mb-4 mb-sm-0">
                                    <div className="card text-white" style={{backgroundColor : "cornflowerblue"}}>
                                        <div className="card-body pb-4">
                                            <h5 className="card-title">{t("USERS")}</h5>
                                            <p className="card-text mb-4">{t("WITH SUPPORTING")}</p>
                                            <Link to="/users" className="btn btn-fill"><FontAwesomeIcon icon={faUserAlt}/>{t("GO_TO_USER")}</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-lg-4 p-sm-2 mb-4 mb-sm-0">
                                    <div className="card text-white" style={{backgroundColor: "cadetblue"}}>
                                        <div className="card-body pb-4">
                                            <h5 className="card-title">{t("ENQUIRES")}</h5>
                                            <p className="card-text mb-4">{t("WITH SUPPORTING")}</p>
                                            <Link to="/contactus" className="btn btn-fill"><FontAwesomeIcon icon={faUserAlt}/>{t("GO_TO_CONTACT")}</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

Dashboard.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(
    mapStateToProps,
    { logoutUser }
)(withTranslation()(Dashboard),Dashboard);
