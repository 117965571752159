import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Select from "react-select";
import {Modal,Button} from 'react-bootstrap/';
import axios from "axios";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import { toast, ToastContainer} from "react-toastify";
import $ from 'jquery';
import { withTranslation, WithTranslation } from "react-i18next";
import keys from "../../actions/config";
const url = keys.baseUrl;

class Withdraw extends Component {
    
    constructor(props) {

   super(props);
const {t} = this.props;

        this.columns = [
            {
                key: "receiveraddress",
                text: t("TO_ADDRESS"),
                className: "receiveraddress",
                align: "left",
                sortable: true,
                width:200,
               
            },
            {
                key: "cryptoType",
                text: t("COIN"),
                className: "cryptoType",
                align: "left",
                sortable: true
            },
            {
                key: "username",
                text: t("USER_NAME"),
                className: "userId",
                align: "left",
                sortable: true,
                
            },
            {
                key: "transferamount",
                text: t("TRANSFER_AMOUNT"),
                className: "transferamount",
                align: "left",
                sortable: true
            },
            {
                key: "status",
                text: t("STATUS"),
                className: "status",
                align: "left",
                sortable: true
            },
             {
                key: "created_date",
                text: t("CREATED_DATE"),
                className: "created_date",
                align: "left",
                sortable: true
            },
            {
                key: "action",
                text: t("ACTION"),
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
                    console.log(record,'ddddddddd')
                     if(record.status=="Confirmed")
                        {
                           return (
                        <Fragment>
                           <div className="d-flex"><button
                                data-toggle="modal"
                                data-target="#show-withdraw-modal"
                                className="fa fa-stack-exchange btn-primary mr-2"
                                onClick={() => this.showtransactionid(record)}
                                >                               
                            </button></div> 
                        </Fragment>
                    );
                        }
                        if(record.status=="Pending")
                        {

                    return (
                        <Fragment>
                            <button
                                data-toggle="modal"
                                data-target="#show-withdraw-modal"
                                className="btn btn-primary btn-sm mr-2"
                                onClick={() => this.showDetails(record)}
                                >
                                <i className="fa fa-check"></i>
                            </button>
                        </Fragment>
                    );
                        }
                }
            }
        ];

        this.config = {
            page_size: 10,
            length_menu: [ 10, 20, 50 ],
            filename: "Withdraw",
            no_data_text: t('NO_WITHDRAW_FOUND'),
            language: {
                length_menu: t("SHOW_MENU"),
                filter: t("FILTER IN RECORDS"),
                info: t("SHOWING_START_END"),
                pagination: {
                    first: t("FIRST"),
                    previous: t("PREVIOUS"),
                    next: t("NEXT"),
                    last: t("LAST")
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
                records: [],
                showDetails : false,
                showDetails1 : false,
                id: '',
                status : '',
                transferamount: '',
                finalamount:'',
                userId:'',
                cryptoType: '',
                userId: '',
                receiveraddress: '',
                tagid: '',
                errors: '',
                transactionId: ''

        };
        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.getData()
    };
    showtransactionid = (record) => {
       this.setState({transactionId:record.transactionId})
       this.setState({showDetails1:true})
    }
    showDetails = (record) => {
      console.log(record,'record')
      this.setState({receiveraddress:record.receiveraddress})
      this.setState({tagid:''})
      this.setState({finalamount:record.finalamount})
      this.setState({id:record._id})
      this.setState({userId:record.userId})
      this.setState({cryptoType:record.cryptoType})
      this.setState({transferamount:record.transferamount})
      this.setState({status:record.status})
      this.setState({showDetails:true})
    }
    confirmSubmit = () => {
      var id = this.state.id;
      var passVal = {id:id,status:"Confirmed"};
      axios
          .post(url+"api/updatewithdraw",passVal)
          .then(res => {
            toast(res.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
            this.setState({showDetails:false});
            this.getData();
          })
          .catch()
    }
    rejectSubmit = () => {
      var id = this.state.id;
      var amount = this.state.finalamount;
      var userid = this.state.userId;
      var cryptoType = this.state.cryptoType;
      var passVal = {id:id,status:"Rejected",amount:amount,userid:userid,cryptoType:cryptoType};
      axios
          .post(url+"api/updatewithdraw",passVal)
          .then(res => {
            toast(res.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
            this.setState({showDetails:false});
            this.getData();
          })
          .catch()
    }

    handleClosedetails = (record) => {
      this.setState({showDetails:false})
    }

    handleClosedetails1 = (record) => {
      this.setState({showDetails1:false})
    }

    getData() {
        axios
            .post(url+"api/withdraw-data")
            .then(res => {
              console.log(res);
                this.setState({ records: res.data})
                this.setState({userId:res.data.userId})
            })
            .catch()
    }


    pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }

    render() {
const {t} = this.props;

        // console.log(this.state.userId,'res.data.userIdres.data.userId')
        return (
            <div>
            <Modal className="withdrawdetails" show={this.state.showDetails} onHide={this.handleClosedetails}  aria-labelledby="contained-modal-title-vcenter" centered>
      				<Modal.Header closeButton>
      				<Modal.Title>Details</Modal.Title>
      				</Modal.Header>
      				<Modal.Body>
      				<div className="popUpSpace">
              <div className="row mt-2">
                  <div className="col-md-3">
                      <label htmlFor="answer">To Address</label>
                  </div>
                  <div className="col-md-9">
                     <div className="elipsis-225">{this.state.receiveraddress}</div>
                  </div>
              </div>

              <div className="row mt-2">
                  <div className="col-md-3">
                      <label htmlFor="answer">Tag id/Memo</label>
                  </div>
                  <div className="col-md-9">
                      {this.state.tagid}
                  </div>
              </div>
              <div className="row mt-2">
                  <div className="col-md-3">
                      <label htmlFor="answer">Transfer Coin</label>
                  </div>
                  <div className="col-md-9">
                      {this.state.cryptoType}
                  </div>
              </div>
              <div className="row mt-2">
                  <div className="col-md-3">
                      <label htmlFor="answer">Transfer Amount</label>
                  </div>
                  <div className="col-md-9">
                      {this.state.transferamount}
                  </div>
              </div>
              <div className="row mt-2">
                  <div className="col-md-3">
                      <label htmlFor="answer">Status</label>
                  </div>
                  <div className="col-md-9">
                      {this.state.status}
                  </div>
              </div>
            	</div>
      				</Modal.Body>
      				<Modal.Footer>
      					<Button className="btnDefaultNewBlue" onClick={this.rejectSubmit}>
      						Reject
      					</Button>
      					<Button onClick={this.confirmSubmit} className="btnDefaultNew">
      						Confirm
      					</Button>
      				</Modal.Footer>
      			</Modal>
            <Modal show={this.state.showDetails1} onHide={this.handleClosedetails1}  aria-labelledby="contained-modal-title-vcenter" centered>
              <Modal.Header closeButton>
              <Modal.Title>{t("TRANSACTION_ID")}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
              <div className="popUpSpace">
              <div className="row mt-2">
                  
                  <div className="col-md-12" style={{"word-wrap":"break-word"}}>
                      {this.state.transactionId ?this.state.transactionId : t("THERE_IS_NO") }
                  </div>
              </div>

             
              </div>
              </Modal.Body>
              <Modal.Footer>
              </Modal.Footer>
            </Modal> 
                <Navbar/>
                <div className="d-flex" id="wrapper">
                    <Sidebar/>
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <h3 className="mt-2 text-secondary">{t("WITHDRAW_LIST")}</h3>
                            <div className="resonsive_table">
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                responsive={true}
                                onPageChange={this.pageChange.bind(this)}
                                className="table table-bordered table-striped withdraw_dt_table"
                            />
                            </div>
                        </div>
                    </div>
                    <ToastContainer/>
                </div>
            </div>
        );
    }

}

Withdraw.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});

export default connect(
    mapStateToProps
)(withTranslation()(Withdraw),(Withdraw));
