import React, { Component, Fragment } from "react";

//import compoents
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import ReactDatatable from '@ashvin27/react-datatable';
import { withTranslation} from "react-i18next";

//import Actions
import {perpetualOrderHistory} from '../../actions/reportActions'

class tradehistory extends Component {
    constructor(props) {
        super(props);
        const {t}=this.props
      
        this.columns = [
            {
                key: "orderDate",
                text: t("DATE"),
                className: "Date",
                align: "left",
                sortable: true,
                width: 200,
               
            },
            {
                key: "firstCurrency",
                text: t("BASE_CURRENCY"),
                className: "pairName",
                align: "left",
                sortable: true,
                width: 200,
            },
            {
                key: "secondCurrency",
                text: t("QUOTE_CURRENCY"),
                className: "name",
                align: "left",
                sortable: true,
            },
            {
                key: "orderType",
                text: t("TYPE"),
                className: "Type",
                align: "left",
                sortable: true,
                width: 200,
            },          
            {
                key: "buyorsell",
                text: t("SIDE"),
                className: "Side",
                align: "left",
                sortable: true,
                width: 200,
            },
            {
                key: "averagePrice",
                text: t("AVERAGE"),
                className: "Average",
                align: "left",
                sortable: true,
                width: 200,
            },
            {
                key: "price",
                text: t("PRICE"),
                className: "Price",
                align: "left",
                sortable: true,
                width: 200,
            },
          
            {
                key: "filledQuantity",
                text: t("EXECUTED"),
                className: "Executed",
                align: "left",
                sortable: true,
                width: 200,
            },
            {
                key: "quantity",
                text: t("AMOUND"),
                className: "Amount",
                align: "left",
                sortable: true,
                width: 200,
            },
            {
                key: "quantity",
                text: t("TOTAL"),
                className: "Total",
                align: "left",
                sortable: true,
                width: 200,
            },
            // {
            //     key: "trader",
            //     text: "Trader",
            //     className: "trader",
            //     align: "left",
            //     sortable: true,
            //     width: 200,
            //     cell: record => {
            //         return (
            //             record.traderDet
            //             ?
            //                 record.traderDet.name
            //                 ?
            //                     record.traderDet.name
            //                 :
            //                     // record.traderDet.email.split('@')[0].substring(0, 10)
            //                     record.traderDet.email
            //             :
            //                 ''
            //         )
            //     }
            // },
         
        ];

        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            filename: "Order",
            no_data_text: 'NO_EMAIL_TEMPLATES_FOUND',
            language: {
                length_menu: t("SHOW_MENU"),
                filter: t("FILTER IN RECORDS"),
                info: t("SHOWING_START_END"),
                pagination: {
                    first: t("FIRST"),
                    previous: t("PREVIOUS"),
                    next: t("NEXT"),
                    last: t("LAST")
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };
        this.state = {
            records: [],
            loader: false,
            page: 1,
            limit: 10,
            count: 0,


        };
        this.handlePagination = this.handlePagination.bind(this);

    }
    componentDidMount() {
        const { page, limit } = this.state;
        let reqData = {
            page,
            limit
        }
        this.getPerpetualOrder(reqData)
    };

    handlePagination(index) {
        let reqData = {
            page: index.page_number,
            limit: index.page_size,
            search: index.filter_value
        }
        this.getPerpetualOrder(reqData);
        this.setState({ page: index.page_number, limit: index.page_size, search: index.filter_value })
    }


   async getPerpetualOrder(reqData) {
        try {
            this.setState({ 'loader': true })

            const { status, loading, result } = await perpetualOrderHistory(reqData);
            this.setState({ 'loader': loading })
            if (status == 'success') {
                this.setState({ "count": result.count, 'records': result.data })
            }
        } catch (err) { }

    

    }

    render() {
const {t}=this.props
        const { records ,count,loader} = this.state
        return (
            <div>
                <Navbar />
                <div className="d-flex" id="wrapper">
                    <Sidebar />
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <h3 className="mt-2 text-secondary">{t("PERPETUAL_ORDER_HISTORY")}</h3>
                            <div className="resonsive_table">
                            <ReactDatatable
                                  config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                total_record={count}
                                dynamic={true}
                                loading={loader}
                                onChange={this.handlePagination}
                                className="table table-bordered table-striped perpetual_order_table"
                            />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}



export default (withTranslation()(tradehistory));
