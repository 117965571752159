import axios from "axios";
import {
    GET_ERRORS,
    CMS_ADD,
    CMS_UPDATE
} from "./types";
import keys from "./config";
const url = keys.baseUrl;
export const addCms = (cmsData) => dispatch => {
    axios
        .post(url+"api/cms-add", cmsData)
        .then(res =>
            dispatch({
                type: CMS_ADD,
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        })
    );
};


export const updateCms = (cmsData) => dispatch => {
    console.log("cmsData_cmsData",cmsData);
    axios(url+"api/cms-update", {
        method: "POST",
            headers:{
                "Content-Type": "multipart/form-data"
                // "Content-Type": "application/json"
            },
            data:cmsData
        })
        .then(res =>
            dispatch({
                type: CMS_UPDATE,
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        })
    );
};


// export const updateCms = (cmsData) => dispatch => {
//     console.log("cmsData_cmsData",cmsData);
//     axios(url+"api/testapi", {
//         method: "POST",
//             headers:{
//                 "Content-Type": "multipart/form-data"
//                 // "Content-Type": "application/json"
//             },
//             data:cmsData
//         })
//         .then(res =>
//            console.log("res_res",res)
//         ).catch(err =>
//         dispatch({
//             type: GET_ERRORS,
//             payload: err.response.data
//         })
//     );
// };
