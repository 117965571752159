import React, { Component, Fragment } from "react";
import ReactDatatable from '@ashvin27/react-datatable';

//import compents
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { withTranslation, WithTranslation } from "react-i18next";
//import actions
import {perpetualTradeHistory} from '../../actions/reportActions'
import { withRouter } from "react-router-dom";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import axios from "axios";
import keys from "../../actions/config"
const url = keys.baseUrl;
class CommssionHistory extends Component {
    constructor(props) {
        super(props);
        const {t}=this.props
        console.log("prpos",props)
        this.columns = [          
            {
                key: "currecnysymbol",
                text: t("CURRENCY"),
                className: "Status",
                align: "left",
                sortable: true,
                width: 200,
            },    
            {
                key: "tradetype",
                text: t("TRADE_TYPE"),
                className: "IPaddress",
                align: "left",
                sortable: true,
                width: 200,
            },
           
            {
                key: "fees",
                text: t("TOTAL_EARNED"),
                className: "Browser",
                align: "left",
                sortable: true,
                width: 200,
                cell:records=>{
                    var fees = records.fees != null ? records.fees.toFixed(8) : ""
                    return (
                        <Fragment>
                            {fees}
                        </Fragment>
                    )
                }
            },
        ];

        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            filename: "Order",
            no_data_text: t('NO_RECORD_FOUND!'),
            language: {
                length_menu: t("SHOW_MENU"),
                filter: t("FILTER IN RECORDS"),
                info: t("SHOWING_START_END"),
                pagination: {
                    first: t("FIRST"),
                    previous: t("PREVIOUS"),
                    next: t("NEXT"),
                    last: t("LAST")
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };
        this.state = {
            records: [],
        };

    }
    getData(){
        let id = this.props.auth.user.id;
        axios.get(url+"api/commissionhistory").then(res=>{
            console.log(res,"res")
            this.setState({records:res.data})
        })
    }
    componentDidMount() {
        this.getData()
    };

    render() {
      const {t}=this.props
        const { records,count } = this.state
        return (
            <div>
                <Navbar />
                <div className="d-flex" id="wrapper">
                    <Sidebar />
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <h3 className="mt-2 text-secondary">{t("COMMISSION_HISTORY")}</h3>
                            <div className="resonsive_table">
                            <ReactDatatable
                                config={this.config}
                                records={records}
                                columns={this.columns}
                                dynamic={false}
                                total_record={count}
                                // onChange={this.handlePagination} 
                                className="table table-bordered table-striped login_hist_table" />
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

CommssionHistory.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
  };
  const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
  });
  export default connect(
    mapStateToProps,
  )(withTranslation()(CommssionHistory),withRouter(CommssionHistory));

//export default (LoginHistory);
