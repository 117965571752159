import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { withTranslation, WithTranslation } from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import axios from "axios";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import SupportReplyModal from "../partials/SupportReplyModal";
import { toast, ToastContainer} from "react-toastify";
import keys from "../../actions/config";
import { Loader } from "react-full-page-loader-overlay";
const url = keys.baseUrl;
class tradehistory extends Component {
    constructor(props) {
        super(props);
        const {t}=this.props
        this.state = {
            total_pages: 0,
            records: [],
            count:0,
            loadershow:false,
        };
        this.columns = [
            {
                key: "pairname",
                text: t("CONTRACTS"),
                className: "pairname",
                align: "left",
                sortable: true,
                width:200,
            },{
                key: "Buyemail",
                text: t("BUY_EMAIL"),
                className: "name",
                align: "left",
                sortable: true,
                width:200,
            },{
                key: "Sellemail",
                text: t("SELL_EMAIL"),
                className: "name",
                align: "left",
                sortable: true,
                width:200,
            },{
                key: "filledtype",
                text: t("FILLED_TYPE"),
                className: "filledtype",
                align: "left",
                sortable: true,
                width:200,
            },{
                key: "filledAmount",
                text: t("OUANTITY"),
                className: "filledAmount",
                align: "left",
                sortable: true,
                width:200,
            },
            {
                key: "Fee",
                text: t("FEE"),
                className: "Fee",
                align: "left",
                sortable: true,
                width:200,
            },{
                key: "Price",
                text: t("PRICE"),
                className: "Price",
                align: "left",
                sortable: true,
                width:200,
            },{
                key: "orderValue",
                text: t("ORDER_VALUE"),
                className: "orderValue",
                align: "left",
                sortable: true,
                width:200,
            },{
                key: "Remaining",
                text: t("REMAINING"),
                className: "Remaining",
                align: "left",
                sortable: true,
                width:200,
            },{
                key: "orderType",
                text: t("ORDER_TYPE"),
                className: "orderType",
                align: "left",
                sortable: true,
                width:200,
            },{ 
                key: "_id",
                text: "#",
                className: "_id",
                align: "left",
                sortable: true,
                width:200,
            },{
                key: "createdDate",
                text: t("DATE"),
                className: "createdDate",
                align: "left",
                sortable: true,
                width:200,
            },




        ];

        this.config = {
            page_size: 10,
            length_menu: [ 10, 20, 50 ],
            filename: "Order",
            no_data_text: t('NO_RECORD_FOUND'),
            language: {
                length_menu: t("SHOW_MENU"),
                filter: t("FILTER IN RECORDS"),
                info: t("SHOWING_START_END"),
                pagination: {
                    first: t("FIRST"),
                    previous: t("PREVIOUS"),
                    next: t("NEXT"),
                    last: t("LAST")
                }
            },
            show_length_menu: false,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };
}
      componentDidMount() {
        this.getData()
      };

      pageChange(pageData) {
         console.log("OnPageChange", pageData);
      }
    tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
        if(key === "sort_order" && data[key]){
            return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
        } else {
            return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
        }
        }).join('&');

        this.getData(queryString);
    }
      getData(queryString = ""){
        // this.setState({loadershow:true})
        let urldet = url+"api/trade_history/spot?" + queryString;
          axios
          .get(urldet)
          .then(res => {
            console.log(res,'ressssss')
            this.setState({
                    total   : res.data.total,
                    records : res.data.result,
                    count   : res.data.totalcount,
                    loadershow : false

                })
          })
          .catch()
      }

    render() {
 const {t}=this.props
            const {records} = this.state
        return (
            <div>
            {/* <Loader centerBorder="#66B7F5" show={this.state.loadershow}  /> */}
                <Navbar/>
                <div className="d-flex" id="wrapper">
                    <Sidebar/>
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <h3 className="mt-2 text-secondary">{t("TRADE_HISTORY")} </h3>
                            <div className="resonsive_table">
                           <ReactDatatable
                                className="table table-bordered table-striped trade_history_table"
                                config={this.config}
                                records={records}
                                columns={this.columns}
                                dynamic={true}
                                total_record={this.state.count}
                                onChange={this.tableChangeHandler}
                            />
                            </div>
                        </div>
                    </div>
                    <ToastContainer/>
                </div>
            </div>
        );
    }

}

tradehistory.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});

export default connect(
    mapStateToProps
)(withTranslation()(tradehistory),(tradehistory));
