import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import axios from "axios";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import UserAddModal from "../partials/UserAddModal";
import UserUpdateModal from "../partials/UserUpdateModal";
import { toast, ToastContainer} from "react-toastify";
import keys from "../../actions/config";
import {Modal,Button} from 'react-bootstrap/';
import ConfirmationModal from "./ConfirmationModal";
import { withTranslation } from 'react-i18next';

const url = keys.baseUrl;
class Users extends Component {

    constructor(props) {
        super(props);

        const {t} = this.props;

        this.columns = [
        {
                key: "date",
                text: t("CREATE_DATE"),
                className: "date",
                align: "left",
                sortable: true,
                cell: record => {
                    const dateReplace =  record.date.replace("T", "  ") 
                    const split = dateReplace.split(' ');
                    const join = split[0] + " " + "T" + split[2];
                    return(
                        join
                    )
                }
            },
            {
                key: "email",
                text: t("EMAIL"),
                className: "email",
                align: "left",
                sortable: true
            },
            {
                key: "status",
                text: t("STATUS"),
                className: "status",
                align: "left",
                sortable: true,
                cell: record => {
                    console.log(record,'recordssssss');
                    const checkin = record.active;
                    console.log(checkin,'checkineeed');
                    if(checkin == "Activated"){
                        var lockVal = t("ACTIVE");
                    } else{
                        var lockVal = t('DEACTIVE');
                    }

                    return (
                        lockVal
                    );
                }
            },

            //  {
            //     key: "moderator",
            //     text: "Moderator status",
            //     className: "moderator",
            //     align: "left",
            //     sortable: true,
            //     cell: record => {

            //         const checkin = record.moderator;
            //         console.log(checkin,'checkineeed');
            //         if(checkin=='1'){
            //             var lockVal = 'Active';
            //         } else{
            //             var lockVal = 'Deactive';
            //         }

            //         return (
            //             lockVal
            //         );
            //     }
            // },
            {
                key: "action",
                text: t("ACTION"),
                className: "action",
                width: 200,
                align: "left",
                sortable: false,
                cell: record => {
                    console.log(record,'recordssssss');
                    const checkin = record.active;
                    console.log(checkin,'checkineeed');
                    if(checkin=='Activated'){
                        var lockVal = 'fa fa-unlock';
                    } else{
                        var lockVal = 'fa fa-lock';
                    }

                    return (
                        <Fragment>
                          <div className="d-flex"> <button
                                className="btn btn-outline btn-sm mr-2"
                                onClick={() => this.deleteRecord(record)}>
                                <i className="fa fa-trash"></i>
                            </button>
                             <button
                               className="btn btn-primary btn-sm mr-2"
                                onClick={() => this.changestatus(record)}>
                                <i className={lockVal}></i>
                            </button>
                            {record.google == "Enabled" &&
                             <button
                               className="btn btn-primary btn-sm mr-2"
                                onClick={() => this.changeDisabled(record)}>
                                {t("DISABLED")}
                             </button>
                            }
                            {/*<button title="Moderator"
                               className="btn btn-primary btn-sm mr-1"
                                onClick={() => this.changemoderator(record)}>
                                <i className="fa fa-stack-exchange"></i>
                            </button>

                             <button title="block chat"
                               className="btn btn-primary btn-sm mr-1"
                                onClick={() => this.chatblock(record)}>
                                <i class="fa fa-user-times"></i>
                            </button>*/}
                    </div> 

                        </Fragment>
                    );
                }
            }
        ];

        this.config = {
            page_size: 10,
            length_menu: [ 10, 20, 50 ],
            filename: "Users",
            no_data_text: t('NO_USER_FOUND'),
            sort:{column: "Created date", order: "desc"},
            language: {
                length_menu: t("SHOW_MENU"),
                filter: t("FILTER IN RECORDS"),
                info: t("SHOWING_START_END"),
                pagination: {
                    first: t("FIRST"),
                    previous: t("PREVIOUS"),
                    next: t("NEXT"),
                    last: t("LAST")
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
            // defaultSortAsc: true,
        };

        this.state = {
            currentRecord: {
                records: [],
                id: '',
                name: '',
                email: '',
                status:'',
                password: '',
                password2: '',
                lockVal: '',
                blockinguser: '',
                bonususer: '',
                blocking_time: '',
                bonus_amount: '',
                modalshow: false,
                modalshow1: false,
                responsive: true,
                DeletUser:false,
                DeletRecord:{},
            }
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.getData()
        console.log(this.state,'statezzzzzz');
    };

    componentWillReceiveProps(nextProps) {
        this.getData()
    }

    getData() {
        axios
            .post(url+"api/user-data")
            .then(res => {
                this.setState({ records: res.data})
               console.log(res,'reszzzzhhhhhh');
            })
            .catch()
    }

    editRecord(record) {
        this.setState({ currentRecord: record});

    }
    changeDisabled(record) {
        axios
        .post(url+"api/user-disabled", {_id: record._id})
        .then(res =>{
            console.log(res.data,'message')
             if (res.status === 200) {
               
                   toast(res.data.message, {
                       position: toast.POSITION.TOP_CENTER,
                   })
                   this.getData()
                }
        })
    }
    deleteRecord(record) {
        // axios
        //     .post(url+"api/user-delete", {_id: record._id})
        //     .then(res => {
        //         if (res.status === 200) {
        //            toast(res.data.message, {
        //                position: toast.POSITION.TOP_CENTER,
        //            })
        //            this.getData()
        //         }
        //     })
        //     .catch();
        // this.getData();
        this.setState({"DeletRecord":record,"DeletUser":true})
    }
 blockingsubmission = () => {
        if(this.state.blockinguser=='' || this.state.blocking_time=='')
        {
            alert("Enter blockind time");
            return false;
        }
        axios
            .post(url+"api/blockuser", {_id: this.state.blockinguser,blocking_time:this.state.blocking_time})
            .then(res => {
                if (res.status === 200) {
            
                   toast(res.data.message, {
                       position: toast.POSITION.TOP_CENTER,
                   })
                }
            })
            .catch();
        this.getData();
        this.modalclose();
    }

     bonussubmission = () => {
        if(this.state.bonususer=='' || this.state.bonus_amount=='')
        {
            alert("Enter blockind time");
            return false;
        }
        axios
            .post(url+"api/addbonus", {_id: this.state.bonususer,bonus_amount:this.state.bonus_amount})
            .then(res => {
                if (res.status === 200) {
                    
                   toast(res.data.message, {
                       position: toast.POSITION.TOP_CENTER,
                   })
                }
            })
            .catch();
        this.getData();
        this.modalclose1();
    }

chatblock(record) {
this.setState({modalshow:true,blockinguser:record._id})
};



onChange = (e) => {
this.setState({ [e.target.id]: e.target.value });
};

modalclose = () => {
this.setState({modalshow:false});
}

modalclose1 = () => {
this.setState({modalshow1:false});
}


 changestatus(record) {
    const {t}=this.props
        axios
            .post(url+"api/user-changestatus", {_id: record._id})
            .then(res => {
                console.log(res,'reschange');
                if (res.status === 200) {
                    console.log("res.data.message",res.data.message);
                    let messages=t(res.data.message)
                   toast(messages, {
                       position: toast.POSITION.TOP_CENTER,
                   })
                     this.getData();
                }
            })
            .catch();
        // this.getData();
    }
 
    changemoderator(record) {
        axios
            .post(url+"api/user-changemoderator", {_id: record._id})
            .then(res => {
                console.log(res,'reschange');
                if (res.status === 200) {
                   toast(res.data.message, {
                       position: toast.POSITION.TOP_CENTER,
                   })
                }
            })
            .catch();
        this.getData();
    }

    pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }

    render() {
const {t} = this.props;

        return (
            <div>
                <Navbar/>
                <div className="d-flex" id="wrapper">
                    <Sidebar/>
                    {this.state.DeletUser ? <ConfirmationModal record={this.state.DeletRecord} getData={this.getData} onDismiss={()=>{this.setState({"DeletUser":false})}}/>:""}
                    <UserAddModal/>
                    <UserUpdateModal record={this.state.currentRecord}/>
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            {/* <button className="btn btn-outline float-right mt-0 mr-2 btn_pos_right" data-toggle="modal" data-target="#add-user-modal"><FontAwesomeIcon icon={faPlus}/> add</button> */}
                            <div className="clearfix" />
                            <h3 className="mt-2 text-secondary">{t("USER_MANAGEMENT")} </h3>
                            <div className="resonsive_table">
                                <ReactDatatable
                                    config={this.config}
                                    responsive={true}
                                    records={this.state.records}
                                    columns={this.columns}
                                    onPageChange={this.pageChange.bind(this)}
                                    className="table table-bordered table-striped user_dt_table"
                            />
                            </div>
                        </div>
                    </div>
                    <ToastContainer/>
                </div>
            <Modal show={this.state.modalshow} onHide={this.modalclose}  aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Block chat</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                    <div className="row">
                            <div className="col-md-4">
                                <div className="checkbox pt-2"><label>Blocking time (in hours)</label></div>
                                </div>
                            <div className="col-md-8">
                                    <div className="def-number-input number-input safari_only">
                                    <input className="quantity" min="0" name="blocking_time" id="blocking_time" onChange={this.onChange} type="number" value={this.state.blocking_time}/>
                                    </div>
                            </div>
                    </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                        <Button variant="secondary btnDefaultNewBlue" onClick={this.modalclose}>
                            Cancel
                        </Button>
                        {(this.state.blockinguser=='' || this.state.blocking_time=='')?
                        <Button disabled="disabled" variant="primary btnDefaultNew" onClick={this.blockingsubmission}>
                            Confirm
                        </Button>
                        : <Button variant="primary btnDefaultNew" onClick={this.blockingsubmission}>
                            Confirm
                        </Button>}

                </Modal.Footer>
            </Modal>
             <Modal show={this.state.modalshow1} onHide={this.modalclose}  aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add Bonus</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                    <div className="row">
                            <div className="col-md-4">
                                <div className="checkbox pt-2"><label>Bonus amount in BTC</label></div>
                                </div>
                            <div className="col-md-8">
                                    <div className="def-number-input number-input safari_only">
                                    <input className="quantity" min="0" name="bonus_amount" id="bonus_amount" onChange={this.onChange} type="number" value={this.state.bonus_amount}/>
                                    </div>
                            </div>
                    </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                        <Button variant="secondary btnDefaultNewBlue" onClick={this.modalclose}>
                            Cancel
                        </Button>
                        {(this.state.bonususer=='')?
                        <Button disabled="disabled" variant="primary btnDefaultNew" onClick={this.bonussubmission}>
                            Confirm
                        </Button>
                        : <Button variant="primary btnDefaultNew" onClick={this.bonussubmission}>
                            Confirm
                        </Button>}

                </Modal.Footer>
            </Modal>
            </div>

        );
    }

}

Users.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});

export default connect(
    mapStateToProps
)(withTranslation()(Users),(Users));
