export default {
    "DASHBOARD" : "Dashboard",
    "USERS":"Users",
    "WITH SUPPORTING":"With supporting text below as a natural lead-in to additional content.",
    "GO_TO_USER":"Go to Users",
    "ENQUIRES":"Enquires",
    "GO_TO_CONTACT":"Go to Contact",
    "FORGOT PASSWORD":"Forgot Password",
    "EMAIL":"Email",
    "FORGOT":"Forgot",
    "BACKTOLOGIN":"Back to Login?",
    "LOGIN":"Log in",
    "PASSWORD":"Password",
    "DON'T HAVE ACCOUNT":"Don't have an account?",
    "FORGOT PASSWORD?":"Forgot Password?",
    "SETTINGS":"Settings",
    "PROFILE":"profile",
    "CHANGE PASSWORD":" Change Password",
    "LANGUAGE":"Language",
    "LOGOUT":"Log Out",
    "NAME":"Name",
    "PHONE_NUMBER":"Phone Number",
    "UPDATE_PROFILE":"Update Profile",
    "EDIT_PROFILE":"Edit Profile",
    "SEND_OTP":"Send OTP",
    "OTP":"otp",
    "OLD_PASSWORD":"Old Password",
    "NEW_PASSWORD":"New Password",
    "CONFROM_PASSWORD":"Confirm Password",
    "UPDATE_PASSWORD":"Update Password",
    "CONTACT_PERSION":"*Contact Person",
    "SITE_NAME":"*Site Name",
    "SITE_DESCRIPTION":"*Site Description",
    "ADDRESS":"*Address",
    "REGISTER_CODE":"Register Code",
    "MOBILE_NUMBER":"Mobile Number",
    "COMPANY INFROMATION LINK":"Company information link",
    "WALLET LICENSE INFROMATION LINK ":"wallet license information link",
    "TWITTER_URL":"Twitter URL",
    "MEDIUM URL":"Medium URL",
    "TELEGRAM_LINK":"Telegram Link",
    "FACEBOOK_URL":"Facebook URL",
    "LINKEDIN_URL":"Linkedin URL",
    "COPYRIGHTS_TEXT":"Copyrights Text",
    "FORCED_LIQUIDATION":"Forced liquidation",
    "SITE_LOGO":"Site Logo",
    "CHOOSE_FILE":"Choose File",
    "UPDATE_SETTINGS":"Update Settings",
    "ENABLE":"Enable",
    "DISABLE":"Disable",
    "USER_MANAGEMENT":"User management",
    "CREATE_DATE":"Created date",
    "STATUS":"Status",
    "ACTION":"Action",
    "DISABLED":" Disabled",
    "FILTER IN RECORDS":"Filter in records...",
    "FIRST":"First",
    "PREVIOUS":"Previous",
    "NEXT":"Next",
    "LAST":"Last",
    "USER_BALANCE":"User balance List",
    "USER_NAME":"User Name",
    "CURRENCY":"Currency",
    "SPOT_WALLET":"Spot Wallet",
    "DERIVATIVE_WALLET":"Derivative Wallet",
    "KYC_VERFICATION":"KYC Verification",
    "IDENTITY_DOCUMENT":"Identity Document",
    "RESIDENTIAL_DOCUMENT":"Residential Document",
    "DEPOSITE_LIST":"Deposit List",
    "TO_ADDRESS":"To Address",
    "TRANSFER_AMOUNT":"Transfer Amount",
    "CREATED_DATE":"Created date",
    "COIN":"Coin",
    "USER_MAIL":"User email",
    "TRANSACTION_ID":"Transaction Id",
    "ACTIVE":"Active",
    "DEACTIVE":"Deactive",
    "PAGE_STATUS":"Page status",
    "SUCCESSFULLY":"successfully",
    "ACTIVATED":"Activated",
    "DEACTIVATED":"DeActivated",
    "DELETE_USER":"Delete User",
    "ARE_YOU_DELETE":"Are you sure you want to delete the user?",
    "CANCEL":"Cancel",
    "CONFORM":"Confirm",
    "WITHDRAW_LIST":"Withdraw List",
    "TAG_ID":"Tag id/Memo",
    "REJECT":"Reject",
    "THERE_IS_NO":"There is no transactionId",
    "START_DATE":"Start Date",
    "END_DATE":"End Date",
    "TOKEN_NAME":"Token Name",
    "INDUSTRY":"Industry",
    "WEB_SITE":"Web Site",
    "FA_FA_LOCK":"fa fa-lock",
    "FA_FA_UNLOCK":"fa fas-unlock",
    "ADD":"Add",
    "ADD_LAUNCHPAD":"Add Launchpad Details",
    "TOKEN_SYMBOL":"Token Symbol",
    "AVAILABLE_CURRENCY":"Available Currency",
    "TOKEN_ICON":"Token ICON",
    "CHOOSE_FILE":"Choose File",
    "TOKEN_LAUNCH_PRICE":"Token Launch Price",
    "MINIMUM_PURCHASE":"Minimum Purchase Amount",
    "DISCOUNT":"Discount",
    "TOKEN_AVAILABLE_TO_SALE":"Token Available to Sale",
    "TOKEN_MAX_SUPPLY":"Token Max Supply",
    "INDUSTRY":"Industry",
    "WEBSITE":"Website",
    "CONTENT":"Content",
    "ADD_LAUNCHPADS":"Add Launchpad",
    "CLOSE":"Close",
    "TOKEN_PURCHASE":"Token Purchase",
    "DOWNLOAD_PDF":"Download(PDF)",
    "DOWNLOAD_CSV":"Download(CSV)",
    "DOWNLOAD_XLS":"Download(XLS)",
    "PURCGASE_DATE":"Purchase Date",
    "USER_ID":"User ID",
    "BUY_COIN":"Buy Coin",
    "SELL_COIN":"Sell Coin",
    "PRICE":"Price",
    "OUANTITY":"Quantity",
    "DISCOUNT":"Discount",
    "TOTAL":"Total",
    "LANUCHPAD_MANAGEMENT":"Launchpad Management",
    "CURRENCY_LIST":"Currency List",
    "ARE_YOU_SURE":"Are you sure you want to delete this contract?",
    "ADD_CURRENCY":"Add Currency",
    "CURRENCY_TYPE":"Currency Type",
    "CURRENCY_NAME":"Currency Name",
    "CURRENCY_SYMBOL":"Currency Symbol",
    "DECIMALS":"Decimals",
    "CONTRACT_ADDRESS":"Contract Address",
    "TOKEN_TYPE":"Token Type",
    "MIN_ABI":"Min ABI",
    "WITHDRAWAL_FEE":"Withdrawal Fee",
    "MINIMUM_WITHDRAW":"Minimum Withdrawal",
    "CURRENCY_ICON":"Currency Icon",
    "UPDATE_CURRENCY":"Update Currency ",
    "SPOT_TARDE_PAIRS":"Spot Trade Pairs",
    "TICKER":"Ticker",
    "ADD_PAIR":"Add Pair",
    "TAKER_FEES":"Taker Fees",
    "MARK_UP_PERCENTAGE":"Mark up Percentage",
    "MAKER_REBATE":"Maker Rebate",
    "MARKET_PRICE":"Market Price",
    "MAX_ORDER_QUANTITY":"Max Order Quantity",
    "MIN_ORDER_QUANTITY":"Min Order Quantity",
    "MAX_PRICE":"Max Price(%)",
    "MIN_PRICE":"Min Price(%)",
    "FLOATING_DIGITAL":"Floating Digits",
    "FIRST_CURRENCY":"First Currency",
    "SECOND_CURRENCY":"Second Currency",
    "BINANCE_INTEGRATION":"Binance Integration",
    "ADD_CONTRACT":"Add Contract",
    "UPDATE_PAIR":"Update Pair",
    "TICKER_ROOT":"Ticker Root",
    "UPDATE_CONTRACT":"Update Contract",
    "ADD_PAIR_PAGE":"Add Pair Page",
    "ORDER_HISTORY":"Order History",
    "CONTRACTS":"Contracts",
    "FILLED_REMAINING":"Filled Remaining",
    "EXEC_PRICE":"Exec Price",
    "ORDER_PRICE":"Order Price",
    "BUY_OR_SELL":"Buy or Sell",
    "ORDER_VALUE":"Order Value",
    "TYPE":"Type",
    "DATE":"Date",
    "TRADE_HISTORY":"Trade History",
    "BUY_EMAIL":"Buy Email",
    "SELL_EMAIL":"Buy Email",
    "FILLED_TYPE":"Filled Type",
    "FEE":"Fee",
    "REMAINING":"Remaining",
    "ORDER_TYPE":"Order Type",
    "PERPETUAL_CONTRACTS":"Perpetual Contracts",
    "ADD_CONTRACTS_PAGE":"Add Contracts Page",
    "ADD_PREPETUAL_CONTRACT":"Add Perpetual Contract",
    "INITIAL_MARGIN":"Initial Margin",
    "MAINTANENCE_MARGIN":"Maintanence Margin",
    "INTEREST_BASE_SYMBOL":"Interest Base Symbol",
    "INTEREST_QUOTE_SYMBOL":"Símbolo de interes base",
    "FUNDING_PREMIUN_SYMBOL":"Funding Premium Symbol",
    "FUNDING_INTERVAL":"Funding Interval(in hours)",
    "LEVERAGE":"Leverage",
    "TAKER_FEES":"Taker Fees",
    "MAKER_REBATE":"Maker Rebate",
    "DAY_LONG_FEE":"Day Long Fee",
    "RISK_LIMIT":"Risk Limit",
    "RISK_STEP":"Risk Step",
    "CONTRACT_SIZE":"Contract Size",
    "MARKET_PRICE":"Market price",
    "MINIMUM_PRICE_INCREMENT":"Minimum Price Increment",
    "MAX_PRICE":"Max Price",
    "LOT_SIZE":"Lot Size",
    "ARE_YOU_SURE_DELETE":"Are you sure you want to delete this contract?",
    "UPDATE_PERPETUAL":"Update Perpetual",
    "SHOW_MENU":"Show _MENU_ result per page",
    "SHOWING_START_END":"Showing _START_ to _END_ of _TOTAL_ records",
    "NO_USER_FOUND":"No user found!",
    "NO_USERBALANCE_FOUND":"No Userbalance found!",
    "NO_RECORD_FOUND":"No Records found!",
    "NO_WITHDRAW_FOUND":"No Withdraw found!",
    "NO_CURRENCY_FOUND":"No Currency found!",
    "NO_CONTRACTS_FOUND":"No Contracts found!",
    "PERPETUAL_ORDER_HISTORY":"Perpetual Order History",
    "NO_EMAIL_TEMPLATES_FOUND":"No Email Templates found!",
    "BASE_CURRENCY":"Base Currency",
    "QUOTE_CURRENCY":"Quote Currency",
    "SIDE":"Side",
    "AVERAGE":"Average",
    "EXECUTED":"Executed",
    "AMOUND":"Amount",
    "PERPETUAL_TARDE_HISTORY":"Perpetual Trade History",
    "EXCUTED":"Excuted",
    "FEES":"Fees",
    "TRADER":"Trader",
    "LIQUIDATED_LIST":"Liquidated List",
    "PAIR_NAME":"Pair Name",
    "ENTRY_PRICE":"Entry Price",
    "EXIT_PRICE":"Exit Price",
    "EXIT_TYPE":"Exit Type",
    "PROFIT_LOSS":"Profit/Loss",
    "CREATED_DATE":"Created Date",
    "USER_EMAIL":"User Email",
    "EMAIL_TEMPLATES_LIST":"Email Templates List",
    "SUBJECT":"Subject",
    "INDENTIFIER":"Identifier",
    "UPDATE_TEMPLATE":"Update Template",
    "TEMPLATE_DELETED" : "Template deleted successfully. Refreshing data...",
    "CMS_LIST":"Cms List",
    "PAGE_NAME":"Page Name",
    "CMS_DELETED_SUCCESSFULLY":"CMS deleted successfully. Refreshing data...",
    "UPDATE_CMS":"Update Cms",
    "COMMISSION_HISTORY":"Commission History",
    "TRADE_TYPE":"Trade Type",
    "TOTAL_EARNED":"Total Earned",
    "FAQ_LIST":"FAQ List",
    "QUESTION":"Question",
    "ANSWER":"Answer",
    "ADD_FAQ_PAGE":"Add FAQ Page",
    "ADD_FAQ":"Add Faq",
    "FAQ_DELETED_SUCCESSFULLY":"FAQ deleted successfully. Refreshing data...",
    "ENQUIRES_LIST":"Enquires List",
    "MESSAGE":"Message",
    "REPLY_STATUS":"Reply Status",
    "CONTACT_DELETED_SUCCESSFULLY":"Contact deleted successfully. Refreshing data...",
    "REPLY_TO_CONTACT":"Reply to contact",
    "REPLY":"Reply",
    "SUPPORT_TICKET":"Support Ticket",
    "VIEW_TICKET_DETAILS":"View Ticket Detail",
    "DESCRIPTION":"Description",
    "QUERY_IMAGES":"Query images",
    "DOWNLOAD":"Download",
    "REPLY_MESSAGE":"Reply Message",
    "REPLY_TO_TICKET":"Reply to Ticket",
    "IMAGE":"Image",
    "BLOCK_LIST":"Block List",
    "IP_ADDRESS":"IP Address",
    "ADD_BLOCK_LIST":"Add Block List",
    "Page status Activated successfully":"Page status Activated successfully",
    "Page status DeActivated successfully":"Page status DeActivated successfully",
    "USER_BALANCE":"User Balance",
    "LAUNCHPAD":"Launchpad",
    "TRADE_PAIR":"Trade Pair",
    "SPOT_ORDER_HISTORY":"Spot Order History",
    "SPOT_TRADE_HISTORY":"Spot Trade History",
    "PERPETUAL_CONTRACT":"Perpetual contract",
    "EMAIL_TEMPLATES":"Email Templates",
    "CMS_PAGES":"CMS Pages",
    "CONTACT_US":"Contact Us",
    "NEWSLETTER":"Newsletter",
    "SUPPORT":"Support",
    "PROOF_NUMBER":"Proof Number",
    "FRONT_IMAGE":"Front Image",
    "BACK_IMAGE":"Back Image",
    "SELFI_IMAGE":"Selfi Image",
    "PAN_IMAGE":"Pan Image",
    "REASON":"Reason",
    "APPROVE":"Approve",
    "VIEW":"View",
    "ALL_THE_PROOFS_VERIFIED":"All the Proofs have been verified by manual",
    "RESIDENTIAL_DOCUMENT":"Residential Document ",
    "IMAGE_SIZE_LESS_THAN_20KB":"Image Size should be less than 20 Kb",
    "IMAGE_SIZE_LESS_THAN_1MB":"Image Size should be less than 1 Mb",
    "Currency updated successfully. Refreshing data...":"Currency updated successfully. Refreshing data...",
    "CURRENCY_NAME_REQUIRED":"Currency Name field is required",
    "Currency Symbol field is required":"Currency Symbol field is required",
    "DECIMAL_REQUIRED" : "Decimal field is required",
    "WITHDRAWAL_FEE_REQUIRED":"WithdrawFee field is required",
    "WITHDRAWAL_MINIMUM_REQUIRED":"minimumWithdraw field is required",
    "TAKER_FEES_FIELD_REQUIRED":"Taker fees field is required",
    "TAKER_FEES_REQUIRED":"Taker fees field is required",
    "MARKUPPERCENTAGE_REQUIRED":"Markuppercentage field is required",
    "MARKER_REBATE_REQIURED":"Maker Rebate field is required",
    "MARKER_PRICE_REQIURED":"MarkPrice field is required",
    "MAX_QUANTITY_REQIURED":"Max quantity field is required",
    "MIN_QUANTITY_FIELD_REQIURED":"Min quantity field is required",
    "MAX_PRICE_REQIURED":"Max Price field is required",
    "MIN_PRICE_REQIURED":"Min Price field is required",
    "FLOATING_FILELD_REQIURED":"FloatingDigits field is required",
    "FIRST_CURRENCY_REQIURED":"First currency field is required",
    "SECOND_CURRENCY_REQIURED":"Second currency field is required",
    "Pair updated successfully. Refreshing data...":"Pair updated successfully. Refreshing data...",
    "Pair added successfully. Refreshing data...":"Pair added successfully. Refreshing data...",
    "INITIAL_MARGIN_REQUIRED":"Initial margin field is required",
    "MAINTANCE_MARGIN_REQIURED":"Maintanence margin field is required",
    "INTEREST_BASE_REQUIRED":"Interest Base Symbol field is required",
    "INTEREST_SYMBOL_REQUIRED":"Interest Quote Symbol field is required",
    "FUNDING_PREMIUM_REQUIRED":"Funding premium symbol field is required",
    "FUNDING_REQUIRED":"Funding interval is required",
    "LEVERAGE_REQUIRED":"Leverage field is required",
    "RISK_LIMIT_REQUIRED":"Risk limit field is required",
    "RISK_STEP_REQUIRED":"Risk step field is required",
    "CONTRACT_SIZE_REQUIRED":"Contract size is required",
    "MARKET_PRICE_REQUIRED":"Market price field is required",
    "MIN_PRICE_REQUIRED":"Min price increment field is required",
    "MAX_PRICE_REQUIRED":"Max price field is required",
    "MAX_QUANTITY_REQUIRED":"Max quantity field is required",
    "MIN_QUANTITY_REQUIRED":"Min quantity field is required",
    "LOT_SIZE_REQUIRED":"Lot size field is required",
    "Contract updated successfully. Refreshing data...":"Contract updated successfully. Refreshing data...",
    "This pair is not available in Bybit":"This pair is not available in Bybit",
    "FIRST_CURRENCY_NOT_SAME":"First currency and second currency cannot be same",
    "Templates updated successfully. Refreshing data...":"Templates updated successfully. Refreshing data...",
    "Cms updated successfully. Refreshing data...":"Cms updated successfully. Refreshing data...",
    "ip added successfully":"ip added successfully",
    "ARE_YOU_DELETE_BLOCK_LIST":"Are you sure you want to delete this from Block List?",
    "ip deleted successfully":"ip deleted successfully",
    "NEWSLETTER DETAILS":"Newsletter Details",
    "MESSAGE":"Message",
    "SEND":"Send",
    "SUPPORT_TICKET_LIST" : "Support Ticket List"

    


 



    


    
 
   



 



   



 







    

    


}    