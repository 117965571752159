import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { addperpetual } from "../../actions/perpetualActions";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';
import { Editor } from '@tinymce/tinymce-react';
import axios from "axios";
import keys from "../../actions/config";
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import { t } from 'i18next';
const url = keys.baseUrl;
class PerpetualAddModal extends React.Component {
    constructor() {
        super();
        this.state = {
            email_assigned:false,
            first_currency: "",
            tickerroot             : "",
            initial_margin         : "",
            maint_margin           : "",
            interest_base_symbol   : "",
            interest_quote_symbol  : "",
            funding_premium_symbol : "",
            risk_limit             : "",
            risk_step              :  "",
            minpriceincrement      :  "",
            maxpriceincrement      : "",
            mark_price             : "",
            contract_size          : "",
            maxquantity            : "",
            minquantity            : "",
            lotsize                : "",
            funding_interval       : "",
            second_currency        : "",
            leverage               : "",
            maker_rebate           : "",
            dailyinterest           : "",
            taker_fees             : "",
            errors: {}
        };
       // console.log(this.state,'statessssssssss');
         this.handleChange1 = this.handleChange1.bind(this);
         this.handleChange1 = this.handleChange1.bind(this);
    }

    styles = {
        option: (styles, {isFocused, isSelected}) => ({
            ...styles,
            color: "#fff",
            background: isFocused
                ? '#bdbdbd'
                : isSelected
                    ? '#005eb8'
                    : "#808080",
            zIndex: 1
        }),
        valueContainer: (provided, state) => ({
          ...provided,
          height: '52px',
          padding: '0 6px',
          backgroundColor: "#03081f",
          borderColor: '#81c8f6',
        borderRadius: 10,
        borderStyle: 'solid',
        borderWidth: '1px'
         
        }),
        control: (provided, state) => ({
          ...provided,
          height: '52px',
          borderRadius:10,
          backgroundColor: "#03081f",
          border:'none'
         
        }),
        indicatorsContainer: (provided, state) => ({
          ...provided,
          height: '52px',
          position: 'absolute',
          right: 0,
          top: 0,
          color:'#fff' 
        }),    
        singleValue: (provided, state) => ({
          ...provided,
          color: "#fff"
        }),
        menuList: base => ({
            ...base,
            // kill the white space on first and last option
            padding: 0
          })
      };
 componentDidMount() {
            this.getData()  
        };
    componentWillReceiveProps(nextProps) {
        const {t}=this.props
        if (nextProps.errors) {
            // nextProps.errors={message:t(nextProps.errors.message)}
            console.log("nextProps.errors",t(nextProps.errors.message))
             $("#add-perpetual-modal").find(".text-danger").show();
            this.setState({
                errors: nextProps.errors
            });
        }
       // console.log(nextProps);
        if (nextProps.auth !== undefined
            && nextProps.auth.perpetual !== undefined
            && nextProps.auth.perpetual.data !== undefined
            && nextProps.auth.perpetual.data.message !== undefined) {
            $('#add-perpetual-modal').modal('hide');
            console.log("nextProps.auth.perpetual.data.message",nextProps.auth.perpetual.data.message);
            toast(t(nextProps.auth.perpetual.data.message), {
                
                position: toast.POSITION.TOP_CENTER
            });
            nextProps.auth.perpetual = undefined;
            
        }
    }
  
   getData() {
           axios
           .get(url+"78data-first")
            .then(res => {  
                
               console.log(res.data,'datassss');
                var currencyarray =[]; 
                  res.data.map((item,i)=>{
                    const name = item.currencySymbol;
                    const value = item.currencySymbol;         
                    const obj = {'value':name, 'label':value};
                  currencyarray.push(obj);
                   });
                 // console.log(currencyarray,'currencyarray');
                   this.setState({ first_currency1:currencyarray,email_assigned:true});
             })
            .catch()
      //  console.log(this.props,'authget1');
    }
 
    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };
 handleChange = selectedOption => {
    this.setState({first_currency : selectedOption.value });
  //  console.log(`Option selected:`, selectedOption );
  };
  handleChange1 = selectedOption => {
    this.setState({ second_currency : selectedOption.value });
    //console.log(`Option selected:`, selectedOption );
  };

    onFaqAdd = e => {
        const {t}=this.props
        e.preventDefault();
        if((this.state.first_currency==this.state.second_currency) && (!this.state.first_currency=="" &&! this.state.second_currency==""))
        {
            toast(t("FIRST_CURRENCY_NOT_SAME"), {
                       position: toast.POSITION.TOP_CENTER,
                   })
            return false;
        }
        else{
            e.preventDefault();
            const newcontract = {
                tickerroot             : this.state.first_currency + this.state.second_currency,
                initial_margin         : this.state.initial_margin,
                maint_margin           : this.state.maint_margin,
                interest_base_symbol   : this.state.interest_base_symbol,
                interest_quote_symbol  : this.state.interest_quote_symbol,
                funding_premium_symbol : this.state.funding_premium_symbol,
                risk_limit             : this.state.risk_limit,
                risk_step              : this.state.risk_step,
                minpriceincrement      : this.state.minpriceincrement,
                maxpriceincrement      : this.state.maxpriceincrement,
                mark_price             : this.state.mark_price,
                contract_size          : this.state.contract_size,
                maxquantity            : this.state.maxquantity,
                minquantity            : this.state.minquantity,
                lotsize                : this.state.lotsize,
                funding_interval       : this.state.funding_interval,
                second_currency        : this.state.second_currency,
                first_currency         : this.state.first_currency,
                leverage               : this.state.leverage,
                maker_rebate           : this.state.maker_rebate,
                dailyinterest          : this.state.dailyinterest,
                taker_fees             : this.state.taker_fees,
            };
           // console.log(newcontract);
            this.props.addperpetual(newcontract,t);
        }
    };


    render() {
        const { errors } = this.state;
         const { selectedOption } = this.state.first_currency;
         const { selectedOption1 } = this.state.second_currency;
const {t} = this.props;

    //      const options1 = [
    //   { value: 'USD', label: 'USD' },
    //   { value: 'INR', label: 'INR' },
    // ];
        
        return (
            <div>
                <div className="modal fade" id="add-perpetual-modal" data-reset="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">{t("ADD_PREPETUAL_CONTRACT")}</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate onSubmit={this.onFaqAdd} id="add-perpetual">
                                    <div className="row mt-2">
                                        
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.tickerroot}
                                                id="tickerroot"
                                                type="text"
                                                error={errors.tickerroot}
                                                 className="d-none"/>
                                            
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="initial_margin">{t("INITIAL_MARGIN")}</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.initial_margin}
                                                error={errors.initial_margin}
                                                id="initial_margin"
                                                type="number"
                                                className={classnames("form-control", {
                                                    invalid: errors.initial_margin
                                                })}
                                            />
                                            <span className="text-danger">{errors.initial_margin ? t("INITIAL_MARGIN_REQUIRED"):""}</span>
                                        </div>
                                    </div>

                                     <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="maint_margin">{t("MAINTANENCE_MARGIN")}</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.maint_margin}
                                                error={errors.maint_margin}
                                                id="maint_margin"
                                                type="number"
                                                className={classnames("form-control", {
                                                    invalid: errors.maint_margin
                                                })}
                                            />
                                            <span className="text-danger">{errors.maint_margin? t("MAINTANCE_MARGIN_REQIURED"):"" }</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="interest_base_symbol">{t("INTEREST_BASE_SYMBOL")}</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.interest_base_symbol}
                                                error={errors.interest_base_symbol}
                                                id="interest_base_symbol"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.interest_base_symbol
                                                })}
                                            />
                                            <span className="text-danger">{errors.interest_base_symbol ? t("INTEREST_BASE_REQUIRED"):""}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="interest_quote_symbol">{t("INTEREST_QUOTE_SYMBOL")}</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.interest_quote_symbol}
                                                error={errors.interest_quote_symbol}
                                                id="interest_quote_symbol"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.interest_quote_symbol
                                                })}
                                            />
                                            <span className="text-danger">{errors.interest_quote_symbol ? t("INTEREST_SYMBOL_REQUIRED"):""}</span>
                                        </div>
                                    </div>

                                     <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="funding_premium_symbol">{t("FUNDING_PREMIUN_SYMBOL")}</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.funding_premium_symbol}
                                                error={errors.funding_premium_symbol}
                                                id="funding_premium_symbol"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.funding_premium_symbol
                                                })}
                                            />
                                            <span className="text-danger">{errors.funding_premium_symbol ? t("FUNDING_PREMIUM_REQUIRED"):""}</span>
                                        </div>
                                    </div>

                                     <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="funding_interval">{t("FUNDING_INTERVAL")}</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.funding_interval}
                                                error={errors.funding_interval}
                                                id="funding_interval"
                                                type="number"
                                                className={classnames("form-control", {
                                                    invalid: errors.funding_interval
                                                })}
                                            />
                                            <span className="text-danger">{errors.funding_interval ? t("FUNDING_REQUIRED"):""}</span>
                                        </div>
                                    </div>

                                      <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="leverage">{t("LEVERAGE")}</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.leverage}
                                                error={errors.leverage}
                                                id="leverage"
                                                type="number"
                                                className={classnames("form-control", {
                                                    invalid: errors.leverage
                                                })}
                                            />
                                            <span className="text-danger">{errors.leverage ? t("LEVERAGE_REQUIRED"):""}</span>
                                        </div>
                                    </div>

                                     <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="taker_fees">{t("TAKER_FEES")}</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.taker_fees}
                                                error={errors.taker_fees}
                                                id="taker_fees"
                                                type="number"
                                                className={classnames("form-control", {
                                                    invalid: errors.taker_fees
                                                })}
                                            />
                                            <span className="text-danger">{errors.taker_fees ? t("TAKER_FEES_FIELD_REQUIRED"):""}</span>
                                        </div>
                                    </div>


                                     <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="maker_rebate">{t("MAKER_REBATE")}</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.maker_rebate}
                                                error={errors.maker_rebate}
                                                id="maker_rebate"
                                                type="number"
                                                className={classnames("form-control", {
                                                    invalid: errors.maker_rebate
                                                })}
                                            />
                                            <span className="text-danger">{errors.maker_rebate ? t("MARKER_REBATE_REQIURED"):"" }</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="dailyinterest">{t("DAY_LONG_FEE")}</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.dailyinterest}
                                                error={errors.dailyinterest}
                                                id="dailyinterest"
                                                type="number"
                                                className={classnames("form-control", {
                                                    invalid: errors.dailyinterest
                                                })}
                                            />
                                            <span className="text-danger">{errors.dailyinterest}</span>
                                        </div>
                                    </div>


                                     <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="risk_limit">{t("RISK_LIMIT")}</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.risk_limit}
                                                error={errors.risk_limit}
                                                id="risk_limit"
                                                type="number"
                                                className={classnames("form-control", {
                                                    invalid: errors.risk_limit
                                                })}
                                            />
                                            <span className="text-danger">{errors.risk_limit  ? t("RISK_LIMIT_REQUIRED"):""}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="risk_step">{t("RISK_STEP")}</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.risk_step}
                                                error={errors.risk_step}
                                                id="risk_step"
                                                type="number"
                                                className={classnames("form-control", {
                                                    invalid: errors.risk_step
                                                })}
                                            />
                                            <span className="text-danger">{errors.risk_step ? t("RISK_STEP_REQUIRED"):""}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="contract_size">{t("CONTRACT_SIZE")}</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.contract_size}
                                                error={errors.contract_size}
                                                id="contract_size"
                                                type="number"
                                                className={classnames("form-control", {
                                                    invalid: errors.contract_size
                                                })}
                                            />
                                            <span className="text-danger">{errors.contract_size  ? t("CONTRACT_SIZE_REQUIRED"):""}</span>
                                        </div>
                                    </div>

                                     <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="mark_price">{t("MARKET_PRICE")}</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.mark_price}
                                                error={errors.mark_price}
                                                id="mark_price"
                                                type="number"
                                                className={classnames("form-control", {
                                                    invalid: errors.mark_price
                                                })}
                                            />
                                            <span className="text-danger">{errors.mark_price ? t("MARKET_PRICE_REQUIRED"):""}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="minpriceincrement">{t("MINIMUM_PRICE_INCREMENT")}</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.minpriceincrement}
                                                error={errors.minpriceincrement}
                                                id="minpriceincrement"
                                                type="number"
                                                className={classnames("form-control", {
                                                    invalid: errors.minpriceincrement
                                                })}
                                            />
                                            <span className="text-danger">{errors.minpriceincrement ? t("MIN_PRICE_REQUIRED"):""}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="maxpriceincrement">{t("MAX_PRICE")}</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.maxpriceincrement}
                                                error={errors.maxpriceincrement}
                                                id="maxpriceincrement"
                                                type="number"
                                                className={classnames("form-control", {
                                                    invalid: errors.maxpriceincrement
                                                })}
                                            />
                                            <span className="text-danger">{errors.maxpriceincrement  ? t("MAX_PRICE_REQUIRED"):""}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="maxquantity">{t("MAX_ORDER_QUANTITY")}</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.maxquantity}
                                                error={errors.maxquantity}
                                                id="maxquantity"
                                                type="number"
                                                className={classnames("form-control", {
                                                    invalid: errors.maxquantity
                                                })}
                                            />
                                            <span className="text-danger">{errors.maxquantity  ? t("MAX_QUANTITY_REQUIRED"):""}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="minquantity">{t("MIN_ORDER_QUANTITY")}</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.minquantity}
                                                error={errors.minquantity}
                                                id="minquantity"
                                                type="number"
                                                className={classnames("form-control", {
                                                    invalid: errors.minquantity
                                                })}
                                            />
                                            <span className="text-danger">{errors.minquantity ? t("MIN_QUANTITY_REQUIRED"):""}</span>
                                        </div>
                                    </div>

                                     <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="lotsize">{t("LOT_SIZE")}</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.lotsize}
                                                error={errors.lotsize}
                                                id="lotsize"
                                                type="number"
                                                className={classnames("form-control", {
                                                    invalid: errors.lotsize
                                                })}
                                            />
                                            <span className="text-danger">{errors.lotsize ? t("LOT_SIZE_REQUIRED"):""}</span>
                                        </div>
                                    </div>
                                       
                                     <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="first_currency">{t("FIRST_CURRENCY")}</label>
                                        </div>
                                        <div className="col-md-9">
                                            <Select isSearchable={false}
                                                value={selectedOption}
                                                onChange={this.handleChange}
                                                options={this.state.first_currency1}
                                                styles={this.styles} className="border_blue_select"
                                            />
                                            <span className="text-danger">{errors.first_currency  ? t("FIRST_CURRENCY_REQIURED"):""}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="second_currency">{t("SECOND_CURRENCY")}</label>
                                        </div>
                                        <div className="col-md-9">
                                            <Select isSearchable={false}
                                                value={selectedOption1}
                                                onChange={this.handleChange1}
                                                options={this.state.first_currency1}
                                                styles={this.styles} className="border_blue_select"
                                            />
                                            <span className="text-danger">{errors.second_currency ? t("SECOND_CURRENCY_REQIURED"):""}</span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-outline" data-dismiss="modal">{t("CLOSE")}</button>
                                <button
                                    form="add-perpetual"
                                    type="submit"
                                    className="btn btn-primary">
                                    {t("ADD_CONTRACT")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

PerpetualAddModal.propTypes = {
    addperpetual: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { addperpetual }
)(withRouter(withTranslation()(PerpetualAddModal),(PerpetualAddModal)));
