import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import SubCategoryAddModal from "../partials/SubcategoryAddModal"

// import SubCategoryUpdateModal from "../partials/SubcategoryUpdateModal.jsx";

import SubCategoryUpdateModel from "../partials/SubcategoryUpdateModal";

import { toast, ToastContainer } from "react-toastify";
import $ from 'jquery';
import keys from "../../actions/config";
const url = keys.baseUrl;
class SubCategory extends Component {
    constructor(props) {
        super(props);

        this.columns = [
            {
                key: "categoryName",
                text: "CategoryName",
                className: "categoryname",
                align: "left",
                sortable: true,
                cell: record => {
                    console.log('maincategoryId',record?.categoryName)
                    if (record?.categoryName) {

                        return (record?.categoryName)

                    }
                }

            },
            {
                key: "status",
                text: "Status",
                className: "status",
                align: "left",
                sortable: true,
                cell: record => {
                    console.log('record.status',record.status)
                    return (record.status == "active" || record.status == "Active" ? ('Active') : ('DeActive'))
                }
            },
            {
                key: "action",
                text: "Action",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
                    return (
                        <Fragment>
                            <div className="d-flex"> <button
                                data-toggle="modal"
                                data-target="#update-subcategory-modal"
                                className="btn btn-primary btn-sm mr-2"
                                onClick={() => this.editRecord(record)}
                            >
                                <i className="fa fa-edit"></i>
                            </button>
                                {/* <button
                                    className="btn btn-outline btn-sm"
                                    onClick={() => this.deleteRecord(record)}>
                                    <i className="fa fa-trash"></i>
                                </button> */}
                                </div>
                        </Fragment>
                    );
                }
            }
        ];

        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            filename: "Currency",
            no_data_text: 'No Category found!',
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            records: [],
            editFormModal: true
        };

        this.state = {
            currentRecord: {
                id: "",
                subcategoryName: '',
                status: '',
                categoryName: ''
            },
            errors: {}

        };

        // this.getData = this.getData.bind(this);
        this.handleCloseEditForm = this.handleCloseEditForm.bind(this);
    }

    componentDidMount() {
        // this.getData()
        this.getCategoryList()
    };

    componentWillReceiveProps(nextProps) {
        // $("#add-subcategory-modal").modal("hide");
        // this.getData()
        this.getCategoryList()
    }

    // getData() {
    //     axios
    //         .get(url + "api/sub-category")
    //         .then(res => {
    //             // console.log("Response of subb",res)
    //             this.setState({ records: res.data })
    //         })
    //         .catch()
    // }

    getCategoryList = () => {
        try {
            axios.get(url + "api/supportcategoryList")
                .then(res => {
                    console.log('respond', res?.data?.result)
                    this.setState({records : res?.data?.result})
                })
        }
        catch(err)
        {
            console.log('errorsdetails',err)
        }
        
    }
    editRecord(record) {
        // this.setState({updateModel: true})
        // $("#update-subcategory-modal").find(".text-danger").hide();
        // // $("#add-subcategory-modal").find(".text-danger").hide();
        // console.log('recorddesialtask',record)
        this.setState({ currentRecord: record, errors: {}, editFormModal: true});
    }
    addRecord() {
        $("#add-subcategory-modal").find(".text-danger").hide();
        // this.getData()
    }
    handleCloseEditForm() {
        this.setState({ editFormModal: false })
    }

    deleteRecord(record) {
        axios
        .post(url + "api/supportcategory_delete", { _id: record._id })
        .then(res => {
            if (res.data.status == true) {
                toast(res.data.message, {
                    position: toast.POSITION.TOP_CENTER,
                })
            }
        })
        .catch();
        // this.getData()
        // console.log(record);
        // if (!window.confirm('Are you sure you want to delete this Sub category?')) { return false; }
        // axios
        //     .post(url + "api/subcategory-delete", { _id: record._id })
        //     .then(res => {
        //         if (res.status === 200) {
        //             toast(res.data.message, {
        //                 position: toast.POSITION.TOP_CENTER,
        //             })
        //         }
        //     })
        //     .catch();
        // this.getData();
    }

    pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }

    render() {
        return (
            <div>
                <Navbar />
                <div className="d-flex" id="wrapper">
                    <Sidebar />
                    <SubCategoryAddModal getdata={this.getCategoryList} />
                    {console.log('currentRecords',this.state.currentRecord)}
                    {/* <SubCategoryUpdateModal record={this.state.currentRecord} getdata={this.getCategoryList} onHide={() => { this.setState({ currentRecord: {} }) }} />  */}
                    <SubCategoryUpdateModel 
                    isShow={this.state.editFormModal}
                    onHide={this.handleCloseEditForm}
                    fetchData={this.getCategoryList}
                    record={this.state.currentRecord}
                    />                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <button onClick={() => this.addRecord()} className="btn btn-outline float-right mt-0 mr-2 btn_pos_right" data-toggle="modal" data-target="#add-subcategory-modal"><FontAwesomeIcon icon={faPlus} /> Add Sub Category</button>
                            <div className="clearfix" />
                            <h3 className="mt-2 text-secondary"> Support Category List</h3>
                            <div className="resonsive_table">
                                {console.log("lasjdhkfajksdfjasd",this.state.records)}
                                <ReactDatatable
                                    config={this.config}
                                    records={this.state.records}
                                    columns={this.columns}
                                    onPageChange={this.pageChange.bind(this)}
                                    className="table table-bordered table-striped subcategory_table"
                                />
                            </div>
                        </div>
                    </div>
                    <ToastContainer />
                </div>
            </div>
        );
    }

}

SubCategory.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});

export default connect(
    mapStateToProps
)(SubCategory);
