import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { replysupport } from "../../actions/userActions";
import { withRouter } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { withTranslation, WithTranslation } from 'react-i18next';
import $ from 'jquery';
import axios from "axios";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import { Editor } from '@tinymce/tinymce-react';
import 'react-toastify/dist/ReactToastify.css';
import keys from "../../actions/config";
import { Link } from 'react-router-dom';
import { t } from 'i18next';
import { momentFormat  } from '../../lib/dateTimeHelper';

const url = keys.baseUrl;

class SupportReplyModal extends React.Component {
    constructor(props) {
        super(props);
        console.log(this.props.match.params, 'propssssdfdfd');
        this.state = {
            id: "",
            email_add: "",
            subject: "",
            description: "",
            attachment: "",
            errors: {},
            query_image: "",
            reply: [],
            message_query: "",
            records: {},
            profileurl: "",
            replystatus: false,
            replymessage: "",
            Message: []
        };
    }
    // componentDidMount() {
    //     document.getElementById("support_menu").classList.add("active");

    //     if (window.innerWidth > 991) {
    //         // console.log(document.querySelectorAll(".list-group-item-action.active")[0].offsetTop,"top");
    //         if (document.querySelectorAll(".list-group-item-action.active").length > 0) {
    //             var pagelinkofset = document.querySelectorAll(".list-group-item-action.active")[0].offsetTop;
    //             document.getElementById("scrollbar_sidebar_id").scrollTo(0, pagelinkofset);

    //         }

    //     }
    //     else {
    //         // alert(2)
    //         if (document.querySelectorAll(".list-group-item-action.active").length > 0) {
    //             // console.log(document.querySelectorAll(".list-group-item-action.active")[0].offsetTop,"top");
    //             if (document.querySelectorAll(".list-group-item-action.active").length > 0) {
    //                 var pagelinkofset = document.querySelectorAll(".list-group-item-action.active")[0].offsetTop;
    //                 document.getElementById("scrollbar_sidebar_id").scrollTo(0, pagelinkofset);
    //             }
    //         }

    //     }
    //     this.getData()
    // };

    handleChange = (event) => {
        let imageFormat = /\.(pdf|PDF|docx|DOCX|png|PNG|JPG|jpg)$/;
        let errors = {};
        var MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
        if (event.target.files[0].size > MAX_FILE_SIZE) {
            console.log("first condition")
            errors["query_image"] = "*Image is Above 5MB";
            this.setState({
                errors: errors
            });
        }
        if (!imageFormat.test(event.target.files[0].name)) {
            console.log("second condition")
            errors["query_image"] = "*Image Should Allowed only pdf/docx/jpg/png";
            this.setState({
                errors: errors
            });
        } else {
            console.log("third condition")
            this.setState({
                profileurl: URL.createObjectURL(event.target.files[0]),
                query_image: event.target.files[0]
            })
        }
    }


    getData() {
        const id = this?.props?.match?.params?.id;
        console.log('iddsadata', id)
        axios.get(url + "api/getuserdata/" + id).then(res => {
            if (res.data.status == true) {
                console.log('userdahasgjkfgvjas', res.data.userdata, res.data.userdata.email, res.data.result)
                let supportdata = res.data.result
                let userEmail = res.data.userdata.email;
                console.log('userEmalildjkl asdhfyk', userEmail)
                console.log('supportdata.reply',supportdata,supportdata.reply.length, supportdata.reply.length > 0)
                this.setState({ userdata: supportdata, usermessage: supportdata.message, email: userEmail, Message :supportdata.reply, created_date : supportdata.created_date})
                if( supportdata?.reply?.length > 0)
                {
                    console.log('enter thaso lkdasfasdf')
                     this.setState({replystatus : true})
                }

            }
        })
    }


    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    handleChanges = (e) => {
        // e.preventDefault()
        console.log('e.target.valuesa fkasd', e.target.value)
        this.setState({ [e.target.name]: e.target.value })
    }

    onSubmitQuery = () => {
        const id = this?.props?.match?.params?.id;
        console.log('enter thaslkdfaskd')

        let replymessage = this.state.replymessage;
        let email = this.state.email
        let usermessage = this.state.usermessage
        let data = {
            "replymessage": replymessage,
            "ticketId": id,
            "userEmail": email,
            "Issue" : usermessage

        }

        console.log('dataasdfasdfasdfasdfasd', data,replymessage != "")
        if(replymessage != "")
        {
            axios.post(url + "api/replyadmin", data).then(res => {
                console.log('RES', res)
                if (res.data.status == true) {
                    let message = res.data.result.reply;
                    console.log('resasdfasdfadsdfasdf', res.data.result.reply)
                    console.log('messagedata', message)
    
                    this.setState({ replystatus: true, Message: message })
                }
            })
            this.setState({replymessage : ''})
        }
        
        // this.setState({replystatus : true})


    }


    componentDidMount() {
        this.getData()
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps) {
            this.setState({
                query_image: nextProps.query_image,
                message_query: nextProps.message_query,
            })
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        console.log(nextProps);
        console.log(nextProps, 'xjsadhnxskaucfysducfhsdjkcnhjnpropse');
        if (nextProps.auth !== undefined
            && nextProps.auth.support_reply !== undefined
            && nextProps.auth.support_reply.data !== undefined
            && nextProps.auth.support_reply.data.message !== undefined) {
            toast(nextProps.auth.support_reply.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
            if (nextProps.auth.support_reply.data.success == true)
                toast(nextProps.auth.support_reply.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            nextProps.auth.support_reply = "";
        }
    }

    // onSubmitQuery = e => {
    //     e.preventDefault();
    //     console.log("newReply")
    //     if (!this.state.errors == {} || !this.state.errors == null || !typeof this.state.errors == undefined) {
    //         console.log("error", this.state.errors, typeof this.state.errors, !this.state.errors == {})
    //     }
    //     else {
    //         console.log("sucess")
    //         const newReply = {
    //             _id: this.state.id,
    //             message_query: this.state.message_query,
    //             query_image: this.state.query_image

    //         };
    //         const data = new FormData();
    //         data.append('_id', this.state._id);
    //         data.append('message_query', this.state.message_query);
    //         data.append('file', this.state.query_image);
    //         console.log(newReply, "newReply");
    //         this.props.replysupport(data);
    //     }
    // };

    render() {
        const { t } = this.props
        const { errors } = this.state;
        console.log(this.state.userid, 'this.state');
        return (
            <div>
                <Navbar />
                <div className="d-flex" id="wrapper">
                    <Sidebar />
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <h3 className="mt-2 text-secondary">Support Ticket</h3>
                            {/* <form noValidate onSubmit={this.onSubmitQuery} id="support-reply" className='mt-4'>
                                    <div class="">
                                    <div class="card-header"><b>View Ticket Details</b></div>
                                        <div class="card-body card-block">
                                            <div class="text-secondary">
                                                <div className="row mt-2">
                                                    <div className="col-md-3">
                                                        <label htmlFor="name">Email</label>
                                                    </div>
                                                    {console.log(this.state && this.state.email_add && this.state.email_add,"email")}
                                                    <div className="col-md-6">
                                                        <input
                                                        
                                                            value={this.state && this.state.email_add && this.state.email_add}
                                                            id="email_add"
                                                            type="email"
                                                            error={errors.email_add}
                                                            className={classnames("form-control", {
                                                                invalid: errors.email_add
                                                            })} readOnly/>
                                                        <span className="text-danger">{errors.email_add}</span>
                                                    </div>
                                                </div>

                                                <div className="row mt-2">
                                                    <div className="col-md-3">
                                                        <label htmlFor="name">Subject</label>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <input
                                                        
                                                            value={this.state.subject}
                                                            id="subject"
                                                            type="text"
                                                            error={errors.subject}
                                                            className={classnames("form-control", {
                                                                invalid: errors.subject
                                                            })} readOnly/>
                                                        <span className="text-danger">{errors.subject}</span>
                                                    </div>
                                                </div>

                                                <div className="row mt-2">
                                                    <div className="col-md-3">
                                                        <label htmlFor="name">Description</label>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <input
                                                        
                                                            value={this.state.description}
                                                            id="description"
                                                            type="text"
                                                            error={errors.description}
                                                            className={classnames("form-control", {
                                                                invalid: errors.description
                                                            })} readOnly/>
                                                        <span className="text-danger">{errors.description}</span>
                                                    </div>
                                                </div>

                                                <div className="row mt-2">
                                                    <div className="col-md-3">
                                                    <br/>
                                                    <label htmlFor="attachment">Query images</label>
                                                    </div>
                                                    {this.state.attachment!==null?
                                                <div className="col-md-6 d-flex align-items-end">
                                                    <a href={keys.baseUrl + 'support_images/' + this.state.attachment} target="blank" download>Download</a>
                                                </div>:''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-4">
                                        <div class="card-header"><b>Reply Message</b></div>
                                        <div class="card-body card-block">
                                            {this.state.reply.map((array, i) => {
                                                return ( 
                                                    <section class="card-body text-secondary">{array.replytype}<b> : </b> {array.message_query}
                                                            {array.query_image!==null?
                                                                <div className="col-md-6 px-0">
                                                                    <a href={keys.baseUrl + 'support_images/' + array.query_image} target="blank" download>Download</a>
                                                                </div>:''}
                                                    </section>
                                                )
                                            })}
                                        </div>
                                    </div>   
                                    <div class=" mt-4">
                             <div class="card-header"><b>Reply to Ticket</b></div>
                                    <div class="card-body card-block">
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                        <br/>
                                        <label htmlFor="query_image">{t("IMAGE")}</label>
                                        </div>
                                        <div className="col-md-6">
                                        <label class="custom-file-upload">
                                        <input type="file" onChange={this.handleChange}
                                        />
                                        {t("CHOOSE_FILE")}
                                        </label>
                                        <img width="100px" src={this.state.profileurl} />
                                        <span className="text-danger">{errors.query_image}</span>
                                        </div>
                                    </div>

                                    
                                   <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="message_query">{t("MESSAGE")}</label>
                                        </div>
                                        <div className="col-md-6">
                                             <textarea
                                                onChange={this.onChange}
                                                value={this.state.message_query}
                                                id="message_query"
                                                type="text"
                                                error={errors.message_query}
                                                className={classnames("form-control", {
                                                    invalid: errors.message_query
                                                })}/>
                                            <span className="text-danger">{errors.message_query}</span>
                                        </div>
                                    </div>
                                  
                                    </div>
                                    </div>
                                </form>
                                    <br />
                        <button form="support-reply"
                                    type="submit"
                                    className="btn btn-primary mt-3">
                                    Reply </button> */}

                            <div className="cust-chat">
                                <div className='chats'>
                                    <div className='d-flex gap-2'>
                                        <div>
                                            <img className='img' src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?cs=srgb&dl=pexels-pixabay-220453.jpg&fm=jpg" />
                                        </div>
                                        <div className='msg'>
                                            <div>
                                                <div>{this.state.usermessage}</div>
                                            </div>
                                            <p className='text-muted f-12'>{momentFormat(this.state.created_date)}</p>
                                        </div>
                                    </div>


                                    {this.state.Message && this.state.Message.length > 0 && this.state.Message.map((item ,key) =>{return ( <div className='d-flex flex-row-reverse gap-2'>
                                      <div>
                                            <img className='img' src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?cs=srgb&dl=pexels-pixabay-220453.jpg&fm=jpg" />
                                        </div>
                                        <div className='reply'>
                                           

                                            
                                               <div>
                                                    <div> {item?.replytype}</div> <br></br>
                                                </div>
                                            
                                                      {console.log('item.replydatae',item.replydate)}
                                            <p className='text-muted f-12'>{momentFormat(item?.replydate)}</p><br></br>
                                        </div>
                                    </div>) }) }



                                </div>

                                <div className='writetxt'>
                                    <textarea type='text' className='form-control' name='replymessage' onChange={this.handleChanges}></textarea>
                                    <button className='fa fa-paper-plane' onClick={this.onSubmitQuery}></button>
                                </div>
                            </div>
                            
                            
                        </div>
                        
                        </div>
                    </div>
                    <ToastContainer />
                </div>
            // </div>
        )
    }
}

SupportReplyModal.propTypes = {
    replysupport: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { replysupport }
)(withRouter(withTranslation()(SupportReplyModal), (SupportReplyModal)));