import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import UserAddModal from "../partials/UserAddModal";
import UserUpdateModal from "../partials/UserUpdateModal";
import { toast, ToastContainer } from "react-toastify";
import { withTranslation } from 'react-i18next';
import keys from "../../actions/config";
import $ from 'jquery';
import KycModal from "../partials/KycModal";
import KycResidentModal from "../partials/KycResidentModal";
import { t } from "i18next";




const url = keys.baseUrl;
class Users extends Component {

    constructor(props) {
        super(props);
        const { t } = this.props;
        this.columns = [
            {
                key: "email",
                text: t("CURRENCY"),
                className: "currencyName",
                align: "left",
                sortable: true,
            },
            {
                key: "status",
                text: t("IDENTITY_DOCUMENT"),
                className: "status",
                align: "left",
                sortable: true,
                cell: record => {
                    return (
                        <Fragment>
                            <div className="d-flex"> <button
                                type="button"
                                data-toggle="modal"
                                data-target="#kycidentity-modal"
                                className="btn btn-primary btn-sm mr-2"
                                onClick={() => this.editRecord(record)}
                            >
                                <i className="fa fa-eye"></i>
                            </button></div>
                        </Fragment>
                    );
                }
            },
            {
                key: "action",
                text: t("RESIDENTIAL_DOCUMENT"),
                className: "action",
                align: "left",
                sortable: false,
                cell: record => {
                    return (
                        <Fragment>
                            <button
                                data-toggle="modal"
                                data-target="#kycresident-modal"
                                className="btn btn-primary btn-sm mr-2"
                                onClick={() => this.editRecord(record)}
                            >
                                <i className="fa fa-eye"></i>
                            </button>
                        </Fragment>
                    );
                }
            }
        ];

        // this.columns = [
        //     // {
        //     //     key: "name",
        //     //     text: "Name",
        //     //     className: "name",
        //     //     align: "left",
        //     //     sortable: true,
        //     // },
        //     {
        //         key: "email",
        //         text: "Email",
        //         className: "email",
        //         align: "left",
        //         sortable: true
        //     },
        //     {
        //         key: "verifiedstatus",
        //         text: "KYC Status",
        //         className: "action",
        //         align: "left",
        //         sortable: true
        //     },
        //     {
        //         key: "action",
        //         text: "Action",
        //         className: "action",
        //         width: 100,
        //         align: "left",
        //         sortable: false,
        //         cell: record => {
        //             if(record.verifiedstatus.toLowerCase() == "pending" || record.verifiedstatus.toLowerCase()=='cancelled'){//CANCELLED
        //                 return (
        //                     <Fragment>
        //                         <button
        //                             // data-toggle="modal"
        //                             // data-target="#update-user-modal"
        //                             className="btn btn-primary btn-sm mr-2"
        //                             onClick={() => this.editRecord(record)}
        //                             >
        //                             <i className="fa fa-check"></i>
        //                             {/* <i className="fa fa-edit"></i> */}
        //                         </button>

        //                          {/* <button
        //                            className="btn btn-primary btn-sm"
        //                             onClick={() => this.changestatus(record)}>
        //                             <i className={lockVal}></i>
        //                         </button> */}

        //                         {/* <button
        //                             className="btn btn-outline btn-sm mr-2"
        //                             onClick={() => this.deleteRecord(record)}>
        //                             <i className="fa fa-trash"></i>
        //                         </button> */}
        //                     </Fragment>
        //                 );
        //             }else if(record.verifiedstatus.toLowerCase() == "finished") {//FINISHED
        //                 // <i className=></i>
        //                 return(
        //                     <Fragment>
        //                         <button
        //                             // data-toggle="modal"
        //                             // data-target="#update-user-modal"
        //                             className="btn btn-primary btn-sm mr-2"
        //                             >
        //                             <i className="fa fa-lock"></i>
        //                             </button>
        //                         {/* <i className="fa fa-lock"></i> */}
        //                     </Fragment>
        //                 )

        //             }
        //             // return (
        //             //     <Fragment>
        //             //         <button
        //             //             // data-toggle="modal"
        //             //             // data-target="#update-user-modal"
        //             //             className="btn btn-primary btn-sm mr-2"
        //             //             onClick={() => this.editRecord(record)}
        //             //             >
        //             //             <i className="fa fa-edit"></i>
        //             //         </button>

        //             //          {/* <button
        //             //            className="btn btn-primary btn-sm"
        //             //             onClick={() => this.changestatus(record)}>
        //             //             <i className={lockVal}></i>
        //             //         </button> */}

        //             //         {/* <button
        //             //             className="btn btn-outline btn-sm mr-2"
        //             //             onClick={() => this.deleteRecord(record)}>
        //             //             <i className="fa fa-trash"></i>
        //             //         </button> */}
        //             //     </Fragment>
        //             // );
        //         }
        //     }
        // ];

        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            filename: "Users",
            no_data_text: 'No user found!',
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: t("FILTER IN RECORDS"),
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: t("FIRST"),
                    previous: t("PREVIOUS"),
                    next: t("NEXT"),
                    last: t("LAST")
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            currentRecord: {
                records: [],
                id: '',
                name: '',
                email: '',
                active: '',
                status: '',
                password: '',
                password2: '',
                lockVal: '',
            },
            userDetails : {},
            click : false
        };

        this.getData = this.getData.bind(this);
    }
    // editRecord(record) {
    //     console.log('enter this also')
    //     $("#kycmodal").find(".text-danger").hide();
    //     $("#kycresidentmodal").find(".text-danger").hide();
    // }
    componentDidMount() {
        this.getData()
        console.log(this.state,'statezzzzzz');
    };

    componentWillReceiveProps(nextProps) {
        this.getData()
    }

    getData() {
        axios
            .post(url + "api/user-data")
            .then(res => {
                console.log('userddatasgal',res.data)
                let userdata = res.data;
                var arr1 = [];

                {userdata && userdata.length > 0 && userdata.map((item) =>
                    {
                        if(item.verifiedstatus == "Pending" || item.Addresstatus == "Pending")
                        {
                            arr1.push(item)
                        }
                        
                    })}

                    console.log('arr1',arr1)

                    this.setState({ records: arr1 })
            })
            .catch()
    }
    // onAcceptID = async (id, email) => {
    //     const idaccept = {
    //         _id: id,
    //         email: email,
    //     };

    //     axios
    //         .post(url + "api/IDverifiedaccept", idaccept)
    //         .then((res) => {
    //             if (res.status === 200) {
    //                 console.log("res.data.updateddata.IDstatus",res.data.message);
    //                 this.setState({ IDstatus: res.data.updateddata.IDstatus });
    //                 toast(t("ALL_THE_PROOFS_VERIFIED"), {
    //                     position: toast.POSITION.TOP_CENTER,
    //                 });
    //                 this.getData();
    //             }
    //         })
    //         .catch();
    // };
    // onAcceptAddress = async(id,email) => {

    //    const addressaccept = {
    //      _id: id,
    //      email: email,
    //    };
    //    axios
    //      .post(url + "api/addressaccept", addressaccept)
    //      .then((res) => {
    //        if (res.status === 200) {
    //          this.setState({ Addresstatus: res.data.updateddata.Addresstatus });

    //          toast(res.data.message, {
    //            position: toast.POSITION.TOP_CENTER,
    //          });
    //          this.getData();
    //        }
    //      })
    //      .catch();
    //  };
    // onAcceptPhoto = async(id,email) => {

    //     const photoaccept = {
    //       _id: id,
    //       email: email,
    //     };
    //     axios
    //       .post(url + "api/photoaccept", photoaccept)
    //       .then((res) => {
    //         if (res.status === 200) {
    //           this.setState({ Photostatus: res.data.updateddata.Photostatus });

    //           toast(res.data.message, {
    //             position: toast.POSITION.TOP_CENTER,
    //           });
    //           this.getData();
    //         }
    //       })
    //       .catch();
    //  };
    async editRecord(record) {
        console.log('recordsdata',record?.email)
        $('#kycidentity-modal').find(".text-danger").show();

        this.setState({userDetails : record,click : true})
        // $("#update-user-modal").find(".text-danger").hide();
        
        // this.setState({ currentRecord: record});
        //this.props.history.push("/kycupdate/" + record._id);
        // await this.onAcceptID(record._id, record.email)
        //    await this.onAcceptAddress(record._id,record.email)
        //    await this.onAcceptPhoto(record._id,record.email)
        // console.log("recode edit",this.state.currentRecord)

    }
    deleteRecord(record) {
        axios
            .post(url + "api/user-delete", { _id: record._id })
            .then(res => {
                if (res.status === 200) {
                    toast(res.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            })
            .catch();
        this.getData();
    }

    changestatus(record) {
        axios
            .post(url + "api/user-changestatus", { _id: record._id })
            .then(res => {
                // console.log(res,'reschange');
                if (res.status === 200) {
                    toast(res.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            })
            .catch();
        this.getData();
    }
    pageChange(pageData) {
        // console.log("OnPageChange", pageData);
    }

    render() {
        const { t } = this.props;

        return (
            <div>
                <Navbar />
                <div className="d-flex" id="wrapper">
                    <Sidebar />
                    {console.log('userdatesdetials',this.state.click,this.state.userDetails)}
                  {this?.state?.click && <KycModal userdata={this.state.userDetails} refresh={()=>this.getData()}/>}
                  {/* <KycModal userdata={this.state.userDetails}/>  */}
                   {this.state.click && < KycResidentModal userdata={this.state.userDetails} refresh={()=>this.getData()}/>}
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            {/* <button className="btn btn-outline float-right mt-0 mr-2 btn_pos_right" data-toggle="modal" data-target="#add-user-modal"><FontAwesomeIcon icon={faPlus}/> add</button> */}
                            <h3 className="mt-2 text-secondary">{t("KYC_VERFICATION")}</h3>
                            <div className="resonsive_table">
                                <ReactDatatable
                                    config={this.config}
                                    records={this.state.records}
                                    columns={this.columns}
                                    responsive={true}
                                    onPageChange={this.pageChange.bind(this)}
                                />
                            </div>
                        </div>
                    </div>
                    <ToastContainer />
                </div>
            </div>
        );
    }

}

Users.propTypes = {
    auth: PropTypes?.object?.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});

export default connect(
    mapStateToProps
)(withTranslation()(Users), Users);
