import React, { Component } from "react";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSignOutAlt} from "@fortawesome/free-solid-svg-icons";
import {connect} from "react-redux";
import {logoutUser} from "../../actions/authActions";
import { Link } from "react-router-dom";
import keys from "../../actions/config";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import {faStream} from "@fortawesome/free-solid-svg-icons/faStream";
import { withTranslation } from 'react-i18next';
import { Lan } from "../i18next/i18n";


import adminLogo from "../../images/magnituteLogo.png"
import adminLogoblack from "../../images/Bell_Exchange_logo.png"
import Landing from "../layout/NotFound";
// import Logo from "../../Logo-small.png"
const url = keys.baseUrl;
class Navbar extends Component {
    constructor(props){
        super(props);
        this.state = {
          theme:true,
         
        };
      
    
      }
    onLogoutClick = e => {
        e.preventDefault();
        this.props.logoutUser();
    };

    render() {
        const themechange = () =>{
            if(document.getElementById("root").classList.contains("lighttheme")){
              document.getElementById("root").classList.add("darktheme")
              document.getElementById("root").classList.remove("lighttheme");
              document.getElementById("modaltheme").classList.add("darkmodal")
              document.getElementById("modaltheme").classList.remove("lightmodal");
              this.setState({theme:true})
              // this.state.theme === false 
            }else{
              document.getElementById("root").classList.remove("darktheme")
              document.getElementById("root").classList.add("lighttheme")
              document.getElementById("modaltheme").classList.add("lightmodal")
              document.getElementById("modaltheme").classList.remove("darkmodal");
              // this.state.theme === true
              this.setState({theme:false})
      
            }
           
          }
        const { user } = this.props.auth;
const {t} = this.props;


        console.log(this.props);
        return (
            <div className="container-fluid p-0">

                <nav className="navbar navbar-expand-lg navbar-dark bg-dark adminNav">

                    
                    <a className="navbar-brand" href="/dashboard" > 
                    {/* Bell Exchange */}
                        <img src={adminLogo} className="img-fluid darkthemeimg" alt=""  width={50}/>
                        <img src={adminLogoblack} className="img-fluid lightthemeimg" alt="" width={50}  />
                    </a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse"
                            data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false"
                            aria-label="Toggle navigation">
                        <FontAwesomeIcon icon={faStream}/>
                    </button>

{/* <ul className="navbar-nav navbar_mobile_menu">
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" id="settings"
                                   data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Settings
                                    {user?.name}
                                </a>
                                <div className="dropdown-menu settings_head_menu" aria-labelledby="settings" id='nav_links'>
                                <Link to="/profile">
                                       Profile
                                     </Link>
                                    <Link to="/changepassword">
                                       Change Password
                                     </Link>
                                     <Link to="/settings">
                                       Settings
                                     </Link>
                                     <Link to="/loginhistory">
                                     Login History
                                     </Link>
                                     <a className="nav-link" href="/login"  onClick={this.onLogoutClick}>Logout ({user.name}) <FontAwesomeIcon icon={faSignOutAlt} /> </a>

                                

                                </div>
                            </li>
                           
                        </ul> */}

                    <div className="collapse navbar-collapse" id="navbarNavDropdown">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" id="settings"
                                   data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {t("SETTINGS")}
                                </a>
                                <div className="dropdown-menu" aria-labelledby="settings">
                                     <Link to="/profile">
                                    {t("PROFILE")}
                                     </Link>
                                    <Link to="/changepassword">
                                     {t("CHANGE PASSWORD")}
                                      
                                     </Link>
                                     <Link to="/settings">
                                     {t("SETTINGS")}
                                     </Link>



                                </div>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" id="settings"
                                   data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                   {/* {t("LANGUAGE")} */}
                                   {localStorage.getItem('Translation') == 'en' || localStorage.getItem('lang') == '' ? 'ENGLISH' : 'español'}

                                </a>
                                <div className="dropdown-menu" aria-labelledby="settings">
                                     <Link onClick={()=>Lan('en')}>
                                      English
                                     </Link>
                                    <Link onClick={()=>Lan('sp')}>
                                    español
                                     </Link>
                                   



                                </div>
                            </li>
                            <li className="nav-item active">
                                <a className="nav-link" href="#" onClick={this.onLogoutClick}> {t("LOGOUT")} ({user.name}) <FontAwesomeIcon icon={faSignOutAlt} /> </a>
                            </li>
                            <li>
                            <button className='transbtn theme' onClick={() => themechange()}><span className={this.state.theme ? 'fa fa-sun' : 'fa fa-moon'} ></span></button>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        );
    }
}

Navbar.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(
    mapStateToProps,
    { logoutUser }
)(withTranslation()(Navbar),(Navbar));
