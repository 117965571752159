import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addsubcategory } from "../../actions/category";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import $ from "jquery";
import { Editor } from "@tinymce/tinymce-react";
import Select from "react-select";
import axios from "axios";
import keys from "../../actions/config";
import "react-toastify/dist/ReactToastify.css";

const url = keys.baseUrl;

class SubcategoryAddModal extends React.Component {
  constructor() {
    super();
    this.state = {
      subcategoryName: "",
      status: "",
      errors: {},
      categoryName1: "",
      categoryName: "",
      supportcategoryName: ""

    };
  }

  styles = {
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      color: "#fff",
      background: isFocused
        ? '#bdbdbd'
        : isSelected
          ? '#005eb8'
          : "#808080",
      zIndex: 1
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '52px',
      padding: '0 6px',
      backgroundColor: "#03081f",
      borderColor: '#81c8f6',
      borderRadius: 10,
      borderStyle: 'solid',
      borderWidth: '1px'

    }),
    control: (provided, state) => ({
      ...provided,
      height: '52px',
      borderRadius: 10,
      backgroundColor: "#03081f",
      border: 'none'

    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '52px',
      position: 'absolute',
      right: 0,
      top: 0,
      color: '#fff'
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#fff"
    }),
    menuList: base => ({
      ...base,
      // kill the white space on first and last option
      padding: 0
    })
  };
  componentDidMount() {
    this.getData();
  }

  getData() {
    axios
      .get(url + "api/helpcentrecategory")
      .then(category => {
        console.log("Response data====>", category)
        var categoryarray = [];
        category.data.map((item, i) => {
          const name = item.categoryName;
          const value = item._id;
          const obj = { value: value, label: name };
          categoryarray.push(obj);
        });
        // console.log(currencyarray,'currencyarray');
        this.setState({ categoryName1: categoryarray, email_assigned: true });
      })
  }


  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      $("#add-subcategory-modal")
        .find(".text-danger")
        .show();
      this.setState({
        errors: nextProps.errors
      });
    } else {
      this.setState({
        errors: ""
      });
    }
    //console.log(nextProps.auth.currencyadd.data,'shdbjksfcgdsyfvfdsuy');
    if (
      nextProps.auth !== undefined &&
      nextProps.auth.currencyadd !== undefined &&
      nextProps.auth.currencyadd.data !== undefined &&
      nextProps.auth.currencyadd.data.message !== undefined
    ) {
      $("#add-subcategory-modal").modal("hide");
      this.setState({
        errors: ""
      });
      toast(nextProps.auth.currencyadd.data.message, {
        position: toast.POSITION.TOP_CENTER
      });
      nextProps.auth.currencyadd = undefined;
      this.setState({
        errors: ""
      });
    }
  }

  handleChange = selectedOption => {
    this.setState({ categoryName: selectedOption.value });
    console.log(`Option selected:`, selectedOption.value);
  };
  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onsupportCategoryAdd = e => {
    e.preventDefault();
    try {
      let reqData = {
        "categoryName": this.state.supportcategoryName
      }
      axios.post(url + "api/supportcategory_add", reqData).then((res) => {
        console.log('res.data', res.data.message)
        if (res.data.status == false) {
          toast(res.data.message, {
            position: toast.POSITION.TOP_CENTER,
          })
        }
        else {
          toast(res.data.message, {
            position: toast.POSITION.TOP_CENTER,
          })
          $("#add-subcategory-modal").modal("hide");
          this.props.getdata()
        }
      })
    }
    catch (err) {
      console.log("onsupportCategoryAdd", err);
    }
  };

  render() {
    const { selectedOption } = this.state.categoryName1;
    console.log("selectedOption", selectedOption)
    const { errors } = this.state;
    return (
      <div>
        <div
          className="modal fade"
          id="add-subcategory-modal"
          data-reset="true"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Add Category</h4>
                <button type="button" className="close" data-dismiss="modal" onClick={() => { this.setState({ categoryName: "" }) }}>
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <form
                  noValidate
                  onSubmit={this.onsupportCategoryAdd}
                  id="add-currency"
                >
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="currencyName">Category Name</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.onChange}
                        value={this.state.supportcategoryName}
                        id="supportcategoryName"
                        type="text"
                      // error={errors.currencyName}
                      // className={classnames("form-control", {
                      //   invalid: errors.currencyName
                      // })}
                      />
                      {/* <span style={{textAlign:"end",color:"#dc3545"}}>{errors && errors.subcategoryName}</span> */}
                    </div>
                  </div>
                  {/* <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="first_currency">Main Category</label>
                    </div>
                    <div className="col-md-9">
                      {console.log("maincategory",selectedOption,this.state.maincategoryId,this.state.categoryName1,this.state.categoryName)}
                      <Select isSearchable={false}
                        value={selectedOption}
                        defaultValue={{
                          label: this.state.categoryName,
                          value: this.state.categoryName
                        }}
                        onChange={this.handleChange}
                        options={this.state.categoryName1}
                        styles={this.styles} className="border_blue_select"
                      />
                      <span className="text-danger">
                        {errors.first_currency}
                      </span>
                    </div>
                  </div> */}
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  form="add-currency"
                  type="submit"
                  className="btn btn-primary"
                >
                  Add Category
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SubcategoryAddModal.propTypes = {
  addsubcategory: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});


// export default connect(mapStateToProps)(SubcategoryAddModal);

export default SubcategoryAddModal
