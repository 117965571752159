import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import { withTranslation, WithTranslation } from "react-i18next";
import axios from "axios";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import CurrencyAddModal from "../partials/CurrencyAddModal";
import CurrencyUpdateModal from "../partials/CurrencyUpdateModal";
import { toast, ToastContainer} from "react-toastify";
import $ from 'jquery';
import keys from "../../actions/config";
const url = keys.baseUrl;
class Faq extends Component {
    constructor(props) {
        super(props);
 const {t} =this.props
        this.columns = [
            {
                key: "currencyName",
                text: t("CURRENCY"),
                className: "currencyName",
                align: "left",
                sortable: true,
                width:200
            },
            {
                key: "status",
                text: t("STATUS"),
                className: "status",
                align: "left",
                sortable: true
            },
            {
                key: "action",
                text: t("ACTION"),
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
                    return (
                        <Fragment>
                           <div className="d-flex"> <button
                                data-toggle="modal"
                                data-target="#update-currency-modal"
                                className="btn btn-primary btn-sm mr-2"
                                onClick={() => this.editRecord(record)}
                                >
                                <i className="fa fa-edit"></i>
                            </button>
                            <button
                                className="btn btn-outline btn-sm"
                                onClick={() => this.deleteRecord(record)}>
                                <i className="fa fa-trash"></i>
                            </button></div>
                        </Fragment>
                    );
                }
            }
        ];

        this.config = {
            page_size: 10,
            length_menu: [ 10, 20, 50 ],
            filename: "Currency",
            no_data_text: t('NO_CURRENCY_FOUND'),
            language: {
                length_menu: t("SHOW_MENU"),
                filter: t("FILTER IN RECORDS"),
                info: t("SHOWING_START_END"),
                pagination: {
                    first: t("FIRST"),
                    previous: t("PREVIOUS"),
                    next: t("NEXT"),
                    last: t("LAST")
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            records: []
        };

        this.state = {
            currentRecord: {
                id:"",
                currencyName: '',
                currencySymbol: '',
                status: '',
            },
            errors : {}
            
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.getData()
    };

    componentWillReceiveProps(nextProps) {
        this.getData()
    }

    getData() {
        axios
            .post(url+"api/currency")
            .then(res => {
                this.setState({ records: res.data})
            })
            .catch()
    }

    editRecord(record) {
         $("#update-currency-modal").find(".text-danger").hide();
          $("#add-currency-modal").find(".text-danger").hide();
        this.setState({ currentRecord: record,errors:{}});
    }
  
     addRecord() {
        $("#add-currency-modal").find(".text-danger").hide();
    }


    deleteRecord(record) {
        const {t}=this.props
      console.log(record);
       if(!window.confirm(t('ARE_YOU_SURE'))){ return false; }
        axios
            .post(url+"api/currency-delete", {_id: record._id})
            .then(res => {
                if (res.status === 200) {
                    console.log("res.data.message---->",res.data.message);
                   toast(res.data.message, {
                       position: toast.POSITION.TOP_CENTER,
                   })
                   this.getData();
                }
            })
            .catch();
        this.getData();
    }

    pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }

    render() {
        const {t}=this.props
        return (
            <div>
                <Navbar/>
                <div className="d-flex" id="wrapper">
                    <Sidebar/>
                    <CurrencyAddModal/>
                    <CurrencyUpdateModal record={this.state.currentRecord}/>
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <button onClick={() => this.addRecord()} className="btn btn-outline float-right mt-0 mr-2 btn_pos_right" data-toggle="modal" data-target="#add-currency-modal"><FontAwesomeIcon icon={faPlus}/>{t("ADD_CURRENCY")}</button>
                            <div className="clearfix" />
                            <h3 className="mt-2 text-secondary">{t("CURRENCY_LIST")}</h3>
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                onPageChange={this.pageChange.bind(this)}
                                className="table table-bordered table-striped currency_dt_table"
                            />
                        </div>
                    </div>
                    <ToastContainer/>
                </div>
            </div>
        );
    }

}

Faq.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});

export default connect(
    mapStateToProps
)(withTranslation()(Faq),(Faq));
