import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginUser } from "../../actions/authActions";
import classnames from "classnames";
import keys from "../../actions/config";
import authLogo from "../../images/magnituteLogo.png";
import { withTranslation } from 'react-i18next';



class Login extends Component {
    constructor() {
        super();
        this.state = {
            email: "",
            password: "",
            errors: {},
            showPassword:false,
        };
    }

    componentDidMount() {
        if (this.props.auth.isAuthenticated) {
            this.props.history.push("/dashboard");
        }
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.auth.isAuthenticated) {
            this.props.history.push("/dashboard");
        }

        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onSubmit = e => {
        e.preventDefault();
        const userData = {
            email: this.state.email,
            password: this.state.password
        };
        console.log("UserData----------",userData)
        this.props.loginUser(userData);
    };

    render() {
        const { errors, showPassword } = this.state;
const {t} = this.props;

        return (
            <div className="container loginsec">
                <div className="flex_center">
                <div className="row my-5">
                    <div className="col-md-12 mx-auto">
                    <div className="card">
                        <div className="card-body p-4">
                       <center className="pt-3">
                          {/* <h2 className="form_header">Bell Exchange</h2> */}
                           <img src={authLogo} className="img-fluid img_form" alt="Logo" />
                           </center>
                            <h2 className="text-center text-primary mt-3">{t("LOGIN")}</h2>
                            <form noValidate onSubmit={this.onSubmit} className="white formcur_default">
                                <label htmlFor="email">{t("EMAIL")}</label>
                                <input
                                    onChange={this.onChange}
                                    value={this.state.email}
                                    error={errors.email}
                                    id="email"
                                    type="email"
                                    className={classnames("form-control", {
                                        invalid: errors.email
                                    })}
                                />
                                <span className="text-danger">{errors.email}</span>
                                <br/>
                                <label htmlFor="password">{t("PASSWORD")}</label>
                                <div class="input-group input_grp_width no_warp_flex_input wrapunset">
                                <input
                                    onChange={this.onChange}
                                    value={this.state.password}
                                    error={errors.password}
                                    id="password"
                                    type={showPassword ? "text":"password"}
                                    className={classnames("form-control", {
                                        invalid: errors.password
                                    })}
                                />
                                 <div className="input-group-append">
                                                      <Link
                            className="input-group-text no_underline" id="basic-addon2"
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState({ showPassword: !showPassword });
                            }}
                            >
                               <i className={showPassword && showPassword ?"fa fa-eye cur_pointer_icon":"fa fa-eye-slash cur_pointer_icon"}></i>
                            </Link>
                          </div>
                                </div>
                                <span className="text-danger">{errors.password}</span>
                                <p className="text-center pb-0 mt-2">
                                    <button
                                        type="submit"
                                        className="btn btn-large btn-primary mt-3 px-5">
                                            {t("LOGIN")}   </button>
                                </p>
                                <p className="grey-text text-darken-1 font_15 mt-4">
                                    {t("DON'T HAVE ACCOUNT")} <Link to="/forgot" className="link_blue_new">{t("FORGOT PASSWORD?")}</Link>
                                </p>


                            </form>
                        </div>
                    </div>
                    </div>
                    </div>

                </div>
            </div>
        );
    }
}

Login.propTypes = {
    loginUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});
export default connect(
    mapStateToProps,
    { loginUser }
)(withTranslation()(Login),(Login));
