import moment from 'moment';
import isEmpty from '../lib/isEmpty'
export const momentFormat = (dateTime,format='YYYY-MM-DD HH:mm') => {
    console.log('dateTime',dateTime)
    try{
            console.log('fdas fasdvbgyudfg')
            let newDateTime = new Date(dateTime);
            console.log('moment(newDateTime).utc().format(format)',moment(newDateTime).utc().format(format))
            return moment(newDateTime).utc().format(format)
        
        
    } catch(err){
        return ''
    }
}