import React from 'react'
import 'react-toastify/dist/ReactToastify.css';
import { withTranslation } from 'react-i18next';
import keys from "../../actions/config"
import axios from 'axios';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import $ from 'jquery';
// const keys  = require("../../actions/config")

// import keys from '../../../../backend/config/keys';
const url = keys.baseUrl;
class KycModal extends React.Component {
  constructor(props) {
    super(props);
    console.log('jduhfjdsghjfh', props)
    this.state = {
      name: "",
      email: "",
      password: "",
      password2: "",
      errors: {},
      showPassword: false,
      userRecord: {},
      type: "",
      Proofno: "",
    };

  }
  // handleChange()
  // {
  //   this.setState({[e.target.value] : e.target.value})
  // }


  componentWillReceiveProps(nextProps) {
    let userdata = nextProps.userdata;
    console.log('nextpropsdata', nextProps)
    this.setState({ userRecord: userdata })
    this.setState({ type: userdata?.IDtype, Proofno: userdata?.IDproofno, FrontImage: userdata?.IDprooffront, BackImage: userdata?.IDproofback, SelfieImage: userdata?.Selfieproof, PancardImage: userdata?.Pancardproof, useremail: userdata?.email })
  }

  ApproveorReject(data) {
    console.log('daasdfasdfasdfsadfta', data)
    let updatedata = {
      "Approveorreject": data,
      "useremail": this.state.useremail,
      "Reason": this.state.reason
    }
    console.log('this.state.reason', this.state.reason, this.state.reason != "undefined", this.state.reason != undefined)
    axios.post(url + "api/update_identitydocuments", updatedata).then(res => {

      if (res.data.status == true) {
        console.log('resasdfasdf', res)
        toast("Identity Document update successfully!", {
          position: toast.POSITION.TOP_CENTER
        });
        this.props.refresh()
        $("#kycidentity-modal").modal("hide");
      }
    })
  }


  render() {
    const { t } = this.props;
    const { type } = this.state;

    return (
      <div>
        <div className="modal fade" id="kycidentity-modal" data-reset="true">
          {/* {console.log('typesgal', type, this.state)} */}
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">{t("IDENTITY_DOCUMENT")}</h4>
                <button type="button" className="close" data-dismiss="modal">&times;</button>
              </div>
              <div className="modal-body">
                <div>
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="currencyName">{t("TYPE")}</label>
                    </div>
                    <div className="col-md-9">
                      <input type="text" name="type" value={this.state.type} className="form-control" />
                      <span className="text-danger"></span>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="currencyName">{t("PROOF_NUMBER")}</label>
                    </div>
                    <div className="col-md-9">
                      <input type="text" name="Proofno" value={this.state.Proofno} className="form-control" />
                      <span className="text-danger"></span>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="currencyName">{t("FRONT_IMAGE")}</label>
                    </div>
                    <div className="col-md-9">
                      {console.log('FrontImagedata', this.state.FrontImage)}
                      {/* <a href={`http://localhost:3528/kyc/${this.state.FrontImage}`} target='_blank' className='btn btn-link pl-0'><span className='fa fa-image'></span> {t("VIEW")}</a> */}
                      <a href={`${keys.imageUrl}kyc/${this.state.FrontImage}`} target='_blank' className='btn btn-link pl-0'><span className='fa fa-image'></span>{t("VIEW")}</a>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="currencyName">{t("BACK_IMAGE")}</label>
                    </div>
                    <div className="col-md-9">
                      <a href={`${keys.imageUrl}kyc/${this.state.BackImage}`} target='_blank' className='btn btn-link pl-0'><span className='fa fa-image'></span> {t("VIEW")}</a>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="currencyName">{t("SELFI_IMAGE")}</label>
                    </div>
                    <div className="col-md-9">
                      <a href={`${keys.imageUrl}kyc/${this.state.SelfieImage}`} target='_blank' className='btn btn-link pl-0'><span className='fa fa-image'></span> {t("VIEW")}</a>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="currencyName">{t("PAN_IMAGE")}</label>
                    </div>
                    <div className="col-md-9">
                      <a href={`${keys.imageUrl}kyc/${this.state.PancardImage}`} target='_blank' className='btn btn-link pl-0'><span className='fa fa-image'></span> {t("VIEW")}</a>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="currencyName">{t("REASON")}</label>
                    </div>
                    <div className="col-md-9">
                      <input type="text" name='reason' className="form-control" onChange={(e) => { console.log("reason", e.target.value, e.target.name); this.setState({ [e.target.name]: e.target.value }) }} />
                      {/* name='reason' onChange={this.handleChange} */}
                      <span className="text-danger"></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-outline" data-dismiss="modal" onClick={() => { this.ApproveorReject('Reject') }}>{t("REJECT")}</button>
                <button onClick={() => { this.ApproveorReject('Approve') }}
                  form="add-user"
                  type="submit"
                  className="btn btn-primary">
                  {t("APPROVE")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}



export default (withTranslation()(KycModal));