import React, { Component } from "react";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSignOutAlt} from "@fortawesome/free-solid-svg-icons";
import {connect} from "react-redux";
import {logoutUser} from "../../actions/authActions";
import { withTranslation, WithTranslation } from "react-i18next";
import {Link, NavLink} from "react-router-dom";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import { Scrollbars } from 'react-custom-scrollbars';
import { Alert } from "bootstrap";

class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show:(window.innerWidth>768)?true:false
        }
    }
    onLogoutClick = e => {
        e.preventDefault();
        this.props.logoutUser();
    };

    componentDidMount() {

        if(window.innerWidth>991)
        {
            // console.log(document.querySelectorAll(".list-group-item-action.active")[0].offsetTop,"top");
            if(document.querySelectorAll(".list-group-item-action.active").length>0)
            {           
                 var pagelinkofset = document.querySelectorAll(".list-group-item-action.active")[0].offsetTop;
            document.getElementById("scrollbar_sidebar_id").scrollTo(0,pagelinkofset);

            }

        }
        else
        {
            // alert(2)
            if(document.querySelectorAll(".list-group-item-action.active").length>0)
            {
                // console.log(document.querySelectorAll(".list-group-item-action.active")[0].offsetTop,"top");
                if(document.querySelectorAll(".list-group-item-action.active").length>0)
                {  
                var pagelinkofset = document.querySelectorAll(".list-group-item-action.active")[0].offsetTop;
                document.getElementById("scrollbar_sidebar_id").scrollTo(0,pagelinkofset);
                }
            }
            
        }

       
        window.addEventListener("resize", this.changestate);
     
       
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.changestate);
    }

    changestate= e => {
        this.setState({show:(this.state.show)?false:true});
        document.getElementsByTagName('body')[0].classList.toggle("shrink_whole");
        // console.log(document.querySelectorAll(".list-group-item-action.active")[0].offsetTop,"top");
        // var pagelinkofset = document.querySelectorAll(".list-group-item-action.active")[0].offsetTop;
        // document.getElementById("scrollbar_sidebar_id").scrollTo(0,pagelinkofset);
    }

    render() {
        const {t}=this.props
        const { user } = this.props.auth.user;
        console.log(this.props.auth.user,'fsdlfsdlkfjsldkjflskdjflsdf');
        return (
        <div>
            <button className="btn" onClick={this.changestate} id="menu-toggle"><FontAwesomeIcon icon={faList}/></button>
            { this.state.show?
            <div className="border-right sidebar_whole" >
                <div className="list-group list-group-flush">
                    <div className="scrollbar_sidebar" id="scrollbar_sidebar_id">
                    {/* <Scrollbars style={{ width: 250, height:'calc(100vh - 65px)' }} renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}> */}
                        {
                        (this.props.auth.user.moderator=='1')?
                        <div>
                            <NavLink to="/users" className="list-group-item list-group-item-action">Users</NavLink>
                            <NavLink to="/chat" className="list-group-item list-group-item-action">Chat</NavLink>
                        </div> :
                        <div>
                            <NavLink to="/dashboard" className="list-group-item list-group-item-action">{t("DASHBOARD")}</NavLink>
                            <NavLink to="/users" className="list-group-item list-group-item-action">{t("USERS")}</NavLink>
                            <NavLink to="/Userbalance" className="list-group-item list-group-item-action">{t("USER_BALANCE")}</NavLink>
                            <NavLink to="/kyc" className="list-group-item list-group-item-action">KYC</NavLink>
                            {/* <NavLink to="/bonus" className="list-group-item list-group-item-action">Bonus</NavLink> */}
                            <NavLink to="/deposit" className="list-group-item list-group-item-action">{t("DEPOSITE_LIST")}</NavLink>
                            <NavLink to="/withdraw" className="list-group-item list-group-item-action">{t("WITHDRAW_LIST")}</NavLink>
                            {/*<Link to="/staking" className="list-group-item list-group-item-action">Staking</Link>*/}
                            <NavLink to="/launchpad" className="list-group-item list-group-item-action">{t("LAUNCHPAD")}</NavLink>
                            <NavLink to="/tokenpurchase" className="list-group-item list-group-item-action">{t("TOKEN_PURCHASE")}</NavLink>
                            
                            {/*<Link to="/feesettings" className="list-group-item list-group-item-action">Fee Settings</Link>*/}

                            <NavLink to="/currency" className="list-group-item list-group-item-action">{t("CURRENCY")}</NavLink>
                             

                            <NavLink to="/Pairmanagement" className="list-group-item list-group-item-action">{t("TRADE_PAIR")}</NavLink>
                            {/*<Link to="/Tradingbot" className="list-group-item list-group-item-action">Trading bot</Link>*/}
                            <NavLink to="/orderhistory" className="list-group-item list-group-item-action">{t("SPOT_ORDER_HISTORY")}</NavLink>
                            <NavLink to="/tradehistory" className="list-group-item list-group-item-action">{t("SPOT_TRADE_HISTORY")}</NavLink>

                            <NavLink to="/perpetual" className="list-group-item list-group-item-action">{t("PERPETUAL_CONTRACT")}</NavLink>
                            <NavLink to="/perpetual-Order-History" className="list-group-item list-group-item-action">{t("PERPETUAL_ORDER_HISTORY")}</NavLink>
                            <NavLink to="/perpetual-Trade-History" className="list-group-item list-group-item-action">{t("PERPETUAL_TARDE_HISTORY")}</NavLink>
                            <NavLink to="/liquidated" className="list-group-item list-group-item-action">{t("LIQUIDATED_LIST")}</NavLink>

                            {/* <NavLink to="/copytrade/settings" className="list-group-item list-group-item-action">Copy Trade : Settings</NavLink> */}
                            {/* <NavLink to="/copytrade/applications" className="list-group-item list-group-item-action">Copy Trade : Applications</NavLink> */}
                            {/* <Link to="/closedpositions" className="list-group-item list-group-item-action">Closed positions</Link> */}

                            {/* <Link to="/chat" className="list-group-item list-group-item-action">Chat</Link> */}
                            <NavLink to="/emailtemplates" className="list-group-item list-group-item-action">{t("EMAIL_TEMPLATES")}</NavLink>
                            <NavLink to="/cms" className="list-group-item list-group-item-action">{t("CMS_PAGES")}</NavLink>
                            <NavLink to="/commissionhistory" className="list-group-item list-group-item-action">{t("COMMISSION_HISTORY")}</NavLink>
                            <NavLink to="/faq" className="list-group-item list-group-item-action">FAQ</NavLink>
                            <NavLink to="/contactus" className="list-group-item list-group-item-action">{t("CONTACT_US")}</NavLink>
                            <NavLink to="/newsletter" className="list-group-item list-group-item-action">{t("NEWSLETTER")}</NavLink>
                            <NavLink to="/support" className="list-group-item list-group-item-action" id="support_menu">{t("SUPPORT")}</NavLink>

                            {/* <NavLink to="/category" className="list-group-item list-group-item-action">Blog Category</NavLink> */}
                            <NavLink to="/subcategory" className="list-group-item list-group-item-action">Support Category</NavLink>
                            <NavLink to="/blocklist" className="list-group-item list-group-item-action">{t("BLOCK_LIST")}</NavLink>
                            {/* <NavLink to="/HelpCentreCategory" className="list-group-item list-group-item-action">Help Centre Category</NavLink> */}
                           
                            {/* <NavLink to="/HelpCentreArticle" className="list-group-item list-group-item-action">Help Center Article</NavLink> */}

                            {/* <NavLink to="/HelpCentreArticle" className="list-group-item list-group-item-action">Blog</NavLink> */}
                        </div>
                        }
                        </div>
                    {/* </Scrollbars> */}
                </div>
            </div>:'' }
        </div>
        );
    }
}

Sidebar.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(
    mapStateToProps,
    { logoutUser }
)(withTranslation()(Sidebar),(Sidebar));
