import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.css';
import keys from "../../actions/config"
import axios from 'axios'
import { toast } from "react-toastify";
import $ from 'jquery';


const url = keys.baseUrl
class KycResidentModal extends React.Component {
  constructor() {
    super();
    this.state = {
      name: "",
      email: "",
      password: "",
      password2: "",
      errors: {},
      showPassword: false,
      Addresstype: "",
    };
  }


  componentWillReceiveProps(nextProps) {
    console.log('nextprops?.record', nextProps)
    let userdata = nextProps?.userdata
    console.log('userdata', userdata?.Addressfile, userdata?.Addresstype)
    this.setState({ Addresstype: userdata?.Addresstype, AddressFile: userdata?.Addressfile, useremail: userdata?.email })
  }


  ApproveorReject(data) {
    let userdata = {
      "ApproveorReject": data,
      "email": this?.state?.useremail,
      "reason": this.state.reason
    };
    axios.post(url + "api/update_residentialdocuments", userdata).then(res => {
      if (res.data.status == true) {
        toast("Resident Document update successfully!", {
          position: toast.POSITION.TOP_CENTER
        });
        this.props.refresh()
        $("#kycresident-modal").modal("hide")
      }
    })


  }
  render() {
    const { t } = this.props
    return (
      <div>
        <div className="modal fade" id="kycresident-modal" data-reset="true">
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">{t("RESIDENTIAL_DOCUMENT")} </h4>
                <button type="button" className="close" data-dismiss="modal">&times;</button>
              </div>
              <div className="modal-body">
                <div>
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="currencyName">{t("TYPE")}</label>
                    </div>
                    <div className="col-md-9">
                      {console.log('aSDDRWEAFRLKSTHASD8', this.state.Addresstype)}
                      <input type="text" value={this?.state?.Addresstype} className="form-control" />
                      <span className="text-danger"></span>
                    </div>
                  </div>


                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="currencyName">{t("FRONT_IMAGE")}</label>
                    </div>
                    <div className="col-md-9">
                      {console.log('datasstate', this.state)}
                      <a href={`${keys.imageUrl}kyc/${this.state.AddressFile}`} target='_blank' className='btn btn-link pl-0'><span className='fa fa-image'></span> {t("VIEW")}</a>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="currencyName">{t("REASON")}</label>
                    </div>
                    <div className="col-md-9">
                      <input type="text" className="form-control" name='reason' onChange={(e) => { console.log('e.target', e.target.name, e.target.value); this.setState({ [e.target.name]: e.target.value }) }} />
                      <span className="text-danger"></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button onClick={() => { this.ApproveorReject("Reject") }} type="button" className="btn btn-outline" data-dismiss="modal">{t("REJECT")}</button>
                <button onClick={() => { this.ApproveorReject("Approve") }}
                  form="add-user"
                  type="submit"
                  className="btn btn-primary">
                  {t("APPROVE")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}



export default (withTranslation()(KycResidentModal));