export default {
    "DASHBOARD" : "Panel",
    "USERS":"Usuarios",
    "WITH SUPPORTING":"Con texto de apoyo a continuación como introducción natural a contenido adicional.",
    "GO_TO_USER":"Ir a usuarias",
    "ENQUIRES":"En cuadernillos",
    "GO_TO_CONTACT":"Ir a Contacto",
    "FORGOT PASSWORD":"Has olvidado tu contraseña",
    "EMAIL":"Correo electrónico",
    "FORGOT":"Olvidó",
    "BACKTOLOGIN":"¿Atrás para iniciar sesión?",
    "LOGIN":" de inicio de sesión",
    "PASSWORD":"Contraseña",
    "DON'T HAVE ACCOUNT":"¿No tienes una cuenta?",
    "FORGOT PASSWORD?":"¿Has olvidado tu contraseña?",
    "SETTINGS":"Ajustes",
    "PROFILE":"perfil",
    "CHANGE PASSWORD":"Cambiar la contraseña",
    "LANGUAGE":"Idioma",
    "LOGOUT":"Cerrar sesión",
    "NAME":"Nombre",
    "PHONE_NUMBER":"Número de teléfono",
    "UPDATE_PROFILE":"Actualización del perfil",
    "EDIT_PROFILE":"Editar perfil",
    "SEND_OTP":"Enviar OTP",
    "OTP":"OTP",
    "OLD_PASSWORD":"Contraseña anterior",
    "NEW_PASSWORD":"Nueva contraseña",
    "CONFROM_PASSWORD":"confirmar Contraseña",
    "UPDATE_PASSWORD":"Actualiza contraseña",
    "CONTACT_PERSION":"*Persona de contacto",
    "SITE_NAME":"*Nombre del sitio",
    "SITE_DESCRIPTION":"*Descripción del lugar",
    "ADDRESS":"*DIRECCIÓN",
    "REGISTER_CODE":"Código de registro",
    "MOBILE_NUMBER":"Número de teléfono móvil",
    "COMPANY INFROMATION LINK":"Enlace de información de la empresa",
    "WALLET LICENSE INFROMATION LINK ":"enlace de información de licencia de billetera",
    "TWITTER_URL":"URL de Twitter",
    "MEDIUM URL":"URL mediana",
    "TELEGRAM_LINK":"Enlace de telegrama",
    "FACEBOOK_URL":"Facebook URL",
    "LINKEDIN_URL":"URL de Linkedin",
    "COPYRIGHTS_TEXT":"Texto de derechos de autor",
    "FORCED_LIQUIDATION":"liquidación forzosa",
    "SITE_LOGO":"Logotipo del sitio",
    "CHOOSE_FILE":"Elija el archivo",
    "UPDATE_SETTINGS":"Ajustes de actualización",
    "ENABLE":"permitir",
    "DISABLE":"Desactivar",
    "USER_MANAGEMENT":"Gestión de usuarios",
    "CREATE_DATE":"Fecha de creación",
    "STATUS":"Estado",
    "ACTION":"Acción",
    "DISABLED":"Desactivado",
    "FILTER IN RECORDS":"Filtrar en registros...",
    "FIRST":"Primero",
    "PREVIOUS":"Previo",
    "NEXT":"Siguiente",
    "LAST":"Último",
    "USER_BALANCE":"Lista de saldo de usuario",
    "USER_NAME":"Lista de saldo de usuario",
    "CURRENCY":"Divisa",
    "SPOT_WALLET":"Monedero al contado",
    "DERIVATIVE_WALLET":"Cartera de derivados",
    "KYC_VERFICATION":"Verificación KYC",
    "IDENTITY_DOCUMENT":"Documento de identidad",
    "RESIDENTIAL_DOCUMENT":"Documento Residencial",
    "DEPOSITE_LIST":"Lista de depósitos",
    "TO_ADDRESS":"Dirigirse",
    "TRANSFER_AMOUNT":"Monto de la transferencia",
    "CREATED_DATE":"Fecha de creación",
    "COIN":"Moneda",
    "USER_MAIL":"Correo electrónico del usuario",
    "TRANSACTION_ID":"ID de transacción",
    "ACTIVE":"activo",
    "DEACTIVE":"Desactivado",
    "PAGE_STATUS":"Estado de la página",
    "SUCCESSFULLY":"exitosamente",
    "ACTIVATED":"Activado",
    "DEACTIVATED":"Desactivado",
    "DELETE_USER":"Borrar usuario",
    "ARE_YOU_DELETE":"¿Está seguro de que desea eliminar el usuario?",
    "CANCEL":"Cancelar",
    "CONFORM":"Confirmar",
    "WITHDRAW_LIST":"Retirar lista",
    "TAG_ID":"ID de etiqueta/Memo",
    "REJECT":"Rechazar",
    "THERE_IS_NO":"No hay ID de transacción",
    "START_DATE":"Fecha de inicio",
    "END_DATE":"Fecha final",
    "TOKEN_NAME":"Nombre de token",
    "INDUSTRY":"Industria",
    "WEB_SITE":"Sitio web",
    "FA_FA_LOCK":"fa fa-bloqueo",
    "FA_FA_UNLOCK":"fa fa-desbloquear",
    "ADD":"agregar",
    "ADD_LAUNCHPAD":"Agregar detalles de la plataforma de lanzamiento",
    "TOKEN_SYMBOL":"símbolo de ficha",
    "AVAILABLE_CURRENCY":"Moneda disponible",
    "TOKEN_ICON":"ICONO de ficha",
    "CHOOSE_FILE":"Elegir archivo",
    "TOKEN_LAUNCH_PRICE":"Precio de lanzamiento del token",
    "MINIMUM_PURCHASE":"Importe mínimo de compra",
    "DISCOUNT":"Descuento",
    "TOKEN_AVAILABLE_TO_SALE":"Token disponible para la venta",
    "TOKEN_MAX_SUPPLY":"Suministro máximo de fichas",
    "INDUSTRY":"Industria",
    "WEBSITE":"Sitio web",
    "CONTENT":"Contenido",
    "ADD_LAUNCHPADS":"Añadir plataforma de lanzamiento",
    "CLOSE":"Cerrar",
    "TOKEN_PURCHASE":"Compra de fichas",
    "DOWNLOAD_PDF":"Descargar PDF)",
    "DOWNLOAD_CSV":"Descargar (CSV)",
    "DOWNLOAD_XLS":"Descargar (XLS)",
    "PURCGASE_DATE":"Fecha de compra",
    "USER_ID":"Identificación de usuario",
    "BUY_COIN":"Comprar moneda",
    "SELL_COIN":"vender moneda",
    "PRICE":"Precio",
    "OUANTITY":"Cantidad",
    "DISCOUNT":"Descuento",
    "TOTAL":"Total",
    "LANUCHPAD_MANAGEMENT":"Gestión de plataforma de lanzamiento",
    "CURRENCY_LIST":"Lista de divisas",
    "ARE_YOU_SURE":"¿Está seguro de que desea eliminar este contrato?",
    "ADD_CURRENCY":"Agregar moneda",
    "CURRENCY_TYPE":"Tipo de cambio monetario",
    "CURRENCY_NAME":"Nombre de moneda",
    "CURRENCY_SYMBOL":"Símbolo de moneda",
    "DECIMALS":"decimales",
    "CONTRACT_ADDRESS":"Dirección del contrato",
    "TOKEN_TYPE":"Tipo de token",
    "MIN_ABI":"ITB mínimo",
    "WITHDRAWAL_FEE":"Cargo por retiro",
    "MINIMUM_WITHDRAW":"Retiro Mínimo",
    "CURRENCY_ICON":"Icono de moneda",
    "UPDATE_CURRENCY":"Actualizar moneda",
    "SPOT_TARDE_PAIRS":"Pares comerciales al contado",
    "TICKER":"Corazón",
    "ADD_PAIR":"Añadir par",
    "TAKER_FEES":"Comisiones del beneficiario",
    "MARK_UP_PERCENTAGE":"Porcentaje de marcado",
    "MAKER_REBATE":"Descuento del creador",
    "MARKET_PRICE":"Precio de mercado",
    "MAX_ORDER_QUANTITY":"Cantidad máxima de pedido",
    "MIN_ORDER_QUANTITY":"Cantidad minima para ordenar",
    "MAX_PRICE":"Precio máximo (%)",
    "MIN_PRICE":"Precio mínimo (%)",
    "FLOATING_DIGITAL":"Dígitos flotantes",
    "FIRST_CURRENCY":"Primera moneda",
    "SECOND_CURRENCY":"Segunda divisa",
    "BINANCE_INTEGRATION":"Integración Binance",
    "ADD_CONTRACT":"Agregar contrato",
    "UPDATE_PAIR":"Actualizar par",
    "TICKER_ROOT":"Símbolo raiz",
    "UPDATE_CONTRACT":"Actualizar contrato",
    "ADD_PAIR_PAGE":"Agregar página de par",
    "ORDER_HISTORY":"Historial de pedidos",
    "CONTRACTS":"Contratos",
    "FILLED_REMAINING":"Lleno restante",
    "EXEC_PRICE":"Precio ejecutivo",
    "ORDER_PRICE":"Precio del pedido",
    "BUY_OR_SELL":"comprar o vender",
    "ORDER_VALUE":"Valor del pedido",
    "TYPE":"Tipo",
    "DATE":"Fecha",
    "TRADE_HISTORY":"Historial de negociación",
    "BUY_EMAIL":"Comprar correo electrónico",
    "SELL_EMAIL":"Comprar correo electrónico",
    "FILLED_TYPE":"Tipo de relleno",
    "FEE":"Tarifa",
    "REMAINING":"Restante",
    "ORDER_TYPE":"tipo de pedido",
    "PERPETUAL_CONTRACTS":"Contratos Perpetuos",
    "ADD_CONTRACTS_PAGE":"Agregar página de contratos",
    "ADD_PREPETUAL_CONTRACT":"Agregar contrato perpetuo",
    "INITIAL_MARGIN":"Margen inicial",
    "MAINTANENCE_MARGIN":"Margen de mantenimiento",
    "INTEREST_BASE_SYMBOL":"Símbolo de interes base",
    "INTEREST_QUOTE_SYMBOL":"Símboo de interés de la cotización",
    "FUNDING_PREMIUN_SYMBOL":"Prima de financiación premium",
    "FUNDING_INTERVAL":"Intervalo de financiación (en horas)",
    "LEVERAGE":"Apalancamiento",
    "TAKER_FEES":"Comisiones del beneficiario",
    "MAKER_REBATE":"Descuento del creador",
    "DAY_LONG_FEE":"Comisión por el día",
    "RISK_LIMIT":"Límite de riesgo",
    "RISK_STEP":"Escalón de riesgo",
    "CONTRACT_SIZE":"Tamaño del contrato",
    "MARKET_PRICE ":"Precio de mercado",
    "MINIMUM_PRICE_INCREMENT":"Incremento mínimo del precio",
    "MAX_PRICE":"Precio máximo",
    "LOT_SIZE":"Tamaño del lote",
    "ARE_YOU_SURE_DELETE":"¿Está seguro de que desea eliminar este contrato?",
    "UPDATE_PERPETUAL":"Actualizar perpetuos",
    "SHOW_MENU":"Mostrar _MENU_ resultado por página",
    "SHOWING_START_END":"Mostrando _START_ a _END_ de _TOTAL_ registros",
    "NO_USER_FOUND":"¡Usuario no encontrado!",
    "NO_USERBALANCE_FOUND":"¡No se ha encontrado ningún saldo de usuario!",
    "NO_RECORD_FOUND":"¡No se encontraron registros!",
    "NO_WITHDRAW_FOUND":"¡No se ha encontrado ninguna retirada!",
    "NO_CURRENCY_FOUND":"¡No se ha encontrado ninguna moneda!",
    "NO_CONTRACTS_FOUND":"¡No se encontraron contratos!",
    "PERPETUAL_ORDER_HISTORY":"Historial perpetuo de pedidos",
    "NO_EMAIL_TEMPLATES_FOUND":"¡No se encontraron plantillas de correo electrónico!",
    "BASE_CURRENCY":"Moneda base",
    "QUOTE_CURRENCY":"Divisa de cotización",
    "SIDE":"Lado",
    "AVERAGE":"Media",
    "EXECUTED":"Ejecutado",
    "AMOUND":"Importe",
    "PERPETUAL_TARDE_HISTORY":"Historia del operaciones perpetuo",
    "EXCUTED":"Ejecutado",
    "FEES":"Comisiones",
    "TRADER":"Trader",
    "LIQUIDATED_LIST":"Lista Liquidada",
    "PAIR_NAME":"Nombre del par",
    "ENTRY_PRICE":"Precio de entrada",
    "EXIT_PRICE":"Precio de salida",
    "EXIT_TYPE":"Tipo de salida",
    "PROFIT_LOSS":"Ganancia/Pérdida",
    "CREATED_DATE":"fecha de creación",
    "USER_EMAIL":"Correo electrónico del usuario",
    "EMAIL_TEMPLATES_LIST":"Lista de plantillas de correo electrónico",
    "SUBJECT":"Asunto",
    "INDENTIFIER":"Identificador",
    "UPDATE_TEMPLATE":"Actualizar plantilla",
    "TEMPLATE_DELETED" : "Plantilla eliminada con éxito. Actualizando datos...",
    "CMS_LIST":"Lista del Cms",
    "PAGE_NAME":"Nombre de la página",
    "CMS_DELETED_SUCCESSFULLY":"CMS eliminado con éxito. Actualizando datos...",
    "UPDATE_CMS":"Actualizar el CMS",
    "COMMISSION_HISTORY":"Historia de la Comisión",
    "TRADE_TYPE":"Tipo de comercio",
    "TOTAL_EARNED":"Total ganado",
    "FAQ_LIST":"Lista de preguntas frecuentes",
    "QUESTION":"Pregunta",
    "ANSWER":"Respuesta",
    "ADD_FAQ_PAGE":"APágina de preguntas frecuentes",
    "ADD_FAQ":"Agregar preguntas frecuentes",
    "FAQ_DELETED_SUCCESSFULLY":"Preguntas frecuentes eliminadas con éxito. Actualizando datos...",
    "ENQUIRES_LIST":"Lista de Consultas",
    "MESSAGE":"Mensaje",
    "REPLY_STATUS":"Estado de respuesta",
    "CONTACT_DELETED_SUCCESSFULLY":"Contacto eliminado con éxito. Actualizando datos...",
    "REPLY_TO_CONTACT":"Responder al contacto",
    "REPLY":"Responder",
    "SUPPORT_TICKET":"Ticket de soporte",
    "VIEW_TICKET_DETAILS":"Ver detalle del boleto",
    "DESCRIPTION":"Descripción",
    "QUERY_IMAGES":"Consultar imágenes",
    "DOWNLOAD":"Descargar",
    "REPLY_MESSAGE":"Mensaje de respuesta",
    "REPLY_TO_TICKET":"Responder al boleto",
    "IMAGE":"Imagen",
    "BLOCK_LIST":"Lista de bloqueos",
    "IP_ADDRESS":"Dirección IP",
    "ADD_BLOCK_LIST":"Agregar lista de bloqueo",
    "Page status Activated successfully":"Estado de la página Activado con éxito",
    "Page status DeActivated successfully":"Estado de la página Desactivado con éxito",
    "USER_BALANCE":"Saldo del usuario",
    "LAUNCHPAD":"Plataforma de lanzamiento",
    "TRADE_PAIR":"Intercambiar par",
    "SPOT_ORDER_HISTORY":"Historial de pedidos al contado",
    "SPOT_TRADE_HISTORY":"Historial comercial al contado",
    "PERPETUAL_CONTRACT":"contrato perpetuo",
    "EMAIL_TEMPLATES":"Plantillas de correo electrónico",
    "CMS_PAGES":"Páginas de CMS",
    "CONTACT_US":"Contacte con nosotros",
    "NEWSLETTER":"Boletin informativo",
    "SUPPORT":"Apoyo",
    "PROOF_NUMBER":"Número de prueba",
    "FRONT_IMAGE":"Imagen frontal",
    "BACK_IMAGE":"Imagen trasera",
    "SELFI_IMAGE":"Imagen de Selfi",
    "PAN_IMAGE":"Imagen panorámica",
    "REASON":"Razón",
    "APPROVE":"Aprobar",
    "VIEW":"Vista",
    "ALL_THE_PROOFS_VERIFIED":"Todas las pruebas han sido verificadas por manual.",
    "RESIDENTIAL_DOCUMENT":"Documento Residencial",
    "IMAGE_SIZE_LESS_THAN_20KB":"El tamaño de la imagen debe ser inferior a 20 Kb",
    "IMAGE_SIZE_LESS_THAN_1MB":"El tamaño de la imagen debe ser inferior a 1 Mb",
    "Currency updated successfully. Refreshing data...":"Moneda actualizada con éxito. Actualizando datos...",
    "CURRENCY_NAME_REQUIRED":"El campo Nombre de moneda es obligatorio",
    "CURRENCY_SYMBOL_REQUIRED":"El campo Símbolo de moneda es obligatorio",
    "DECIMAL_REQUIRED" : "El campo decimal es obligatorio",
    "WITHDRAWAL_FEE_REQUIRED":"El campo Retirar tarifa es obligatorio",
    "WITHDRAWAL_MINIMUM_REQUIRED":"Se requiere el campo de retiro mínimo",
    "TAKER_FEES_FIELD_REQUIRED":"El campo de tarifas del tomador es obligatorio",
    "TAKER_FEES_REQUIRED":"El campo de tarifas del tomador es obligatorio",
    "MARKUPPERCENTAGE_REQUIRED":"El campo de porcentaje de margen es obligatorio",
    "MARKER_REBATE_REQIURED":"El campo Reembolso del fabricante es obligatorio",
    "MARKER_PRICE_REQIURED":"El campo MarkPrice es obligatorio",
    "MAX_QUANTITY_REQIURED":"El campo de cantidad máxima es obligatorio",
    "MIN_QUANTITY_FIELD_REQIURED":"El campo de cantidad mínima es obligatorio",
    "MAX_PRICE_REQIURED":"El campo Precio máximo es obligatorio",
    "MIN_PRICE_REQIURED":"El campo de precio mínimo es obligatorio",
    "FLOATING_FILELD_REQIURED":"El campo de dígitos flotantes es obligatorio",
    "FIRST_CURRENCY_REQIURED":"El primer campo de moneda es obligatorio",
    "SECOND_CURRENCY_REQIURED":"El segundo campo de moneda es obligatorio",
    "Pair updated successfully. Refreshing data...":"Par actualizado con éxito. Actualizando datos...",
    "Pair added successfully. Refreshing data...":"Par agregado con éxito. Actualizando datos...",
    "INITIAL_MARGIN_REQUIRED":"El campo de margen inicial es obligatorio",
    "MAINTANCE_MARGIN_REQIURED":"El campo de margen de mantenimiento es obligatorio",
    "INTEREST_BASE_REQUIRED":"El campo Símbolo base de interés es obligatorio",
    "INTEREST_SYMBOL_REQUIRED":"El campo Símbolo de cotización de interés es obligatorio",
    "FUNDING_PREMIUM_REQUIRED":"El campo de símbolo de prima de financiación es obligatorio",
    "FUNDING_REQUIRED":"Se requiere el intervalo de financiación",
    "LEVERAGE_REQUIRED":"El campo de apalancamiento es obligatorio",
    "RISK_LIMIT_REQUIRED":"El campo de límite de riesgo es obligatorio",
    "RISK_STEP_REQUIRED":"El campo de paso de riesgo es obligatorio",
    "CONTRACT_SIZE_REQUIRED":"Se requiere el tamaño del contrato",
    "MARKET_PRICE_REQUIRED":"El campo de precio de mercado es obligatorio",
    "MIN_PRICE_REQUIRED":"El campo de incremento de precio mínimo es obligatorio",
    "MAX_PRICE_REQUIRED":"El campo de precio máximo es obligatorio",
    "MAX_QUANTITY_REQUIRED":"El campo de cantidad máxima es obligatorio",
    "MIN_QUANTITY_REQUIRED":"El campo de cantidad mínima es obligatorio",
    "LOT_SIZE_REQUIRED":"El campo de tamaño de lote es obligatorio",
    "Contract updated successfully. Refreshing data...":"Contrato actualizado con éxito. Actualizando datos...",
    "This pair is not available in Bybit":"Este par no está disponible en Bybit",
    "FIRST_CURRENCY_NOT_SAME":"La primera moneda y la segunda moneda no pueden ser iguales",
    "Templates updated successfully. Refreshing data...":"Plantillas actualizadas con éxito. Actualizando datos...",
    "Cms updated successfully. Refreshing data...":"Cms actualizado correctamente. Actualizando datos...",
    "ip added successfully":"IP agregado con éxito",
    "ARE_YOU_DELETE_BLOCK_LIST":"¿Está seguro de que desea eliminar esto de la lista de bloqueo?",
    "ip deleted successfully":"IP eliminado exitosamente",
    "NEWSLETTER DETAILS":"Detalles del boletín",
    "MESSAGE":"Mensaje",
    "SEND":"Enviar",
    "TOKEN_LAUNCH_PRICE": "Precio de lanzamiento del token",
    "Whitepaper": "Papel blanco",
    "Token Launch Coin": "Moneda de lanzamiento de token",
    "SUPPORT_TICKET_LIST" : "Lista de tickets de soporte",
    "Send_Coin": "enviar moneda"

}