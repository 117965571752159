import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { WithTranslation } from "react-i18next"; 
import {
    GET_ERRORS,
    PERPETUAL_ADD,
    PERPETUAL_UPDATE
} from "./types";
import keys from "./config";

const url = keys.baseUrl;
export const addperpetual = (faqData,t) => dispatch => {
    console.log("tttttt",t);
    axios
        .post(url+"api/perpetual-add", faqData)
        .then(res =>
           { 
            console.log("SCUCESS MESSAGE ARE",res);
            dispatch({
                type: PERPETUAL_ADD,
                payload: res,
            })}
        ).catch(err =>
            {
            console.log("ERR MESSAGE ARE", err.response.data);
            toast.error(t(err.response.data.message), {
                position: toast.POSITION.TOP_LEFT
            });
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response.data
                })
            }
       
    );
};


export const updatePerpetual = (faqData) => dispatch => {
    axios
        .post(url+"api/perpetual-update", faqData)
        .then(res =>
            dispatch({
                type: PERPETUAL_UPDATE,
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        })
    );
};

export const addpair = (faqData) => dispatch => {
    axios
        .post(url+"api/pair-add", faqData)
        .then(res =>
            dispatch({
                type: PERPETUAL_ADD,
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        })
    );
};


export const updatepair = (faqData) => dispatch => {
    console.log("faqData",faqData)
    axios
        .post(url+"api/pair-update", faqData)
        .then(res =>
            dispatch({
                type: PERPETUAL_UPDATE,
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        })
    );
};
