import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import { withTranslation, WithTranslation } from "react-i18next";
import axios from "axios";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import SupportReplyModal from "../partials/SupportReplyModal";
import { toast, ToastContainer} from "react-toastify";
import keys from "../../actions/config";
const url = keys.baseUrl;
class Support extends Component {
    constructor(props) {
        super(props);
const {t}=this.props
        this.columns = [
            
            {
                key: "tickerId",
                text: "Ticket ID",
                className: "name",
                align: "left",
                sortable: true,
            },
            {
                key: "userid",
                text: "User Id",
                className: "name",
                align: "left",
                sortable: true,
            },
            {
                key: "categoryName",
                text: "Category Name",
                className: "name",
                align: "left",
                sortable: true,
            },
            {
                key: "replystatus",
                text: 'status',
                className: "replystatus",
                align: "left",
                sortable: true
            },
            {
                key: "created_date",
                text: "Date",
                className: "closedstatus",
                align: "left",
                sortable: true
            },
            {
                key: "action",
                text: t("ACTION"),
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
                    console.log('recorddetauls',record)
                    return (
                        <Fragment>
                           <div className="d-flex">{record.replystatus == "Close" ? "-" :
                                <button
                                    className="btn btn-primary btn-sm mr-2"
                                    onClick={() => this.editRecord(record)}
                                >
                                    <i className="fa fa-reply"></i>
                                </button>
                            }
                            {/* <button
                                className="btn btn-primary btn-sm mr-2"
                                onClick={() => this.editRecord(record)}
                                >
                                <i className="fa fa-reply"></i>
                            </button> */}
                        {record.replystatus == "Close" ? "" : <button
                            className="btn btn-outline btn-sm"
                            onClick={() => this.deleteRecord(record)}>
                            <i className="fa fa-trash"></i>
                        </button>}</div> 
                        </Fragment>
                    );
                }
            }
        ];

        this.config = {
            page_size: 10,
            length_menu: [ 10, 20, 50 ],
            filename: "Cms",
            no_data_text: 'No Enquires found!',
            language: {
                length_menu: t("SHOW_MENU"),
                filter: t("FILTER IN RECORDS"),
                info: t("SHOWING_START_END"),
                pagination: {
                    first: t("FIRST"),
                    previous: t("PREVIOUS"),
                    next: t("NEXT"),
                    last: t("LAST")
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            records: []
        };

        this.state = {
            currentRecord: {
                _id: '',
                email_add: '',
                subject: '',
                description: '',
                reply : '',
                attachment:''
            }
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.getData()
    };

    componentWillReceiveProps(nextProps) {
        this.getData()
    }

    getData() {
        axios
            .post(url+"api/support-data")
            .then(res => {
                console.log('respodndata',res)
                this.setState({ records: res.data})
            })
            .catch()
            console.log(this.state,'zzsedrfvtguyvz€zayxhs');
    }

    editRecord(record) {
        console.log(record,'record');
         this.props.history.push("/support_reply/" + record.tickerId);
         
    }

    deleteRecord(record) {
      console.log(record);
      const {t}=this.props
        axios
            .post(url+"api/support-delete", {_id: record._id})
            .then(res => {
                if (res.status === 200) {
                   toast(t("CONTACT_DELETED_SUCCESSFULLY"), {
                       position: toast.POSITION.TOP_CENTER,
                   })
                }
            })
            .catch();
        this.getData();
    }

    pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }

    render() {
const {t}=this.props
    
        return (
            <div>
                <Navbar/>
                <div className="d-flex" id="wrapper">
                    <Sidebar/>
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <h3 className="mt-2 text-secondary">{t("SUPPORT_TICKET_LIST")}</h3>
                            <div className="resonsive_table">
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                onPageChange={this.pageChange.bind(this)}
                                className="table table-bordered table-striped support_table"
                            />
                            </div>
                        </div>
                    </div>
                    <ToastContainer/>
                </div>
            </div>
        );
    }

}

Support.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});

export default connect(
    mapStateToProps
)(withTranslation()(Support),(Support));
