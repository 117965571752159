import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateLaunchpad } from "../../actions/LaunchpadActions";
import keys from "../../actions/config"
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';
import Select from 'react-select';
import {CKEditor} from 'ckeditor4-react'
import { Editor } from '@tinymce/tinymce-react';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from "react-datepicker";
import { Link } from 'react-router-dom';
import { currency } from '../../actions/currency';
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";

const url = keys.baseUrl
class LaunchpadUpdateModal extends React.Component {
    constructor(props) {
        super(props);
        console.log("props",props);
         $("#update-launchpad-modal").find(".text-danger").hide();
         this.state = {
            id: this.props.record._id,
            tokenname: this.props.record?.currencyId?.currencySymbol,
            // symbol: this.props.record.symbol,
            availablecurrency: [],
            price: this.props.record.launchPrice,
            minAmt: this.props.record.minAmt,
            discount: this.props.record.discount,
            availablesale: this.props.record.availablesale,
            maxsupply: this.props.record.maxsupply,
            industry: this.props.record.industry,
            website: this.props.record.website,
            curimage: this.props.record.curimage,
            content: this.props.record.content,
            startDate: this.props.record.startdate,
            endDate: this.props.record.enddate,
            errors: {},
            options: [],
            currencyArray:[],
            currencyId: "",
            attachment: ""
         };
         this.handleEditorChange = this.handleEditorChange.bind(this);
         this.handleChange = this.handleChange.bind(this);
         this.handleChange1 = this.handleChange1.bind(this);
    }

    filterPassedTime (time){
        const currentDate = new Date();
        const selectedDate = new Date(time);
        return currentDate.getTime() < selectedDate.getTime()
    };
    handleChange(date) {
     this.setState({
     startDate: date
     })
      
     }
    handleChange1(date1) {
  
        this.setState({
     endDate: date1
     })
     }
    handleEditorChange(e) {
       this.setState({ content:e.editor.getData() });
     }

     async currencyDetails(){
        var options = []
        var availableCurrenct = []
        const currencyData = await currency();
        currencyData.result.map((res,i)=>{
            if(res.type == "Token"){
                options.push({value:res._id,label:res.currencySymbol,id:res._id})
            }
            if(res.type == "Token" || res.type == "Crypto"){
                availableCurrenct.push({value:res._id,label:res.currencySymbol })
            }
        })
        console.log("options_options_options",options,availableCurrenct);
        this.setState({options: options,currencyArray: availableCurrenct})
        
        console.log("currency_currencyData",currencyData);
    }

    componentDidMount(){
        this.currencyDetails()
     }

    componentWillReceiveProps(nextProps) {
        console.log("!!!!!!!!!!!!",nextProps)
        if (nextProps.record) {
            this.setState({
                id: nextProps.record._id,
                tokenname: nextProps.record.tokenname,
                symbol: nextProps.record.launchCoin,
                availablecurrency:nextProps.record.availablecurrency,
                price:nextProps.record.launchPrice,
                minAmt: nextProps.record.minAmt,
                discount: nextProps.record.discount,
                availablesale: nextProps.record.availablesale,
                maxsupply: nextProps.record.maxsupply,
                industry: nextProps.record.industry,
                website: nextProps.record.website,
                content: nextProps.record.content,
                startDate:nextProps.record.startdate,
                endDate:nextProps.record.enddate,
                whitePaper: nextProps.record.whitePaper,
            })
        }
        if (nextProps.errors) {
            console.log("nextProps.errors",nextProps.errors.errors)
             $("#update-launchpad-modal").find(".text-danger").show();
            this.setState({
                errors: nextProps.errors.errors,
            });
        }
        if (nextProps.auth !== undefined
            && nextProps.auth.updatetemplate !== undefined
            && nextProps.auth.updatetemplate.data !== undefined
          ) {
            $('#update-launchpad-modal').modal('hide');
            toast("Launchpad update successfully", {
                position: toast.POSITION.TOP_CENTER
            });
            nextProps.auth.updatetemplate = "";
        }
    }

    onChange = e => {
         $("#update-launchpad-modal").find(".text-danger").show();
        if (e.target.id === 'template-update-tokenname') {
            this.setState({ tokenname: e.target.value });
        }
         if (e.target.id === 'template-update-symbol') {
            this.setState({ symbol: e.target.value });
        }
         if (e.target.id === 'template-update-availablecurrency') {
            this.setState({ availablecurrency: e.target.value });
        }
          if (e.target.id === 'template-update-price') {
            this.setState({ price: e.target.value });
        }
          if (e.target.id === 'template-update-minAmt') {
            this.setState({ minAmt: e.target.value });
        }
          if (e.target.id === 'template-update-discount') {
            this.setState({ discount: e.target.value });
        }
         if (e.target.id === 'template-update-availablesale') {
            this.setState({ availablesale: e.target.value });
        }
         if (e.target.id === 'template-update-maxsupply') {
            this.setState({ maxsupply: e.target.value });
        }
         if (e.target.id === 'template-update-industry') {
            this.setState({ industry: e.target.value });
        }
        
         if (e.target.id === 'template-update-website') {
            this.setState({ website: e.target.value });
        }
          if (e.target.id === 'template-update-startdate') {
            this.setState({ startdate: e.target.value });
        }
        
        if (e.target.id === 'template-update-enddate') {
            this.setState({ enddate: e.target.value });
        }
    };

     onlaunchpadUpdate = async e => {
        e.preventDefault();
         $("#update-launchpad-modal").find(".text-danger").show();
        const newTemplate = {
            _id: this.state.id,
            tokenname: this.state.tokenname,
            symbol   : this.state.symbol,
            availablecurrency: this.state.availablecurrency,
            price:this.state.price,
            minAmt:this.state.minAmt,
            discount:this.state.discount,
            availablesale:this.state.availablesale,
            maxsupply:this.state.maxsupply,
            industry:this.state.industry,
            website:this.state.website,
            content: this.state.content,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            whitePaper: this.state.whitePaper,
            file1: this.state.attachment
        };
        const data = new FormData();
        data.append("launchId", this.state.id);
        data.append("tokenname", this.state.tokenname);
        data.append("symbol", this.state.symbol);
        data.append("availablecurrency", this.state.availablecurrency);
        data.append("price", this.state.price);
        data.append("minAmt", this.state.minAmt);
        data.append("discount", this.state.discount);
        data.append("availablesale", this.state.availablesale);
        data.append("maxsupply", this.state.maxsupply);
        data.append("industry", this.state.industry);
        data.append("website", this.state.website);
        data.append("content", this.state.content);
        data.append("startdate", this.state.startDate);
        data.append("endDate", this.state.endDate);
        data.append("whitePaper", this.state.whitePaper);
        data.append("file1", this.state.attachment);

        // console.log("update-launchpad-modal",newTemplate);
        // const updateLaunchpad = await axios.post(url+"api/update-Launchpad",data);
        // console.log("updateLaunchpad",updateLaunchpad);
        this.props.updateLaunchpad(data);
    };
    handleChangeSelect = (data) => {
        this.setState({tokenname: data.value,currencyId: data.value})
    }
    handleChangeLaunchCoin(data){
        console.log("data_________",data)
        this.setState({symbol: data.value})
    }
    selectAvailableCurrency(data){
        console.log("data_________",data)
        if(data && data.length > 0){
            this.setState({availablecurrency : data.map((el) => { return el.value})}) 
        }else if (data == null){
            this.setState({availablecurrency : []}) 
        }else{
            this.setState({availablecurrency : []}) 

        }
        
    }
    handleChangeefile = (event) => {
        console.log("event.target.files[0]",event.target.files[0]);
        if (event.target.files[0]) {
            var CheckFormate = event.target.files[0].name.split(".");
            CheckFormate = CheckFormate[CheckFormate.length - 1];
            if(CheckFormate == "pdf" || CheckFormate == "PDF"){
                console.log("CheckFormate",CheckFormate);
                var filesize = event.target.files[0].size
                this.setState({
                    // curimage: URL.createObjectURL(event.target.files[0]),
                    attachment: event.target.files[0],
                    // fileName: event.target.files[0].name
                });
            }else{
                toast.error("Select correct file formate", {
                    position: toast.POSITION.TOP_CENTER
                });
            }
           
        }}

    render() {
        const { errors } = this.state;
        console.log("this@@@@@@",this.state.tokenname)

        return (
            <div>
                <div className="modal fade" id="update-launchpad-modal">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Edit Launchpad Details</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                           <div className="modal-body">
                                <form noValidate onSubmit={this.onlaunchpadUpdate} id="update-launchpad" >
                                    {/* <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="tokenname">Token Name</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.tokenname}
                                                id="template-update-tokenname"
                                                type="text"
                                                error={errors.tokenname}
                                                className={classnames("form-control", {
                                                    invalid: errors.tokenname
                                                })}/>
                                            <span className="text-danger">{errors.tokenname}</span>

                                        </div>
                                    </div> */}
                                    {/* <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="symbol">Token Symbol</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.symbol}
                                                error={errors.symbol}
                                                id="template-update-symbol"
                                                type="symbol"
                                                className={classnames("form-control", {
                                                    invalid: errors.symbol
                                                })}
                                            />
                                            <span className="text-danger">{errors.symbol}</span>
                                        </div>
                                    </div> */}
                         <div className="row mt-2">
                            <div className="col-md-3">
                                <label htmlFor="tokenname">Token</label>
                            </div>
                            <div className="col-md-9">
                                {/* <Select  isSearchable={false}
                                   
                                    styles={this.styles} className="border_blue_select basic-multi-select"
                                /> */}
                                 <Select
                                                // value={this.state.tokenname}
                                                
                                                onChange={this.handleChangeSelect}
                                                options={this.state.options}
                                                label={this.state.tokenname}
                                                styles={this.styles} className="border_blue_select basic-multi-select"
                                            />
                                {/* <span className="text-danger">{errors.currencyId}</span> */}
                            </div>
                            <div className="col-md-12 py-3 text-right">
                                <Link to={'/currency'} className="link_new_green">Add Currency</Link>
                            </div>
                           
                        </div>
                        <div className="row mt-2">
                            <div className="col-md-3">
                                <label htmlFor="symbol">Available Currency</label>
                            </div>
                            <div className="col-md-9">
                                {/* <Select isSearchable={false}
                                    // name="availableCoin"
                                    isMulti
                                    styles={this.styles} className="border_blue_select basic-multi-select"
                                    classNamePrefix="select"
                                /> */
                                }
                                <Select
                                                // value={this.state.availablecurrency}
                                                onChange={(e)=> {this.selectAvailableCurrency(e)}}
                                                isMulti
                                                options={this.state.currencyArray}
                                                label="Select"
                                                styles={this.styles} className="border_blue_select basic-multi-select"
                                            />
                                <span className="text-danger">{errors?.availablecurrency}</span>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-md-3">
                                <label htmlFor="minimum">White Paper</label>
                            </div>
                            <div className="col-md-9">
                            <label class="custom-file-upload">
                                <input
                                    name="whitePaper"
                                    type="file"
                                    accept="image/x-pdf,image/doc,image/odt"
                                    // onChange={this.handleFile}
                                    aria-describedby="fileHelp"
                                    onChange={(e)=> {this.handleChangeefile(e)}}
                                />
                                Choose File
                                </label>
                                <span className="text-danger"></span>
                               
                                    <a target="_blank" href={`${keys.imageUrl}/launchpad/${this.state.whitePaper}`} className="link_new_green">view</a>
                              
                            </div>
                        </div>
                        <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="price">Launch Price</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                            placeholder={this.state.price}
                                                onChange={this.onChange}
                                                value={this.state.price}
                                                error={errors?.price}
                                                id="template-update-price"
                                                type="price"
                                                className={classnames("form-control", {
                                                    invalid: errors?.price
                                                })}
                                            />
                                            <span className="text-danger">{errors?.price}</span>
                                        </div>
                                    </div>
                        <div className="row mt-2">
                        <div className="col-md-3">
                                <label htmlFor="price">Launch Coin</label>
                            </div>
                            <div className="col-md-9">
                                {/* <Select isSearchable={false}
                                styles={this.styles} className="border_blue_select basic-multi-select"
                                    
                                /> */}
                                <Select
                                                // value={selectedTerms}
                                                onChange={(e)=> {this.handleChangeLaunchCoin(e)}}
                                                options={this.state.currencyArray}
                                                label="Single select"
                                                styles={this.styles} className="border_blue_select basic-multi-select"
                                            />
                                <span className="text-danger"></span>
                            </div>
                        </div>
                                        {/* <div className="row mt-2">
                                  <div className="col-md-3">
                                  <label htmlFor="minimum">Token ICON</label>
                                  </div>
                                  <div className="col-md-9">
                                  <label class="custom-file-upload">
                                  <input
                                        type="file"
                                        accept="image/x-png,image/gif,image/jpeg"
                                        onChange={this.handleChangeefile}
                                        name="file1"
                                        id="exampleInputFile"
                                        aria-describedby="fileHelp"
                                      />
                                      Choose File
                                      </label>
                                      <span className="text-danger">{this.state.imageerr}</span>
                                     <p>{this.state.curimage}</p>
                                  </div>
                                </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="price">Token Launch Price</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.price}
                                                error={errors.price}
                                                id="template-update-price"
                                                type="price"
                                                className={classnames("form-control", {
                                                    invalid: errors.price
                                                })}
                                            />
                                            <span className="text-danger">{errors.price}</span>
                                        </div>
                                    </div> */}
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password2">Minimum Purchase Amount</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.minAmt}
                                                id="template-update-minAmt"
                                                type="minAmt"
                                                className={classnames("form-control", {
                                                    invalid: errors?.minAmt
                                                })}
                                            />
                                            <span className="text-danger">{errors?.minAmt}</span>
                                        </div>
                                    </div>
                                      <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password2">Discount</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.discount}
                                                id="template-update-discount"
                                                type="discount"
                                                className={classnames("form-control", {
                                                    invalid: errors?.discount
                                                })}
                                            />
                                            <span className="text-danger">{errors?.discount}</span>
                                        </div>
                                    </div>
                                       <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password2">Token Available to Sale</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.availablesale}
                                                id="template-update-availablesale"
                                                type="availablesale"
                                                className={classnames("form-control", {
                                                    invalid: errors?.availablesale
                                                })}
                                            />
                                            <span className="text-danger">{errors?.availablesale}</span>
                                        </div>
                                    </div>
                                     <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password2">Token Max Supply</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.maxsupply}
                                                id="template-update-maxsupply"
                                                type="maxsupply"
                                                className={classnames("form-control", {
                                                    invalid: errors?.maxsupply
                                                })}
                                            />
                                            <span className="text-danger">{errors?.maxsupply}</span>
                                        </div>
                                    </div>
                                     <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password2">Industry</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.industry}
                                                id="template-update-industry"
                                                type="industry"
                                                className={classnames("form-control", {
                                                    invalid: errors?.industry
                                                })}
                                            />
                                            <span className="text-danger">{errors?.industry}</span>
                                        </div>
                                    </div>
                                     <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password2">Website</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.website}
                                                id="template-update-website"
                                                type="website"
                                                className={classnames("form-control", {
                                                    invalid: errors?.website
                                                })}
                                            />
                                            <span className="text-danger">{errors?.website}</span>
                                        </div>
                                    </div>
                                     <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password2">Start Date</label>
                                        </div>
                                        <div className="col-md-9">
                                         {/* <DatePicker
                                          selected={ this.state.startDate }
                                          onChange={ this.handleChange }
                                          dateFormat="MMMM d, yyyy"
                                          className={classnames("form-control", {
                                                    invalid: errors.startDate
                                                })}
                                          /> */}{console.log(this.state.startDate,'this.state.startDate')}
                                         { this.state.startDate  &&
                                           <DatePicker
                                                selected={this.state.startDate}
                                                // placeholderText={new Date(this.state.startDate)}
                                                // onChange={this.handleChange}
                                                onChange={(date) => {
                                                    let newDate = new Date(date)
                                                    newDate.setMilliseconds(0)
                                                    this.setState({startDate: newDate.getTime()})
                                                }}
                                                dateFormat="MMMM d, yyyy HH:mm"
                                                className={classnames("form-control", {
                                                    invalid: errors?.startDate
                                                })}
                                                showTimeSelect
                                                timeFormat="HH:mm"
                                                minDate={new Date()}
                                                timeIntervals={1}
                                                filterTime={this.filterPassedTime}
                                            />}
                                            <span className="text-danger">{errors?.startdate}</span>
                                        </div>
                                    </div>
                                     <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password2">End Date</label>
                                        </div>
                                        <div className="col-md-9">
                                         {/* <DatePicker
                                          selected={ this.state.endDate }
                                          onChange={ this.handleChange1 }
                                          dateFormat="MMMM d, yyyy"
                                          className={classnames("form-control", {
                                                    invalid: errors.endDate
                                                })}
                                          /> */}
                                          <DatePicker
                                                selected={this.state.endDate}
                                                // onChange={this.handleChange1}
                                                onChange={(date) => {
                                                    let newDate = new Date(date)
                                                    newDate.setMilliseconds(0)
                                                    this.setState({endDate: newDate.getTime()})
                                                }}
                                                dateFormat="MMMM d, yyyy HH:mm"
                                                className={classnames("form-control", {
                                                    invalid: errors?.endDate
                                                })}
                                                showTimeSelect
                                                timeFormat="HH:mm"
                                                minDate={new Date()}
                                                timeIntervals={1}
                                                filterTime={this.filterPassedTime}
                                            />
                                            <span className="text-danger">{errors?.endDate}</span>
                                        </div>
                                    </div>
                                    {/* <div className="row mt-2"> */}
                            {/* <div className="col-md-3">
                                <label htmlFor="password2">Telegram Link</label>
                            </div> */}
                            {/* <div className="col-md-9">
                                <input
                                    name="telegram"
                                    type="text"
                                    onChange={this.handleChange}
                                    className={classnames("form-control", {
                                        invalid: errors.telegram
                                    })}
                                />
                                <span className="text-danger">{errors.telegram}</span>
                            </div> */}
                        {/* </div> */}

                        {/* <div className="row mt-2">
                            <div className="col-md-3">
                                <label htmlFor="password2">Twitter Link</label>
                            </div>
                            <div className="col-md-9">
                                <input
                                    name="twitter"
                                    type="text"
                                    onChange={this.handleChange}
                                    className={classnames("form-control", {
                                        invalid: errors.twitter
                                    })}
                                />
                                <span className="text-danger">{errors.twitter}</span>
                            </div>
                        </div> */}

                        {/* <div className="row mt-2">
                            <div className="col-md-3">
                                <label htmlFor="password2">Facebook Link</label>
                            </div>
                            <div className="col-md-9">
                                <input
                                    name="facebook"
                                    type="text"
                                    onChange={this.handleChange}
                                    className={classnames("form-control", {
                                        invalid: errors.facebook
                                    })}
                                />
                                <span className="text-danger">{errors.facebook}</span>
                            </div>
                        </div> */}


                        {/* <div className="row mt-2">
                            <div className="col-md-3">
                                <label htmlFor="password2">Youtube Link</label>
                            </div>
                            <div className="col-md-9">
                                <input
                                    name="youtube"
                                    type="text"
                                    onChange={this.handleChange}
                                    className={classnames("form-control", {
                                        invalid: errors.youtube
                                    })}
                                />
                                <span className="text-danger">{errors.youtube}</span>
                            </div>
                        </div> */}

                        {/* <div className="row mt-2">
                            <div className="col-md-3">
                                <label htmlFor="password2">LinkedIn Link</label>
                            </div>
                            <div className="col-md-9">
                                <input
                                    name="linkedIn"
                                    type="text"
                                    onChange={this.handleChange}
                                    className={classnames("form-control", {
                                        invalid: errors.linkedIn
                                    })}
                                />
                                <span className="text-danger">{errors.linkedIn}</span>
                            </div>
                        </div> */}
                                       <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="content">Content</label>
                                        </div>
                                        <div className="col-md-9">
                                        {/* <Editor apiKey='5vk89nvvi2zckrb2lp2ctyyolewhq1v3pzdiwb7at68h40a5'
                                           initialValue="<p>This is the initial content of the editor</p>"
                                           value={this.state.content} onEditorChange={this.handleEditorChange}
                                           init={{
                                             height: 500,
                                             menubar: false,
                                             plugins: [
                                               'advlist autolink lists link image charmap print preview anchor',
                                               'searchreplace visualblocks code fullscreen',
                                               'insertdatetime media table paste code help wordcount'
                                             ],
                                             toolbar:
                                               'undo redo code | formatselect | bold italic backcolor | \
                                               alignleft aligncenter alignright alignjustify | \
                                               bullist numlist outdent indent | removeformat | help'
                                           }}
                                         /> */}
                                            {
                                                this.state.content &&
                                                <CKEditor
                                                    config={{
                                                        //extraAllowedContent: 'div(*)',
                                                        allowedContent: true,
                                                        height: 500,
                                                    }}
                                                    initData={this.state.content}
                                                    onChange={this.handleEditorChange}
                                                />
                                            }
                                            <span className="text-danger">{errors?.content}</span>
                                        </div>
                                    </div>
                                    
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-outline" data-dismiss="modal">Close</button>
                                <button
                                    form="update-launchpad"
                                    type="submit"
                                    className="btn btn-primary">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>

        )
    }
}

LaunchpadUpdateModal.propTypes = {
    updateLaunchpad: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { updateLaunchpad }
)(withRouter(LaunchpadUpdateModal));
