import React from 'react'
import classnames from "classnames";
import { Modal, Form } from "react-bootstrap";
import keys from "../../actions/config";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//import action
// import { categoryUpdate } from '../../actions/supportAction'

// import lib
// import { toastAlert } from '../../lib/toastAlert';


const url = keys.baseUrl;
class categoryUpdateModal extends React.Component {
    constructor() {
        super();
        this.state = {
          id: '',
          categoryName  : "",
          status :"",
      };
    }

    componentWillReceiveProps(nextProps) {
        const { record } = nextProps;
        console.log("record_record",record)
        if (record) {
            this.setState({
              id: record._id,
              categoryName  : record.categoryName,
              status :record.status,
            })
        }
    }

    // handleChange = e => {
    //     e.preventDefault();
    //     let { name, value } = e.target;
    //     // let formData = { ...this.state.formValue, ...{ [name]: value } };
    //     // this.setState({ formValue: formData });
    //     this.setState({ categoryName: , status: , errors:''})
    // };

    handleClose = () => {
        const { onHide } = this.props;
        onHide();
        // this.setState({ 'formValue': initialFormValue, errors: {} });
    }

    handleSubmit = async e => {
        e.preventDefault();
        try {
          let reqData = {
            "id" : this.state.id,
            "categoryName" : this.state.categoryName,
            "status" : this.state.status
            }
            console.log("reqData_reqData",reqData);
            const updateCategory = await axios.post(url + "api/supportcategory_update",reqData);
            console.log("updateCategory_updateCategory",updateCategory);
            if(updateCategory.data.success == true){
              toast(updateCategory.data.message, {
                position: toast.POSITION.TOP_CENTER
              });
              this.handleClose()
              this.props.fetchData()
            }
        }
        catch (err) { }
    }

    render() {
        // const { errors } = this.state;
        const { isShow, } = this.props;

        return (
            <div>
              {console.log("STATES", this?.state?.categoryName ,this.state.id)}
                <Modal
                    show={isShow}
                    onHide={this.handleClose}
                    aria-labelledby="contained-modal-title-vcenter"
                    scrollable={true} centered
                >
                    <Modal.Header closeButton>
                        <h4 className="modal-title">Update Category</h4>
                    </Modal.Header>
                    <Modal.Body>
                        <form noValidate id="add-spot">
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="taker_fees">Category Name</label>
                                </div>
                                <div className="col-md-9">
                                    <input
                                        onChange={(e)=> {this.setState({categoryName: e.target.value})}}
                                        value={this.state.categoryName}
                                        name="categoryName"
                                        // error={errors.categoryName}
                                        type="text"
                                        className={classnames("form-control", {
                                            // invalid: errors.categoryName
                                        })}
                                    />
                                    {/* <span className="text-danger">{errors.categoryName}</span> */}
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="name">Status</label>
                                </div>
                                <div className="col-md-9">
                                    <Form.Control
                                        as="select"
                                        custom
                                        name={'status'}
                                        value={this.state.status}
                                        onChange={(e)=> {this.setState({status: e.target.value})}}
                                    >
                                        <option value={'active'}>Active</option>
                                        <option value={'Inactive'}>Inactive</option>
                                    </Form.Control>
                                    {/* <span className="text-danger">{errors.status}</span> */}
                                </div>
                            </div>

                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={this.handleClose}
                        >
                            Close
                        </button>
                        <button
                            onClick={this.handleSubmit}
                            className="btn btn-primary"
                        >
                            Update
                        </button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}


export default categoryUpdateModal;