import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import { withTranslation, WithTranslation } from "react-i18next";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import axios from "axios";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import PairAddModal from "../partials/PairAddModal";
import PairUpdateModal from "../partials/PairUpdateModal";
import { toast, ToastContainer} from "react-toastify";
import $ from 'jquery';
import keys from "../../actions/config";
import { t } from "i18next";
const url = keys.baseUrl;

class Pairmanagement extends Component {
    constructor(props) {
        super(props);
        const {t}=this.props

        this.columns = [
            {
                key: "tiker_root",
                text: t("TICKER"),
                className: "tiker_root",
                align: "left",
                sortable: true,
                width:200
            },
            {
                key: "action",
                text: t("ACTION"),
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
                    return (
                        <Fragment>
                           <div className="d-flex"> <button
                                data-toggle="modal"
                                data-target="#update-spot-modal"
                                className="btn btn-primary btn-sm mr-2"
                                onClick={() => this.editRecord(record)}
                               >
                                <i className="fa fa-edit"></i>
                            </button>
                            <button
                                className="btn btn-outline btn-sm"
                                onClick={() => this.deleteRecord(record)}>
                                <i className="fa fa-trash"></i>
                            </button></div>
                        </Fragment>
                    );
                }
            }
        ];

        this.config = {
            page_size: 10,
            length_menu: [ 10, 20, 50 ],
            filename: "Perpetual",
            no_data_text: t('NO_CONTRACTS_FOUND'),
            language: {
                length_menu: t("SHOW_MENU"),
                filter: t("FILTER IN RECORDS"),
                info: t("SHOWING_START_END"),
                pagination: {
                    first: t("FIRST"),
                    previous: t("PREVIOUS"),
                    next: t("NEXT"),
                    last: t("LAST")
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            records: []
        };

        this.state = {
            currentRecord: {
                id: '',
                tiker_root: '',
            }
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.getData()
    };

    componentWillReceiveProps(nextProps) {
        this.getData()
    }

    getData() {
        axios
            .post(url+"api/spotpair-data")
            .then(res => {
                console.log("res.data",res.data)
                if(res.data != undefined)
                {
                    this.setState({ records: res.data.data});
                }
                
            })
            .catch()
    }

    editRecord(record) {
         $("#update-spot-modal").find(".text-danger").hide();
         console.log(record)
        this.setState({ currentRecord: record});
    }
    addRecord() {
        $("#add-spot-modal").find(".text-danger").hide();
    }

    deleteRecord(record) {
        const {t}=this.props
        if(!window.confirm(t("SPOT_TARDE_PAIRS"))){ return false; }
        axios
            .post(url+"api/pair-delete", {_id: record._id})
            .then(res => {
                if (res.status === 200) {
                   toast(res.data.message, {
                       position: toast.POSITION.TOP_CENTER,
                   })
                }
            })
            .catch();
        setTimeout(
    function() {
        this.getData();
    }
    .bind(this),
    1000
);
        
    }

    pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }

    render() {
        const {t} =this.props
        return (
            <div>
                <Navbar/>
                <div className="d-flex" id="wrapper">
                    <Sidebar/>
                    <PairAddModal/>
                    <PairUpdateModal record={this.state.currentRecord}/>
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <button onClick={() => this.addRecord()} className="btn btn-outline float-right mt-0 mr-2 btn_pos_right" data-toggle="modal" data-target="#add-spot-modal"><FontAwesomeIcon icon={faPlus}/>{t("ADD_PAIR_PAGE")} </button>
                            <div className="clearfix" />
                            <h3 className="mt-2 text-secondary">{t("SPOT_TARDE_PAIRS")}</h3>
                            
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                onPageChange={this.pageChange.bind(this)}
                                className="table table-bordered table-striped pair_dt_table"
                            />
                        </div>
                    </div>
                    <ToastContainer/>
                </div>
            </div>
        );
    }

}

Pairmanagement.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});

export default connect(
    mapStateToProps
)(withTranslation()(Pairmanagement),(Pairmanagement));
