import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Select from "react-select";
import {Modal,Button} from 'react-bootstrap/';
import { withTranslation } from 'react-i18next';
import axios from "axios";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import { toast, ToastContainer} from "react-toastify";
import $ from 'jquery';
import keys from "../../actions/config";
const url = keys.baseUrl;

class Deposit extends Component {
    constructor(props) {
        super(props);
const {t} = this.props;

        this.columns = [
        {
                key: "created_date",
                text: t("CREATED_DATE"),
                className: "created_date",
                align: "left",
                sortable: true
            },
            {
                key: "toaddress",
                text: t("TO_ADDRESS"),
                className: "toaddress",
                align: "left",
                sortable: true,
                width:200,
            },
            {
                key: "currency",
                text: t("COIN"),
                className: "currency",
                align: "left",
                sortable: true
            },
             {
                key: "user_id",
                text: t("USER_MAIL"),
                className: "user_id",
                align: "left",
                sortable: true,
                width:200,
            },
            
            {
                key: "amount",
                text: t("TRANSFER_AMOUNT"),
                className: "amount",
                align: "left",
                sortable: true
            },
            {
                key: "status",
                text: t("STATUS"),
                className: "status",
                align: "left",
                sortable: true
            },
             {
                key: "txid",
                text: t("TRANSACTION_ID"),
                className: "txid",
                align: "left",
                sortable: true
            },
            
        ];

        this.config = {
            page_size: 10,
            length_menu: [ 10, 20, 50 ],
            filename: "Withdraw",
            no_data_text: t('NO_RECORD_FOUND'),
            sort:{column: "Created date", order: "desc"},
            language: {
                length_menu: t("SHOW_MENU"),
                filter: t("FILTER IN RECORDS"),
                info: t("SHOWING_START_END"),
                pagination: {
                    first: t("FIRST"),
                    previous: t("PREVIOUS"),
                    next: t("NEXT"),
                    last: t("LAST")
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
                records: [],
                showDetails : false,
                id: '',
                status : '',
                transferamount: '',
                cryptoType: '',
                userId: '',
                receiveraddress: '',
                tagid: '',
                errors: ''

        };
        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.getData()
    };
    showDetails = (record) => {
      this.setState({receiveraddress:record.receiveraddress.address})
      this.setState({tagid:record.receiveraddress.tagid})
      this.setState({id:record._id})
      this.setState({cryptoType:record.cryptoType})
      this.setState({transferamount:record.transferamount})
      this.setState({status:record.status})
      this.setState({showDetails:true})
    }
    confirmSubmit = () => {
      var id = this.state.id;
      var passVal = {id:id,status:"Confirmed"};
      axios
          .post(url+"api/updatewithdraw",passVal)
          .then(res => {
            toast(res.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
            this.setState({showDetails:false});
            this.getData();
          })
          .catch()
    }
    rejectSubmit = () => {
      var id = this.state.id;
      var passVal = {id:id,status:"Rejected"};
      axios
          .post(url+"api/updatewithdraw",passVal)
          .then(res => {
            toast(res.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
            this.setState({showDetails:false});
            this.getData();
          })
          .catch()
    }

    handleClosedetails = (record) => {
      this.setState({showDetails:false})
    }
    getData() {
        axios
            .post(url+"api/deposit-data")
            .then(res => {
              console.log(res);
                this.setState({ records: res.data})
            })
            .catch()
    }


    pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }

    render() {
const {t} = this.props;

        return (
            <div>
            <Modal show={this.state.showDetails} onHide={this.handleClosedetails}  aria-labelledby="contained-modal-title-vcenter" centered>
      				<Modal.Header closeButton>
      				<Modal.Title>Details</Modal.Title>
      				</Modal.Header>
      				<Modal.Body>
      				<div className="popUpSpace">
              <div className="row mt-2">
                  <div className="col-md-3">
                      <label htmlFor="answer">To address</label>
                  </div>
                  <div className="col-md-9">
                      {this.state.receiveraddress}
                  </div>
              </div>

              <div className="row mt-2">
                  <div className="col-md-3">
                      <label htmlFor="answer">Tag id/Memo</label>
                  </div>
                  <div className="col-md-9">
                      {this.state.tagid}
                  </div>
              </div>
              <div className="row mt-2">
                  <div className="col-md-3">
                      <label htmlFor="answer">Transfer Coin</label>
                  </div>
                  <div className="col-md-9">
                      {this.state.cryptoType}
                  </div>
              </div>
              <div className="row mt-2">
                  <div className="col-md-3">
                      <label htmlFor="answer">Transfer Amount</label>
                  </div>
                  <div className="col-md-9">
                      {this.state.transferamount}
                  </div>
              </div>
              <div className="row mt-2">
                  <div className="col-md-3">
                      <label htmlFor="answer">Status</label>
                  </div>
                  <div className="col-md-9">
                      {this.state.status}
                  </div>
              </div>
            	</div>
      				</Modal.Body>
      				<Modal.Footer>
      					<Button variant="danger btnDefaultNewBlue" onClick={this.rejectSubmit}>
      						Reject
      					</Button>
      					<Button onClick={this.confirmSubmit} variant="success btnDefaultNew" >
      						Confirm
      					</Button>
      				</Modal.Footer>
      			</Modal>
                <Navbar/>
                <div className="d-flex" id="wrapper">
                    <Sidebar/>
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <h3 className="mt-2 text-secondary">{t("DEPOSITE_LIST")}</h3>
                            <div className="resonsive_table">
                            <ReactDatatable
                                config={this.config}
                                responsive={true}
                                records={this.state.records}
                                columns={this.columns}
                                onPageChange={this.pageChange.bind(this)}
                                className="table table-bordered table-striped deposit_dt_table"
                            />
                            </div>
                        </div>
                    </div>
                    <ToastContainer/>
                </div>
            </div>
        );
    }

}

Deposit.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});

export default connect(
    mapStateToProps
)(withTranslation()(Deposit),(Deposit));
