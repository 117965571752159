import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addLaunchpad } from "../../actions/LaunchpadActions";
import { currency } from '../../actions/currency';
import { withRouter } from "react-router-dom";
import { withTranslation, WithTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import $ from 'jquery';
import { Editor } from '@tinymce/tinymce-react';
import {CKEditor} from 'ckeditor4-react'
import Select from "react-select";
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Axios from 'axios';




class LaunchpadAddModal extends React.Component {

    constructor() {
        super();
        this.state = {
            tokenname: "",
            symbol: "",
            availablecurrency: "",
            price: "",
            minAmt: "",
            discount: "",
            availablesale: "",
            maxsupply: "",
            industry: "",
            website: "",
            content: "",
            curimage: null,
            attachment: "",
            imageerror: "",
            curimagesize: 0,
            startDate: "",
            endDate: "",
            errors: {},
            currency:[],
            currencyId: "",
            currencyArray: []
        };
        this.handleEditorChange = this.handleEditorChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChange1 = this.handleChange1.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this);
        this.handleChangeLaunchCoin = this.handleChangeLaunchCoin.bind(this);
        this.selectAvailableCurrency = this.selectAvailableCurrency.bind(this);

    }

    clearStateData(e) {

        e.preventDefault()
        this.setState({
            tokenname: "",
            symbol: "",
            availablecurrency: [],
            price: "",
            minAmt: "",
            discount: "",
            availablesale: "",
            maxsupply: "",
            industry: "",
            website: "",
            content: "",
            curimage: null,
            attachment: "",
            imageerror: "",
            curimagesize: 0,
            startDate: "",
            endDate: "",
            errors: {},
            fileName: "",
            options: [],
            currencyId: "",
            currencyArray:[]
        })
    }

    handleChange(date) {
        this.setState({
            startDate: date
        })

    }
    handleChange1(date1) {

        this.setState({
            endDate: date1
        })
    }
    handleChangeSelect(data) {
        this.setState({tokenname: data.value,currencyId: data.value})
    }
    componentWillReceiveProps(nextProps) {
        console.log("helooooooooooooooooooooooooooooooooooo",nextProps,nextProps.errors);
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        console.log("retJsonObj", nextProps.auth)
        if (nextProps.auth !== undefined
            && nextProps.auth.Lauchpadadd !== undefined
            && nextProps.auth.Lauchpadadd.data !== undefined) {
            $('#add-launch-modal').modal('hide');
            toast("LaunchPad created successfully", {
                position: toast.POSITION.TOP_CENTER
            });
            nextProps.auth.newuser = undefined;
        }
    }

    componentDidMount(){
       this.currencyDetails()
    }

    async currencyDetails(){
        var options = []
        var availableCurrenct = []
        const currencyData = await currency();
        currencyData.result.map((res,i)=>{
            if(res.type == "Token"){
                options.push({value:res._id,label:res.currencySymbol,id:res._id})
            }
            else if(res.type == "Token" || res.type == "Crypto"){
                availableCurrenct.push({value:res._id,label:res.currencySymbol })
            }
        })
        console.log("options_options_options",options,availableCurrenct);
        this.setState({options: options,currencyArray: availableCurrenct})
        console.log("currency_currencyData",currencyData);
    }



    handleEditorChange(e) {
        this.setState({ content: e.editor.getData()});
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };
    handleChangeefile = (event) => {
        console.log("event.target.files[0]",event.target.files[0]);
        if (event.target.files[0]) {
            var CheckFormate = event.target.files[0].name.split(".");
            CheckFormate = CheckFormate[CheckFormate.length - 1];
            if(CheckFormate == "pdf" || CheckFormate == "PDF"){
                console.log("CheckFormate",CheckFormate);
                var filesize = event.target.files[0].size
                this.setState({
                    curimage: URL.createObjectURL(event.target.files[0]),
                    attachment: event.target.files[0],
                    fileName: event.target.files[0].name
                });
            }else{
                toast.error("Select correct file formate", {
                    position: toast.POSITION.TOP_CENTER
                });
            }
           
        }

    };

    onLaunchpadAdd = async e => {
        e.preventDefault();
        console.log("@@@@@@@@@", this.state)
        const addLaunchpad1 = {
            tokenname: this.state.tokenname,
            symbol: this.state.symbol,
            availablecurrency: this.state.availablecurrency,
            email: this.state.email,
            user_phone_number: this.state.user_phone_number,
            picture: this.state.picture
        };

        const data = new FormData();
        data.append("startdate", this.state.startDate != "" ? this.state.startDate : this.state.startDate);
        data.append("endDate", this.state.endDate != "" ? this.state.endDate : this.state.endDate);
        data.append("tokenname", this.state.tokenname);
        data.append("symbol", this.state.symbol);
        data.append("availablecurrency", this.state.availablecurrency);
        data.append("price", this.state.price);
        data.append("minAmt", this.state.minAmt);
        data.append("discount", this.state.discount);
        data.append("availablesale", this.state.availablesale);
        data.append("maxsupply", this.state.maxsupply);
        data.append("industry", this.state.industry);
        data.append("website", this.state.website);
        data.append("content", this.state.content);
        data.append("file1", this.state.attachment);
        data.append("currencyId",this.state.currencyId);
        console.log("****************", data.values);
        await this.props.addLaunchpad(data);
        // $('#add-launch-modal').modal('hide');

    };
    setStartDate(date) {
        console.log("date", date)

    }
    handleChangeLaunchCoin(data){
        console.log("data_________",data)
        this.setState({symbol: data.value})
    }
    selectAvailableCurrency(data){
        // this.setState({availablecurrency : data.value})
        console.log("data_________",data)
        if(data && data.length > 0){
            this.setState({availablecurrency : data.map((el) => { return el.value})}) 
        }else if (data == null){
            this.setState({availablecurrency : []}) 
        }else{
            this.setState({availablecurrency : []}) 

        }
    }
    filterPassedTime (time){
        const currentDate = new Date();
        const selectedDate = new Date(time);
        return currentDate.getTime() < selectedDate.getTime()
    };
    render() {
        const { errors } = this.state;
        const {t}=this.props
        console.log("errors",errors)
        return (
            <div>
                <div className="modal fade" id="add-launch-modal" data-reset="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">{t("ADD_LAUNCHPAD")}</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            {console.log("availablecurrency_availablecurrency",this.state.availablecurrency)}
                            <div className="modal-body">
                                <form noValidate onSubmit={this.onLaunchpadAdd} id="add-lauchpad" >
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="tokenname">{t("TOKEN_NAME")}</label>
                                        </div>
                                        <div className="col-md-9">
                                            {/* <input
                                                onChange={this.onChange}
                                                value={this.state.tokenname}
                                                id="tokenname"
                                                type="text"
                                                error={errors.tokenname}
                                                className={classnames("form-control", {
                                                    invalid: errors.tokenname
                                                })} /> */}
                                            <Select
                                                // value={selectedTerms}
                                                onChange={this.handleChangeSelect}
                                                options={this.state.options}
                                                label="Single select"
                                                styles={this.styles} className="border_blue_select basic-multi-select"
                                            />
                                            <span className="text-danger">{errors.tokenname}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="symbol">{t("Whitepaper")}</label>
                                        </div>
                                        <div className="col-md-9">
                                            <label class="custom-file-upload">
                                                <input
                                                    type="file"
                                                    accept="image/x-png,image/gif,image/jpeg"
                                                    onChange={this.handleChangeefile}
                                                    name="file1"
                                                    id="exampleInputFile"
                                                    aria-describedby="fileHelp"
                                                    required
                                                />
                                                {this.state.fileName == "" ? 'Choose File' : this.state.fileName}
                                            </label>
                                            <span className="text-danger">{errors.file1}</span>

                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="symbol">{t("TOKEN_LAUNCH_PRICE")}</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.price}
                                                error={errors.price}
                                                id="price"
                                                type="price"
                                                className={classnames("form-control", {
                                                    invalid: errors.price
                                                })}
                                            />
                                            <span className="text-danger">{errors.price}</span>
                                        </div>
                                    </div>
                                        {/* <div className="row mt-2">
                                  <div className="col-md-3">
                                  <label htmlFor="minimum">{t("TOKEN_ICON")}</label>
                                  </div>
                                  <div className="col-md-9">
                                  <label class="custom-file-upload">
                                  <input
                                        type="file"
                                        accept="image/x-png,image/gif,image/jpeg"
                                        onChange={this.handleChangeefile}
                                        name="file1"
                                        id="exampleInputFile"
                                        aria-describedby="fileHelp"
                                      />
                                      {t("CHOOSE_FILE")}
                                      </label>
                                      <span className="text-danger">{this.state.imageerror}</span>
                                     
                                  </div>
                                </div> */}
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="price">{t("Token Launch Coin")}</label>
                                        </div>
                                        <div className="col-md-9">
                                            {/* <input
                                                onChange={this.onChange}
                                                value={this.state.symbol}
                                                error={errors.symbol}
                                                id="symbol"
                                                type="symbol"
                                                className={classnames("form-control", {
                                                    invalid: errors.symbol
                                                })}
                                            /> */}
                                            {console.log("this.state.currencyArray",this.state.currencyArray)}
                                             <Select
                                                // value={this.state.symbol}
                                                options={this.state.currencyArray}
                                                isSearchable={false}
                                                onChange={this.handleChangeLaunchCoin}
                                                label="Select"
                                                styles={this.styles} className="border_blue_select basic-multi-select"
                                            />

                                            {/* <select name="pets" id="pet-select" className="border_blue_select basic-multi-select">
                                                <option value="">--Please choose an option--</option>
                                                {this.state.currencyArray && this.state.currencyArray.map((value, index) => (
                                                    <option value="dog">{value.lable}</option>
                                                ))} */}
                                               
                                            
                                            {/* </select>  */}

                                            <span className="text-danger">{errors.symbol}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password2">{t("MINIMUM_PURCHASE")}</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.minAmt}
                                                id="minAmt"
                                                type="minAmt"
                                                className={classnames("form-control", {
                                                    invalid: errors.minAmt
                                                })}
                                            />
                                            <span className="text-danger">{errors.minAmt}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password2">{t("DISCOUNT")}</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.discount}
                                                id="discount"
                                                type="discount"
                                                className={classnames("form-control", {
                                                    invalid: errors.discount
                                                })}
                                            />
                                            <span className="text-danger">{errors.discount}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="symbol">Available Currency</label>
                                        </div>
                                        <div className="col-md-9">
                                            {/* <input
                                                onChange={this.onChange}
                                                value={this.state.availablecurrency}
                                                error={errors.availablecurrency}
                                                id="availablecurrency"
                                                type="availablecurrency"
                                                className={classnames("form-control", {
                                                    invalid: errors.availablecurrency
                                                })}
                                            /> */}
                                            <Select
                                                // value={selectedTerms}
                                                onChange={this.selectAvailableCurrency}
                                                isMulti
                                                options={this.state.currencyArray}
                                                label="Single select"
                                                styles={this.styles} className="border_blue_select basic-multi-select"
                                            />
                                            <span className="text-danger">{errors.availablecurrency}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password2">{t("TOKEN_AVAILABLE_TO_SALE")}</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.availablesale}
                                                id="availablesale"
                                                type="availablesale"
                                                className={classnames("form-control", {
                                                    invalid: errors.availablesale
                                                })}
                                            />
                                            <span className="text-danger">{errors.availablesale}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password2">{t("TOKEN_MAX_SUPPLY")}</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.maxsupply}
                                                id="maxsupply"
                                                type="maxsupply"
                                                className={classnames("form-control", {
                                                    invalid: errors.maxsupply
                                                })}
                                            />
                                            <span className="text-danger">{errors.maxsupply}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password2">{t("INDUSTRY")}</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.industry}
                                                id="industry"
                                                type="industry"
                                                className={classnames("form-control", {
                                                    invalid: errors.industry
                                                })}
                                            />
                                            <span className="text-danger">{errors.industry}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password2">{t("WEBSITE")}</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.website}
                                                id="website"
                                                type="website"
                                                className={classnames("form-control", {
                                                    invalid: errors.website
                                                })}
                                            />
                                            <span className="text-danger">{errors.website}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password2">{t("START_DATE")}</label>
                                        </div>
                                        <div className="col-md-9">
                                            <DatePicker
                                                selected={this.state.startDate}
                                                // onChange={this.handleChange}
                                                onChange={(date) => {
                                                    let newDate = new Date(date)
                                                    newDate.setMilliseconds(0)
                                                    this.setState({startDate: newDate.getTime()})
                                                }}
                                                dateFormat="MMMM d, yyyy HH:mm"
                                                className={classnames("form-control", {
                                                    invalid: errors.startDate
                                                })}
                                                showTimeSelect
                                                timeFormat="HH:mm"
                                                minDate={new Date()}
                                                timeIntervals={1}
                                                filterTime={this.filterPassedTime}
                                            />
                                            <span className="text-danger">{errors.startdate}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password2">{t("END_DATE")}</label>
                                        </div>
                                        <div className="col-md-9">
                                            <DatePicker
                                                selected={this.state.endDate}
                                                // onChange={this.handleChange1}
                                                onChange={(date) => {
                                                    let newDate = new Date(date)
                                                    newDate.setMilliseconds(0)
                                                    this.setState({endDate: newDate.getTime()})
                                                }}
                                                dateFormat="MMMM d, yyyy HH:mm"
                                                className={classnames("form-control", {
                                                    invalid: errors.endDate
                                                })}
                                                showTimeSelect
                                                timeFormat="HH:mm"
                                                minDate={new Date()}
                                                timeIntervals={1}
                                                filterTime={this.filterPassedTime}
                                            />
                                            <span className="text-danger">{errors.endDate}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="content">{t("CONTENT")}</label>
                                        </div>
                                        <div className="col-md-9">
                                            {/* <Editor
                                                apiKey='5vk89nvvi2zckrb2lp2ctyyolewhq1v3pzdiwb7at68h40a5'
                                                initialValue="<p>This is the initial content of the editor</p>"
                                                value={this.state.content} onEditorChange={this.handleEditorChange}
                                                init={{
                                                    height: 500,
                                                    menubar: false,
                                                    plugins: [
                                                        'advlist autolink lists link image charmap print preview anchor',
                                                        'searchreplace visualblocks code fullscreen',
                                                        'insertdatetime media table paste code help wordcount'
                                                    ],
                                                    toolbar:
                                                        'undo redo code | formatselect | bold italic backcolor | \
                                               alignleft aligncenter alignright alignjustify | \
                                               bullist numlist outdent indent | removeformat | help'
                                                }}
                                            /> */}
                                            <CKEditor
                                                    config={{
                                                        //extraAllowedContent: 'div(*)',
                                                        allowedContent: true,
                                                        height: 500,
                                                    }}
                                                    initData={""}
                                                    onChange={this.handleEditorChange}
                                                />

                                            <span className="text-danger">{errors.content}</span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-outline" data-dismiss="modal" onClick={this.clearStateData.bind(this)}>{t("CLOSE")}</button>
                                <button
                                    form="add-lauchpad"
                                    type="submit"
                                    className="btn btn-primary">
                                   {t("ADD_LAUNCHPADS")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

LaunchpadAddModal.propTypes = {
    addLaunchpad: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { addLaunchpad}
)(withRouter(withTranslation()(LaunchpadAddModal),(LaunchpadAddModal)));
