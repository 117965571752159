import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import axios from "axios";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import ContactUpdateModal from "../partials/ContactUpdateModal";
import { toast, ToastContainer} from "react-toastify";
import keys from "../../actions/config";
import { withTranslation, WithTranslation } from "react-i18next";
const url = keys.baseUrl;
class Contactus extends Component {
    constructor(props) {
        super(props);
const {t}=this.props
        this.columns = [
            
            {
                key: "name",
                text: t("NAME"),
                className: "name",
                align: "left",
                sortable: true,
            },
            {
                key: "email",
                text: t("EMAIL"),
                className: "email",
                align: "left",
                sortable: true
            },
            {
                key: "message",
                text: t("MESSAGE"),
                className: "message",
                align: "left",
                sortable: true
            },
            {
                key: "replystatus",
                text: t("REPLY_STATUS"),
                className: "message",
                align: "left",
                sortable: true
            },
            {
                key: "action",
                text: t("ACTION"),
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
                    return (
                        <Fragment>
                          <div className="d-flex"> <button
                                data-toggle="modal"
                                data-target="#update-contact-modal"
                                className="btn btn-primary btn-sm mr-2"
                                onClick={() => this.editRecord(record)}
                                >
                                <i className="fa fa-reply"></i>
                            </button>
                            <button
                                className="btn btn-outline btn-sm"
                                onClick={() => this.deleteRecord(record)}>
                                <i className="fa fa-trash"></i>
                            </button></div> 
                        </Fragment>
                    );
                }
            }
        ];

        this.config = {
            page_size: 10,
            length_menu: [ 10, 20, 50 ],
            filename: "Cms",
            no_data_text: 'No Enquires found!',
            language: {
                length_menu: t("SHOW_MENU"),
                filter: t("FILTER IN RECORDS"),
                info: t("SHOWING_START_END"),
                pagination: {
                    first: t("FIRST"),
                    previous: t("PREVIOUS"),
                    next: t("NEXT"),
                    last: t("LAST")
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            records: []
        };

        this.state = {
            currentRecord: {
                _id: '',
                name: '',
                email: '',
                mobilenumber: '',
                message: '',
                reply : ''
            }
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        // console.log(document.querySelectorAll(".list-group-item-action.active")[0].offsetTop,"top");
        // var pagelinkofset = document.querySelectorAll(".list-group-item-action.active")[0].offsetTop;
        // document.getElementById("scrollbar_sidebar_id").scrollTo(0,pagelinkofset);
        this.getData()
    };

    componentWillReceiveProps(nextProps) {
        this.getData()
    }

    getData() {
        axios
            .post(url+"api/contact-data")
            .then(res => {
                console.log(res,'res');
                this.setState({ records: res.data})
            })
            .catch()
    }

    editRecord(record) {
        console.log(record,'record');
        this.setState({ currentRecord: record});
    }

    deleteRecord(record) {
      console.log(record);
      const {t}=this.props
        axios
            .post(url+"api/contact-delete", {_id: record._id})
            .then(res => {
                if (res.status === 200) {
                   toast(t("CONTACT_DELETED_SUCCESSFULLY"), {
                       position: toast.POSITION.TOP_CENTER,
                   })
                }
            })
            .catch();
        this.getData();
    }

    pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }

    render() {
const {t}=this.props
        return (
            <div>
                <Navbar/>
                <div className="d-flex" id="wrapper">
                    <Sidebar/>
                    {/* onHide={()=>{console.log(this.state.currentRecord,"onHide");this.setState({currentRecord:{}})}} */}
                    <ContactUpdateModal record={this.state.currentRecord} />
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <h3 className="mt-2 text-secondary">{t("ENQUIRES_LIST")}</h3>
                            <div className="resonsive_table">
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                onPageChange={this.pageChange.bind(this)}
                                className="table table-bordered table-striped contact_table"
                            />
                            </div>
                        </div>
                    </div>
                    <ToastContainer/>
                </div>
            </div>
        );
    }

}

Contactus.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});

export default connect(
    mapStateToProps
)(withTranslation()(Contactus),(Contactus));
