import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from "@ashvin27/react-datatable";
import PropTypes from "prop-types";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import BlockAddModal from "../partials/BlockAddModal.jsx";
import { toast, ToastContainer } from "react-toastify";
import $ from "jquery";
import keys from "../../actions/config";
const url = keys.baseUrl;

class BlockList extends Component {
  constructor(props) {
    super(props);
const {t}=this.props
    this.columns = [
      {
        key: "ipaddress",
        text: t("IP_ADDRESS"),
        className: "pair",
        align: "left",
        sortable: true,
        width: 200
      },     
   
      {
        key: "action",
        text: t("ACTION"),
        className: "action",
        width: 100,
        align: "left",
        sortable: false,
        cell: record => {
          return (
            <Fragment>
             <div className="d-flex">
              <button
                className="btn btn-outline btn-sm"
                onClick={() => this.deleteRecord(record)}
              >
                <i className="fa fa-trash"></i>
              </button></div>
            </Fragment>
          );
        }
      }
    ];

    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      filename: "Perpetual",
      no_data_text: "No Block list found!",
      language: {
        length_menu: t("SHOW_MENU"),
        filter: t("FILTER IN RECORDS"),
        info: t("SHOWING_START_END"),
        pagination: {
            first: t("FIRST"),
            previous: t("PREVIOUS"),
            next: t("NEXT"),
            last: t("LAST")
        }
    },
      show_length_menu: true,
      show_filter: true,
      show_pagination: true,
      show_info: true
    };

    this.state = {
      records: []
    };

    this.state = {
      currentRecord: {
        id: "",
        tiker_root: ""
      }
    };

    // this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  componentWillReceiveProps(nextProps) {
    this.getData();
  }

  getData() {
    console.log("############################################EGGD############3")
    axios
      .post(url + "api/blocklist")
      .then(res => {
          console.log("blocklist",res)
        if (res.data != undefined) {
          this.setState({ records: res.data })
        }
      })
      .catch();
  }

 async getData2 (){
  console.log("hi")
 let result = await axios.post(url + "api/blocklist")
      // .then(res => {
      //     console.log("blocklist",res)
      //   if (res.data != undefined) {
      //     return res.data
      //   }
      // })
      // .catch();
      return result.data
 }
  addRecord() {
    $("#add-block-modal")
      .find(".text-danger")
      .hide();
  }

  deleteRecord(record) {
    const {t}=this.props
    if (!window.confirm(t("ARE_YOU_DELETE_BLOCK_LIST"))) {
      return false;
    }
    axios
      .post(url + "api/delet-blocklist", { id: record._id })
      .then(res => {
        if (res.status === 200) {
          toast(t(res.data.message), {
            position: toast.POSITION.TOP_CENTER
          });
        }
      })
      .catch();
    setTimeout(
      function() {
        this.getData();
      }.bind(this),
      1000
    );
  }

  pageChange(pageData) {
    console.log("OnPageChange", pageData);
  }

  render() {
    const {t}=this.props
    return (
      <div>
        <Navbar />
        <div className="d-flex" id="wrapper">
          <Sidebar />
          <BlockAddModal getdata={async() =>{ var data = await this.getData2();console.log(data,"getData2"); this.setState({ records: data })}} />
          <div id="page-content-wrapper">
            <div className="container-fluid">
              <button
                onClick={() => this.addRecord()}
                className="btn btn-outline float-right mt-0 mr-2 btn_pos_right"
                data-toggle="modal"
                data-target="#add-block-modal"
              >
                <FontAwesomeIcon icon={faPlus} /> {t("ADD_BLOCK_LIST")}
              </button>
              <div className="clearfix" />

              <h3 className="mt-2 text-secondary">{t("BLOCK_LIST")}</h3>
              <div className="resonsive_table">
              <ReactDatatable
                config={this.config}
                records={this.state.records}
                columns={this.columns}
                onPageChange={this.pageChange.bind(this)}
                className="table table-bordered table-striped article_table"
              />
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      </div>
    );
  }
}

BlockList.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  records: state.records
});

export default connect(mapStateToProps)(withTranslation()(BlockList),(BlockList));
