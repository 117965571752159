import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import { addarticle } from "../../actions/category";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { withTranslation, WithTranslation } from "react-i18next";
import $ from "jquery";
import { Editor } from "@tinymce/tinymce-react";
import Select from "react-select";
import axios from "axios";
import keys from "../../actions/config";
import "react-toastify/dist/ReactToastify.css";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';


const url = keys.baseUrl;

class BlockAddModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subcategoryName: "",
      status: "",
      errors: {},
      categoryName1: "",
      categoryName: "",
      subcategorydropdown: "",
      articlename: "",
      content: "",
      query_image: "",
      curimage:"",
      ipaddress:""
    };
    this.handleEditorChange = this.handleEditorChange.bind(this);
  }

  styles = {
    option: (styles, {isFocused, isSelected}) => ({
      ...styles,
      color: "white",
      background: isFocused
          ? '#1d296a'
          : isSelected
              ? '#113853'
              : "#81c8f6",
      zIndex: 1
  }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '52px',
      padding: '0 6px',
      backgroundColor: "#03081f",
      borderColor: '#81c8f6',
    borderRadius: 10,
    borderStyle: 'solid',
    borderWidth: '1px'
     
    }),
    control: (provided, state) => ({
      ...provided,
      height: '52px',
      borderRadius:10,
      backgroundColor: "#03081f",
      border:'none'
     
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '52px',
      position: 'absolute',
      right: 0,
      top: 0,
      color:'#fff' 
    }),    
    singleValue: (provided, state) => ({
      ...provided,
      color: "#fff"
    }),
    menuList: base => ({
      ...base,
      // kill the white space on first and last option
      padding: 0
    })
  };
  componentDidMount() {
    this.getData();
  }

  getData() {
    axios.get(url + "api/blocklist").then(category => {
      console.log("Response data====>", category);
      var categoryarray = [];
      category.data.map((item, i) => {
        const name = item.categoryName;
        const value = item._id;
        const obj = { value: value, label: name };
        categoryarray.push(obj);
      });
      this.setState({ categoryName1: categoryarray, email_assigned: true });
    });

    axios.get(url + "api/sub-category").then(subcategory => {
      console.log("Response data====>", subcategory);
   
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      $("#add-article-modal")
        .find(".text-danger")
        .show();
      this.setState({
        errors: nextProps.errors
      });
    } else {
      this.setState({
        errors: ""
      });
    }
    if (
      nextProps.auth !== undefined &&
      nextProps.auth.currencyadd !== undefined &&
      nextProps.auth.currencyadd.data !== undefined &&
      nextProps.auth.currencyadd.data.message !== undefined
    ) {
      $("#add-article-modal").modal("hide");
      this.setState({
        errors: ""
      });
      const {t}=this.props
      toast(t(nextProps.auth.currencyadd.data.message), {
        position: toast.POSITION.TOP_CENTER
      });
      nextProps.auth.currencyadd = undefined;
      this.setState({
        errors: ""
      });
    }
  }
  handleEditorChange(content, editor) {
    this.setState({ content });
  }

  handleChange = selectedOption => {
    this.setState({ categoryName: selectedOption.value });

    console.log(`Option selected:`, selectedOption);
    var data = {
      categoryid: selectedOption.value
    };
    axios.post(url + "api/sub-category-select-find", data).then(subcategory => {
      console.log("Response data====>", subcategory);
      var subcategoryarray = [];
      subcategory.data.map((item, i) => {
        const name = item.subcategoryName;
        const value = item._id;
        const obj = { value: value, label: name };
        subcategoryarray.push(obj);
      });
      console.log(subcategoryarray, "subcategoryarray");
      this.setState({
        subcategoryName: subcategoryarray,
        email_assigned: true
      });
    });
  };

  handleChange1 = selectedOption => {
    this.setState({ subcategorydropdown: selectedOption });
    // console.log(`Option selected:`, selectedOption );
  };
  onChange = e => {
    console.log([e.target.id], e.target.value ,"onChange")
    this.setState({ [e.target.id]: e.target.value });
  };

  handleChangefile = event => {
    console.log(event.target.files[0],'mokkd')
    this.setState({
      curimage:URL.createObjectURL(event.target.files[0]),
      query_image:event.target.files[0]
       });
  };

  onarticleAdd = e => {
    e.preventDefault();
    // const newarticle = {
    //   articledetails: this.state.articlename,
    //   maincategoryId: this.state.categoryName,
    //   subcategoryId: this.state.subcategorydropdown.value,
    //   content: this.state.content,
    //   query_image: this.state.query_image

    // };
    // console.log("newarticle***********", newarticle);
    // this.props.addarticle(newarticle);

    const data = {
      ipaddress:this.state.ipaddress
    }
    console.log(data,"data")
    // data.append('_id', this.state._id);
    // data.append("ipaddress", this.state.ipaddress);
    // data.append("maincategoryId", this.state.categoryName);
    // // data.append("subcategoryId", this.state.subcategorydropdown.value);
    // data.append("content", this.state.content);
    // data.append("file", this.state.query_image);
    // console.log(":Daatataa", data);
    axios
      .post(url + "api/add-blocklist", data)
      .then(res => {
        const {t}=this.props
        if (res.status === 200) {
          toast(t(res.data.message), {
            position: toast.POSITION.TOP_CENTER
          });
          this.props.getdata();
          $("#add-block-modal").modal("hide");

        }
      })
      .catch();
       //this.props.getdata();
    // this.props.addarticle(data);
  };

  render() {
    const {t}=this.props
    const { selectedOption, subcategoryName } = this.state.categoryName1;

    const { errors } = this.state;
    return (
      <div>
        <div className="modal fade" id="add-block-modal" data-reset="true">
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">{t("ADD_BLOCK_LIST")}</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <form noValidate onSubmit={this.onarticleAdd} id="add-block">
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="currencyName">{t("IP_ADDRESS")}</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.onChange}
                        value={this.state.ipaddress}
                        id="ipaddress"
                        type="text"
                        // error={errors.currencyName}
                        className={classnames("form-control", {
                          invalid: errors.currencyName
                        })}
                      />
                      {/* <span className="text-danger">{errors.currencyName}</span> */}
                    </div>
                  </div>

               

                

               

           
                </form>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline"
                  data-dismiss="modal"
                >
                {t("CLOSE")}
                </button>
                <button
                  form="add-block"
                  type="submit"
                  className="btn btn-primary"
                >
                  {t("ADD")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

BlockAddModal.propTypes = {
//   addarticle: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(mapStateToProps)(withRouter(withTranslation()(BlockAddModal),(BlockAddModal)));
